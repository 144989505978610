import React, { useEffect, useState } from 'react'

import {
    Grid,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@material-ui/core'


function StoryTable(props) {
    let n = props.rows
    if (typeof n == "undefined") {
        n = 5
    }
    let [items, setItems] = useState(n)
    let [rows, setRows] = useState(n)
    
    const [data, setData] = useState([])
    const [layout, setLayout] = useState([])

    const showMore = () => {
        setRows(rows + items)
    }

    useEffect(() => {
        if (props.data && props.layout) {
            let layout = props.layout
            let tableLayout = []
            
            if (layout.length == 1 && layout[0] == "as is") {
                let keys = props.data[0]
                for (var key in keys) {
                    tableLayout.push({column:key, field:key}) // ignore warning keys should be unique
                }
            } else {
                tableLayout = layout
            }
            
            setData(props.data)
            setLayout(tableLayout)
        }
        if (props.items) {
            setRows(props.items)
            setItems(props.items)
        }
    }, [props])

    return (

        <div>
            <TableContainer className="dataTable">
                <Table className="dataTable" aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            {layout.map((item) => (
                                <TableCell align="right">{item.column}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.slice(0, rows).map((row) => (
                            <TableRow key={row[layout[0].field]}>
                                <TableCell component="th" scope="row">
                                    {row[layout[0].field]}
                                </TableCell>
                                {layout.slice(1, layout.length).map((item) => (
                                    <TableCell align="right">{row[item.field]}</TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {data.length > rows &&
                <Grid container justify="center" spacing={2} alignItems="stretch">
                    <Button onClick={showMore} style={{color: 'rgb(21, 125, 216)'}}>Show More</Button>
                </Grid>
            }
        </div>
    )
}
export default StoryTable