
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { API } from 'aws-amplify'
import StoryTitle from '../components/StoryTitle'
import StoryTable from '../components/StoryTable'
import color from '../shared/color'
import { DonutChart, BubbleChart } from '../d3'
import { Typography, Grid, CircularProgress, Paper } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import '../story.css'

function Time() {

    const [title] = useState("Time Data Overview")
    const [summary] =  useState("A datailed look at the ways in which our customers configure and consume our products in order to improve implementation based on real world usage of our products.")
    const [categories] = useState(["implementation"])
    const [isLoading, setIsLoading] = useState(true)
    const [data, setData] = useState(null)

    const [bubbleColor, setBubColor] = useState(null)
    const useStyles = makeStyles({
        table: {
            minWidth: 650,
        },
    })

    let history = useHistory()

    useEffect(() => {
        setBubColor(new Map([
            ["Scheduled_with_permission", "#4e79a7"],
            ["Customer_Managed", "#f28e2c"],
            ["Scheduled_wo_permission", "#59a14f"]
          ]))
        async function fetch() {
            await API.get('qs-web-api', '/data/time/timeoverview.json', {})
                .then((storyData) => {
                    setData(storyData)
                })
                .catch(function (error) {
                    console.log(error)
                })
            setIsLoading(false)
        }
        fetch()
    }, [])

    if (isLoading) {
        return (<div className="circular-progress"><CircularProgress disableShrink /></div>)
    } else {
        return (
            <div id="story">
                <StoryTitle title={title} date={data.date} categories={categories}></StoryTitle>

                <Paper className="storySection">
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <div className="subText">
                            <Typography >
                                TIME is a joint effort between DevOps, Engineering Services and Professional Services to give Telemetry Insights Management Extraction (TIME) for the Med Es Products. The data collection is planned for the PLX, IV Prep and completed for the Med ES. The collected data helps improve customer insights and show trends in the device using crowd-sourced analytics.
                                <p>
                                    For example there are {data.uniqueSecGroup} unique secruity groups and  {data.uniqueOverrideGroups} unique override groups. This shows that there is a lot to learn about how our customer use our system and the patterns that can be identified. This is just the tip of the iceberg.

                                </p>
                                <p>
                                    If you or your team would like to dive into the data, please open the Time-Med-ES workbook from the workbook tab.
                                </p>
                        </Typography>
                        </div>
                    </Grid>
                </Paper>

                <Paper className="storySection">
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <div className="storyTitle">
                            <Typography variant="h5" fontWeight="bold">
                                Medication Management Solutions Sites Measured By Med Es and PAS ES
                        </Typography>
                        </div>
                    </Grid>
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <BubbleChart data={data.patchFleetSoldTo.data} scale={data.patchFleetSoldTo.scale} colors={bubbleColor} tooltip={"ES Servers:<br>"} width={750} height={550}></BubbleChart>
                    </Grid>
                    <Grid container justify="center" spacing={2} alignItems="stretch">
                        <div className="subText">
                            <Typography>
                                <p>
                                    The larger the circle greater the distrubition of customers that fit that type of device distribution between the number of med es and pas es.
                                </p>

                                <p>
                                TIME allows the user to filter the data by the amount of MMS devices to help hone in on the customer type. Looking at how our customer vary helps explain the different needs for their patients.

                                Time-Med-ES gives oversight of customer configuration management by allowing very cateried filters.                             </p>
                                </Typography>
                        </div>
                    </Grid>
                </Paper>

                <Paper className="storySection">
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <div className="description">
                            <Typography variant="h5">
                                Understanding Customers Configurations to determine a Better Outcome
                            </Typography>
                        </div>
                    </Grid>
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <div className="subText">
                            <Typography variant="h6">
                                Remove Order With Undetermined Dose Usage
                            </Typography>
                        </div>
                    </Grid>
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <DonutChart data={data.pieFacilityCustomerRemoveOrderWithUndeterminedDose} colors={color.defaultScheme} tooltip={"Count of configuration <br>"} width={1000} height={400} radius={200}></DonutChart>
                    </Grid>
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <div className="subText">
                            <Typography>
                                A quick win early on for Time-Med-ES was a customer refused to use “undetermined dose” as the customer felt the setting was a patient safety concern. Thanks to the crowd-sourced data, the customer felt more secure in allowing for “undetermined dose” to be used.
                                <br/>
                                <br/>
                                As of 4/1/2021, the current customer base is happily using “undetermined dose”. The purpose of time is to ensure monitoring that setting along with each other setting in the Med ES Server.
                        </Typography>
                        </div>
                    </Grid>
                </Paper>

                <Paper className="storySection">
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <div className="description">
                            <Typography variant="h6">
                                Examples of MMS Configuration Management for the Med ES Product
                            </Typography>
                        </div>
                    </Grid>
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <div className="subText">
                            <Typography >
                                While showing each value would overload the webpage, below is a sample of the different distributions of configuration management to give ideas of how the data can be used. As TIME is a fairly new process, these will be replaced with different narratives on the data usage.
                        </Typography>
                        </div>
                    </Grid>
                </Paper>

                <Paper className="storySection">
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <div className="description">
                            <Typography variant="h6">
                                Top Patient Id Types
                            </Typography>
                        </div>
                    </Grid>
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <DonutChart data={data.piePatientIdtypesCodes} colors={color.defaultScheme} tooltip={"Count of configurations <br>"} width={1000} height={400} radius={200}></DonutChart>
                    </Grid>
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <div className="subText">
                            <Typography >
                                An overview of the top patient id types used by the Med ES product.
                        </Typography>
                        </div>
                    </Grid>
                </Paper>

                <Paper className="storySection">
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <div className="description">
                            <Typography variant="h6">
                                Top Gender Id Types
                            </Typography>
                        </div>
                    </Grid>
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <DonutChart data={data.pieGenderTable} colors={color.defaultScheme} tooltip={"Count of configurations <br>"} width={1000} height={400} radius={200}></DonutChart>
                    </Grid>
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <div className="subText">
                            <Typography >
                                An overview of the top gender id types used by the Med ES product.
                        </Typography>
                        </div>
                    </Grid>
                </Paper>

                <Paper className="storySection">
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <div className="description">
                            <Typography variant="h6">
                                Top 5 Discrepancy Reasons
                            </Typography>
                        </div>
                    </Grid>
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <DonutChart data={data.pieDiscrepancyreasons} colors={color.defaultScheme} tooltip={"Count of configurations <br>"} width={1000} height={400} radius={200}></DonutChart>
                    </Grid>
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <div className="subText">
                            <Typography >
                                Using an import for the customer crowdsourcded data, this pie chart gives an overview of the most common used discrepancy reasons for the Med ES Product.
                        </Typography>
                        </div>
                    </Grid>
                </Paper>

                <Paper className="storySection">
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <div className="description">
                            <Typography variant="h6">
                                Med ES Product Use of Facility Messages
                            </Typography>
                        </div>
                    </Grid>
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <div className="subText">
                            <Typography >
                                Item Messages
                        </Typography>
                        </div>
                    </Grid>
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <DonutChart data={data.pieFacilityUsagenotreturnableitemmessage} colors={color.defaultScheme} tooltip={"Count of configurations <br>"} width={1000} height={400} radius={200}></DonutChart>
                    </Grid>
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <div className="subText">
                            <Typography >
                                Allergy Message
                        </Typography>
                        </div>
                    </Grid>
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <DonutChart data={data.pieFacilityUsagetruncatedallergymessage} colors={color.defaultScheme} tooltip={"Count of configurations <br>"} width={1000} height={400} radius={200}></DonutChart>
                    </Grid>
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <div className="subText">
                            <Typography >
                                Order Message
                        </Typography>
                        </div>
                    </Grid>
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <DonutChart data={data.pieFacilityUsagetruncatedordermessage} colors={color.defaultScheme} tooltip={"Count of configurations <br>"} width={1000} height={400} radius={200}></DonutChart>
                    </Grid>
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <div className="subText">
                            <Typography >
                                Using an import for the customer crowdsourcded data, this pie chart gives an overview of the most common used discrepancy reasons for the Med ES Product.
                        </Typography>
                        </div>
                    </Grid>
                </Paper>

                <Paper className="storySection">
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <div className="description">
                            <Typography variant="h6">
                                Facility Components similar to  Remove Order With Undetermined Dose
                            </Typography>
                        </div>
                    </Grid>
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <div className="subText">
                            <Typography >
                                Display Patient ID Barcode
                        </Typography>
                        </div>
                    </Grid>
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <DonutChart data={data.pieFacilityCustomerDisplayPatientIDBarcode} colors={color.defaultScheme} tooltip={"Count of configurations <br>"} width={1000} height={400} radius={200}></DonutChart>
                    </Grid>
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <div className="subText">
                            <Typography >
                                Allow Cubie Pockets for another Destination
                        </Typography>
                        </div>
                    </Grid>
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <DonutChart data={data.pieFacilityCustomerAllowCubiePocketsforanotherDestination} colors={color.defaultScheme} tooltip={"Count of configurations <br>"} width={1000} height={400} radius={200}></DonutChart>
                    </Grid>
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <div className="subText">
                            <Typography >
                                Allow Partial Multi-Component Order Remove
                        </Typography>
                        </div>
                    </Grid>
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <DonutChart data={data['pieFacilityCustomerAllowPartialMulti-ComponentOrderRemove']} colors={color.defaultScheme} tooltip={"Count of configurations <br>"} width={1000} height={400} radius={200}></DonutChart>
                    </Grid>
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <div className="subText">
                            <Typography >
                                Using an import for the customer crowdsourcded data, this pie chart gives an overview of facility setting reasons for the Med ES Product.
                        </Typography>
                        </div>
                    </Grid>
                     <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <DonutChart data={data.pieFacilityCustomerAllowInsertingUncheckedCubiePockets} colors={color.defaultScheme} tooltip={"Count of configurations <br>"} width={1000} height={400} radius={200}></DonutChart>
                    </Grid>
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <div className="subText">
                            <Typography >
                                Using an import for the customer crowdsourcded data, this pie chart gives an overview of the most common used discrepancy reasons for the Med ES Product.
                        </Typography>
                        </div>
                    </Grid>
                </Paper>

                <Paper className="storySection">
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <div className="description">
                            <Typography variant="h6">
                                Top 5 Overview of Print Usage
                            </Typography>
                        </div>
                    </Grid>
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <div className="subText">
                            <Typography >
                                Choice to Use Formulary Print on Remove
                        </Typography>
                        </div>
                    </Grid>
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <DonutChart data={data.pieFormularyprintonremove} colors={color.defaultScheme} tooltip={"Count of configurations <br>"} width={1000} height={400} radius={200}></DonutChart>
                    </Grid>
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <div className="subText">
                            <Typography >
                                Choice to Use Formulary Print on Return
                        </Typography>
                        </div>
                    </Grid>
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <DonutChart data={data.pieFormularyprintonreturn} colors={color.defaultScheme} tooltip={"Count of configurations <br>"} width={1000} height={400} radius={200}></DonutChart>
                    </Grid>
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <div className="subText">
                            <Typography >
                                Choice to Use Formulary Print on Waste
                        </Typography>
                        </div>
                    </Grid>
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <DonutChart data={data.pieFormularyprintonwaste} colors={color.defaultScheme} tooltip={"Count of configurations <br>"} width={1000} height={400} radius={200}></DonutChart>
                    </Grid>
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <div className="subText">
                            <Typography >
                                Using an import for the customer crowdsourcded data, this pie chart gives an overview of the most common used discrepancy reasons for the Med ES Product.
                        </Typography>
                        </div>
                    </Grid>
                </Paper>



            </div >
        )
    }
}

export default Time