
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { API } from 'aws-amplify'
import color from '../shared/color'
import StoryTitle from '../components/StoryTitle'
import StoryTable from '../components/StoryTable'
import { Parallel, ScatterPlot, DonutChart, StackBarChart, Sunburst } from '../d3'
import { Typography, Divider, Grid, CircularProgress, colors } from '@material-ui/core'
import Paper from '@material-ui/core/Paper';
import '../story.css'

function JHReport() {

    const [title] = useState("Jon Henry Prod Report")
    const [summary] = useState("Analysis of the use of Jon Henry in Production since release.")
    const [categories] = useState(["report"])
    const [isLoading, setIsLoading] = useState(true)
    const [data, setData] = useState(null)
    let history = useHistory()

    useEffect(() => {
        async function fetch() {
            await API.get('qs-web-api', '/data/jhreport/data.json', {})
                .then((storyData) => {
                    setData(storyData)
                })
                .catch(function (error) {
                    console.log(error)
                })

            setIsLoading(false)
        }

        fetch()
    }, [])

    if (isLoading) {
        return (<div className="circular-progress"><CircularProgress disableShrink /></div>)
    } else {
        return (
            
            <div id="story">
                <StoryTitle title={title} date={new Date(data.date.toLocaleString('en-US', { timeZone: 'America/Los_Angeles' }))} categories={categories}></StoryTitle>
                
                <Paper className="storySection">
                    <Grid container justify="center">
                        <div className="description">
                            <Typography variant="h6" fontWeight="bold">
                                Summary
                            </Typography>
                        </div>
                    </Grid>
                    <Grid container justifyItems="center" spacing={2} alignItems="stretch">
                        <div className="subText">
                            <Typography>
                                <p>
                                {data['metaData'].comments.commentSum}
                                 </p>
                            </Typography>
                        </div>
                    </Grid>
                </Paper>

                <Paper className="storySection">
                    <Grid container justify="center">
                        <div className="description">
                            <Typography variant="h6" fontWeight="bold">
                                Jon Henry Use Summary 
                            </Typography>
                        </div>
                    </Grid>
                    <Grid container justifyItems="center" spacing={2} alignItems="stretch">
                        <div className="subText">
                            <Typography>
                                <p>
                                    {data['metaData'].comments.commentUse}
                                </p>
                                <p>
                                    {data['metaData'].comments.commentTrend}
                                    <br/>
                                    Hover over the line or legend text to display the selection more clearly.
                                </p>
                            </Typography>
                        </div>
                    </Grid>
                    <Grid container justify-content="space-evenly" justifyItems="center" alignt-content="space-evenly" align-itmes="center">
                        <ScatterPlot 
                            data={data['metaData'].data.usePerDay} 
                            axis={{x: "Date", y: "Use per Day"}} 
                            colors={color.defaultScheme} 
                            addDots={false} 
                            addLine={true} 
                            width={450} 
                            height={400} 
                            legendOffsetX={450}>
                        </ScatterPlot>
                        <ScatterPlot 
                            data={data['metaData'].data.itemsPerDay} 
                            axis={{x: "Date", y: "Items per Day"}} 
                            colors={color.defaultScheme} 
                            addDots={false} 
                            addLine={true} 
                            width={450} 
                            height={400} 
                            legendOffsetX={450}>
                        </ScatterPlot>
                    </Grid>
                    <Grid container justifyItems="center" spacing={2} alignItems="stretch">
                        <div className="subText">
                            <Typography>
                                <p>
                                    {data['metaData'].comments.commentTime}
                                </p>
                                <p>
                                    {data['metaData'].comments.commentErrors}
                                </p>
                                <p>
                                    {data['metaData'].comments.commentSaveAs}
                                </p>
                                
                            </Typography>
                        </div>
                    </Grid>
                </Paper>

                <Paper className="storySection">
                    <Grid container justify="center">
                        <div className="description">
                            <Typography variant="h6" fontWeight="bold">
                                Jon Henry Risk Score Summary 
                            </Typography>
                        </div>
                    </Grid>
                    <Grid container justifyItems="center">
                        <div className="subText">
                            <Typography>
                                <p>
                                    {data['metaData'].comments.commentRisk}
                                </p>
                            </Typography>
                        </div>
                    </Grid>
                    <Grid container justifyContent="flex-end">
                        <StackBarChart 
                            data={data['metaData'].data.vRiskScoreByUseH} 
                            axis={{x: "Risk Score", y: "N" }} 
                            colors={color.defaultScheme} 
                            tooltip={"Histogram of Mean Risk Score for Each Submission"} 
                            width={500} 
                            height={350} 
                            legendOffsetX={300}
                            legendOffsetY={200}>
                        </StackBarChart>
                        <StackBarChart 
                            data={data['metaData'].data.vAllRiskScoresH} 
                            axis={{x: "Risk Score", y: "N" }} 
                            colors={color.defaultScheme} 
                            tooltip={"Histogram of Risk Score by Item"} 
                            width={500} 
                            height={350} 
                            legendOffsetX={300}
                            legendOffsetY={200}>
                        </StackBarChart>
                    </Grid>
                    <Grid container justifyItems="center">
                        <div className="subText">
                            <Typography>
                                <p>
                                    Cumulative distribution of Risk Score and Proportion of Risk Categories for items
                                </p>
                            </Typography>
                        </div>
                    </Grid>
                    <Grid container justifyContent="center" align-items="center">
                        <ScatterPlot 
                            data={data['metaData'].data.cumDRisk} 
                            axis={{x: "Risk Score", y: "% of Distribution"}}
                            xType={"line"} 
                            colors={color.defaultScheme} 
                            addDots={false} 
                            addLine={true} 
                            width={450} 
                            height={220} 
                            legendOffsetX={200}
                            legendOffsetY={30}>
                        </ScatterPlot>
                        <DonutChart 
                            data={data['metaData'].data.donutRisk} 
                            colors={color.defaultScheme} 
                            tooltip={"Total items: "} 
                            width={550} 
                            height={300} 
                            radius={140}>
                        </DonutChart>
                    </Grid>
                    <Grid container justifyItems="center">
                        <div className="subText">
                            <Typography>
                                <p>
                                    For more information, view&nbsp;
                                    <a 
                                        href = "https://bddevops.atlassian.net/wiki/spaces/PE/pages/323158744/Jon+Henry+Risk+Score"
                                        target = "_blank">
                                            Jon Henry Risk Score
                                    </a>
                                </p>
                            </Typography>
                        </div>
                    </Grid>
                </Paper>
                
                <Paper className="storySection">
                    <Grid container justify="center">
                        <div className="description">
                        <Typography variant="h6" fontWeight="bold">
                            Jon Henry Risk Score Examples
                        </Typography>
                    </div>
                    </Grid>
                    <Grid container justifyItems="center">
                        <div className="subText">
                            <Typography>
                                <p>
                                    {data['metaData'].comments.commentHighRisk1}
                                </p>
                                <p>
                                    {data['metaData'].comments.commentHighRisk2}
                                </p>
                                <p>
                                    {data['metaData'].comments.commentHighRisk3}
                                </p>
                            </Typography>
                        </div>
                        <StoryTable 
                            data={data['metaData'].data.examplesHighRisk} 
                            layout={["as is"]} 
                            rows={8}>
                        </StoryTable>
                    </Grid>
                    <Grid container justifyItems="center">
                        <div className="subText">
                            <Typography>
                                <p>
                                {data['metaData'].comments.commentLowRisk}
                                </p>
                            </Typography>
                        </div>
                        <StoryTable 
                            data={data['metaData'].data.examplesLowRisk} 
                            layout={["as is"]} 
                            rows={8}>
                        </StoryTable>
                    </Grid>
                </Paper>

                <Paper className="storySection">
                    <Grid container justify="center">
                        <div className="description">
                            <Typography variant="h6" fontWeight="bold">
                                Jon Henry Input Quality Summary
                            </Typography>
                        </div>
                        <div className="subText">
                            <Typography>
                                <p>
                                    {data['metaData'].comments.commentInput1}
                                </p>
                                <p>
                                    {data['metaData'].comments.commentInput2}
                                </p>
                                <p>
                                    {data['metaData'].comments.commentInput3}
                                </p>
                                <p>
                                    {data['metaData'].comments.commentInput4}
                                </p>
                                <p>
                                    {data['metaData'].comments.commentInput5}
                                </p>
                                <p>
                                    {data['metaData'].comments.commentInput6}
                                </p>
                                <p>
                                    {data['metaData'].comments.commentInput7}
                                </p>
                            </Typography>
                        </div>
                    </Grid>
                </Paper>
                
                <Paper className="storySection">
                    <Grid container justify="center">
                        <div className="description">
                            <Typography variant="h6" fontWeight="bold">
                                Jon Henry Input Quality Examples
                            </Typography>
                        </div>
                    </Grid>
                    <Grid container justifyItems="center">
                        <div className="subText">
                            <Typography>
                                <p>
                                    The following graph illustrates how the input can lead to the assignment of a BDL ID or a not.
                                    Hover over a ribbon to see more details. Click on an item to highlight
                                    the information from that item, or click on a grouping box that
                                    the ribbons pass through to highlight that relative information.
                                    Click on the background of the graph to deselect.
                                </p>
                            </Typography>
                        </div>
                        <Parallel 
                            data={data['metaData'].data.pSetDF2Long} 
                            colors={color.defaultScheme} 
                            width={1000} 
                            height={700}>
                        </Parallel>
                        <div className="subText">
                            <Typography>
                                <p>
                                    {data['metaData'].comments.commentPar1}
                                </p>
                                <p>
                                    {data['metaData'].comments.commentPar2}
                                </p>
                            </Typography>
                        </div>
                    </Grid>
                    <Grid container justifyItems="center">
                        <div className="subText">
                            <Typography>
                                <p>
                                    The following table gives the data displayed and discussed above and more examples.
                                </p>
                            </Typography>
                        </div>
                        <StoryTable 
                            data={data['metaData'].data.parData2} 
                            layout={["as is"]}>
                        </StoryTable>
                    </Grid>
                    <Grid container justifyItems="center" alignItems="stretch" justify-conent="center">
                        <div className="subText">
                            <Typography>
                                <br/>
                                <p>
                                    {data['metaData'].comments.commentDF}
                                </p>
                                <p>
                                    The interactive chart below shows submitted dosage forms grouped into ones that Jon Henry
                                    was able to turn into a BDL dosage form and ones that it wasn't. Click on either category
                                    to see the original submitted data. For items that had a BDL dosage form assigned, click
                                    on the original dosage form to see the BDL dosage forms assigned to that description.
                                    Click in the middle to back out.
                                </p>
                            </Typography>
                        </div>
                    </Grid>
                    <Grid container justify="center">
                        <Sunburst 
                            data={data['metaData'].data.sunDF} 
                            colors={['#4e79a7', '#f28e2c']} 
                            w={1000}>
                        </Sunburst>
                        <p>
                            This graph demonstrates what types of input will not lead to a dosage form or will lead to
                            ambiguous ones.
                        </p>
                    </Grid>
                </Paper>

                <Paper className="storySection">
                    <Grid container justify="center">
                        <div className="description">
                            <Typography variant="h6" fontWeight="bold">
                                Additional Uses for Jon Henry
                            </Typography>
                        </div>
                    </Grid>
                    <Grid container justifyItems="center">
                        <div className="subText">
                            <Typography>
                                <p>
                                    Jon Henry has also been used to parse and analyze all of the PLX data to 
                                    better understand custumers, different types and uses by the customer, and
                                    to analyze customer input quality.&nbsp;
                                    <a 
                                        href = 'https://bdlytics.stage.bdservices.io/dashboard/PLX-JonHenry' 
                                        target = '_blank'>
                                            View the BDLytics dashboard.
                                    </a>
                                </p>
                                <p>
                                    Jon Henry has also been used with TIME to parse and analyze all MED ES data,
                                    transforming formulary text into quantifiable structured data.
                                    This is currently being used to group customers into different K-Means
                                    clusters and to predict device configurations.
                                </p>
                            </Typography>
                        </div>
                    </Grid>
                </Paper>

                <Paper className="storySection">
                    <Grid container justify="center">
                        <div className="description">
                            <Typography variant="h6" fontWeight="bold">
                                Additional Material
                            </Typography>
                        </div>
                    </Grid>
                    <Grid container justifyItems="center">
                        <div className="subText">
                            <Typography>
                                <ul>
                                    <li>
                                        <a 
                                            href = "https://bddevops.atlassian.net/wiki/spaces/PE/pages/323125249/Jon+Henry+API+Manual"
                                            target = "_blank">
                                                Jon Henry API Manual
                                        </a>
                                    </li>
                                    <li>
                                        <a 
                                            href = "https://bddevops.atlassian.net/wiki/spaces/PE/pages/323158744/Jon+Henry+Risk+Score"
                                            target = "_blank">
                                                Jon Henry Risk Score
                                        </a>
                                    </li>
                                    <li>
                                        <a 
                                            href = 'https://bdlytics.stage.bdservices.io/dashboard/PLX-JonHenry' 
                                            target = '_blank'>
                                                Jon Henry PLX Dashboard
                                        </a>
                                    </li>
                                </ul>
                            </Typography>
                        </div>
                    </Grid>
                </Paper>


            </div >
        )
    }
}

export default JHReport