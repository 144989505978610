import React, { useEffect, useState } from 'react'
import { DiWindows, DiDatabase } from 'react-icons/di'
import { MdAccessTime, MdLockOutline, MdSecurity,MdSmsFailed,MdAccessTimeFilled,MdLeaderboard,MdVolumeUp } from 'react-icons/md'
import { ImHourGlass } from 'react-icons/im'
import { GiSpeedometer } from 'react-icons/gi'
import { GoReport } from 'react-icons/go'
import { TbAlertTriangle } from 'react-icons/tb'
import { FiBatteryCharging } from 'react-icons/fi'
import { BiReset,BiRocket } from 'react-icons/bi'
import color from '../shared/color'
import './AutoIcons.css'


const categories = [
    "implementation",
    "database",
    "microsoft",
    "security",
    "risk",
    "time",
    "performance",
    "reboot",
    "report",
    "battery",
    "AccessTime",
    "MdLeaderboard",
    "TbAlertTriangle",
    "VolumeUp",
    "BiRocket",
    "TbMathMax"
]


const autoColor = (category) => {
 return color.defaultScheme[categories.indexOf(category)]
}

function AutoIcons(props) {
    const autoIco = (className) => {
        return (props.categories.map(category => {
            category = category.trim()
            switch (categories.indexOf(category)) {
                case 0:
                    return (<MdAccessTime className={className}></MdAccessTime>)
                case 1:
                    return (<DiDatabase className={className}></DiDatabase>)
                case 2:
                    return (<DiWindows className={className}></DiWindows>)
                case 3:
                    return (<MdSecurity className={className}></MdSecurity>)
                case 4:
                    return (<MdLockOutline className={className}></MdLockOutline>)
                case 5:
                    return (<ImHourGlass className={className}></ImHourGlass>)
                case 6:
                    return (<GiSpeedometer className={className}></GiSpeedometer>)
                case 7:
                    return (<BiReset className={className}></BiReset>)
                case 8:
                    return (<GoReport className={className}></GoReport>)
                case 9:
                    return (<FiBatteryCharging className={className}></FiBatteryCharging>)
                case 10:
                    return (<MdAccessTimeFilled className={className}></MdAccessTimeFilled>)
                case 11:
                    return (<MdLeaderboard className={className}></MdLeaderboard>)
                case 12:
                    return (<TbAlertTriangle className={className}></TbAlertTriangle>)
                case 13:
                    return (<MdVolumeUp className={className}></MdVolumeUp>)
                case 14:
                    return (<BiRocket className={className}></BiRocket>)

            }
        }))      
    }

    if (props.categories.length == 0) {
        return (
            <div>
            </div>
        )
    } else if (props.categories.length == 1) {
        let className = "oneIcon"
        return (
            <div className="oneTop">
                 {autoIco(className)}
            </div>
        )
    } else if (props.categories.length == 2) {
        let className = "twoIcon"
        return (                          
            <div className="twoTop">
                 {autoIco(className)}
            </div>
        )
   } else if (props.categories.length >= 3) {
        let className = "threeIcon"
        return (                          
            <div className="threeTop">
                {autoIco(className)}
            </div>
        )
    }
}
export default AutoIcons
export { autoColor }