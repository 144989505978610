import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import * as d3 from "d3"

function BarChart(props) {
    const [divId, setDivId] = useState("barChart")
    const [drawn, setDrawn] = useState(false)
    let history = useHistory()

    const drawChart = (data, w, h,tooltip, axis) => {
        if (!drawn) {
            if (!w)
                w = 900
            if (!h)
                h = 450
            if (!tooltip)
                tooltip = "This cell contains: "

            let margin = {top: 10, right: 30, bottom: 30, left: 40},
                width = w - margin.left - margin.right,
                height = h - margin.top - margin.bottom

            // append the svg object to the body of the page
            let svg = d3.select(`#${divId}`)
                .append("svg")
                .attr("width", width + margin.left + margin.right)
                .attr("height", height + margin.top + margin.bottom)
                .append("g")
                .attr("transform",
                    "translate(" + margin.left + "," + margin.top + ")")

            // X axis: scale and draw:
            let x = d3.scaleBand()
                .domain(data.map(function(d) { return d.name; }))
                .range([0, width])
                .padding(0.2)

            svg.append("g")
                .attr("class", "axis")
                .attr("transform", "translate(0," + height + ")")
                .call(d3.axisBottom(x))
                .selectAll("text")
                  .style("text-anchor", "end")

            // Y axis: initialization
            let y = d3.scaleLinear()
                .domain([0, 70])
                .range([height, 0])

            svg.append("g")
                .call(d3.axisLeft(y))

            // X axis label
            svg.append("text")
                .attr("text-anchor", "end")
                .attr("x", width + margin.left + margin.right)
                .attr("y", height + 25)
                .text(axis.x)
                .style('fill','white')

            // Y axis label
            svg.append("text")
                .attr("text-anchor", "end")
                .attr("transform", "rotate(-90)")
                .attr("y", - 30)
                .attr("x", - height / 4 + margin.bottom)
                .text(axis.y)
                .style('fill','white')

            // create a tooltip
            let Tooltip = d3.select(`#${divId}`)
                .append("div")
                .style("opacity", 0)
                .style('display','none')
                .attr("class", "tooltip")
                .style("background-color", "#323232")
                .style("border", "solid")
                .style("border-width", "2px")
                .style("border-radius", "5px")
                .style("padding", "5px")

            // Three function that change the tooltip when user hover / move / leave a cell
            let mouseover = function (d) {
                Tooltip.style("opacity", 1)
                d3.select(this)
                    .style("stroke", "white")
                    .style("opacity", 1)
            }

            let mousemove = function (d) {
                if(d.srcElement) {
                    Tooltip
                    .style('display','block')

                    Tooltip
                    .html(`<b>${d.srcElement.__data__.name}</b><br/>${tooltip}${d.srcElement.__data__.value}`)
                        .style("position", "absolute")
                        .style("left", `${d.pageX}px`)
                        .style("top", `${d.pageY + 25}px`)
                }
            }

            let mouseleave = function (d) {
                Tooltip
                    .style("opacity", 0)
                d3.select(this)
                    .style("stroke", "none")
                    .style("opacity", 0.8)
            }

            svg.append("g")
                .selectAll("rect")
                .data(data)
                .enter()
                .append("rect")
                .attr("x", x(0))
                .attr("y", (d, i) => y(d.y))
                .attr("width", d => x(d.x) - x(0))
                .attr("height", ((height - margin.top - margin.bottom) / data.length) - 3)

 
            // Draw the chart
              svg.append("g")
                .selectAll('bars')
                .data(data)
                .enter()
                .append("rect")
                  .attr("x", function(d) { return x(d.name); })
                  .attr("y", function(d) { return y(d.value); })
                  .attr("width", x.bandwidth())
                  .attr("height", function(d) { return height - y(d.value); })
                  .attr("fill", "#69b3a2")
                  .on("mouseover", mouseover)
                  .on("mousemove", mousemove)
                  .on("mouseleave", mouseleave)
        }
        setDrawn(true)
    }
    useEffect(() => {
            drawChart(props.data, props.w, props.h, props.tooltip, props.axis)
    }, [props])
    return (
        <div id={divId}></div>
    )
}
export default BarChart