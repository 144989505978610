import React, { useEffect } from 'react'
import { Typography, Grid, Divider, Link } from "@material-ui/core"

function DashboardList(props) {

    useEffect(() => {

    })
    return (
        <div className="drawer">
            <Grid container justify="center" spacing={2} alignItems="stretch" >
                <div className="homeTitle">
                    <Typography variant="h5">
                        Workbooks
                    </Typography>
                </div>
            </Grid>
            <Divider />
            <div className="workbookGridContainer">
                <Grid className="workbookGrid" container alignItems="stretch" direction="column" spacing={1}>
                    {props.workbooks.map((workbook) => (
                        <Grid item xs={3}>
                            <Typography>
                                <Link href={workbook.url} className="workbookLinks" style={{color:"white"}}>
                                    {workbook.name}
                                </Link>
                            </Typography>
                        </Grid>
                    ))}
                </Grid>
            </div>
        </div>
    )
}

export default DashboardList