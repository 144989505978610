import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import * as d3 from "d3"
import * as d3Sankey from 'd3-sankey'

function Parallel(props) {
  const [divId, setDivId] = useState("Parallel"+ Math.floor(Math.random() * Math.floor(100000)))
  const [drawn, setDrawn] = useState(false)
  let history = useHistory()
  
  const drawChart = async (data, tooltip, colors, width, height) => {
    if (!drawn) {

      if (typeof tooltip == "undefined") {
        tooltip = "Group"
      }
      if (typeof width == "undefined") {
        width = 700
      }
      if (typeof height == "undefined") {
        height = 600
      }
      if (typeof colors == "undefined") {
        colors = [
          "gold", 
          "#157DD8",
          "#F28E2C", 
          "#03DAC6", 
          "#7033FF", 
          "lightgrey", 
          "#BB86FC", 
          "#018786", 
          "#A1580E"
        ]
      }
      
      for (let i = 0; i < colors.length; i++) {
        let s = new Option().style
        s.color = colors[i].toString()
        if (s.color == '') {
            colors[i] = "blue"
        }
      }

      let margin = { top: 50, right: 150, bottom: 50, left: 80 }

      let keys = Object.keys(data[0])
      keys = keys.splice(0, keys.length-1)
      
      // get groups so can track colors
      let domain = []
      data.forEach(member => {
        if (!domain.includes(member[keys[0]])) {
          domain.push(member[keys[0]])
        }
      })

      // Add a color scale
      let myColor = d3.scaleOrdinal()
          .domain(domain)
          .range(colors)

      // make a function to assign nodes and links to graph
      let makeGraph = function(keys,data) {
          let index = -1
          const nodes = []
          const nodeByKey = new Map
          const indexByKey = new Map
          const links = []
        
          for (const k of keys) {
            for (const d of data) {
              const key = JSON.stringify([k, d[k]])
              if (nodeByKey.has(key)) continue;
              const node = {name: d[k]}
              nodes.push(node)
              nodeByKey.set(key, node)
              indexByKey.set(key, ++index)
            }
          }
        
          for (let i = 1; i < keys.length; ++i) {
            const a = keys[i - 1]
            const b = keys[i]
            const prefix = keys.slice(0, i + 1)
            const linkByKey = new Map
            for (const d of data) {
              const names = prefix.map(k => d[k])
              const key = JSON.stringify(names)
              const value = d.value || 1
              let link = linkByKey.get(key)
              if (link) { link.value += value; continue; }
              link = {
                source: indexByKey.get(JSON.stringify([a, d[a]])),
                target: indexByKey.get(JSON.stringify([b, d[b]])),
                names,
                value
              }
              links.push(link)
              linkByKey.set(key, link)
            }
          }
        
          return {nodes, links}
        }
      
      //tool tip for mouse hover
      // create a tooltip
      let Tooltip = d3.select(`#${divId}`)
        .append("div")
        .style("opacity", 0)
        .style('display','none')
        .attr("class", "tooltip")
        .style("background-color", "#323232")
        .style("border", "solid")
        .style("border-width", "2px")
        .style("border-radius", "5px")
        .style("padding", "5px")

      let mouseover = function (d) {
        Tooltip.style("opacity", 1)
        
        let info = d.srcElement.__data__
        
        Tooltip
          .html(`<b>${tooltip}:</b> ${info.names.join(" → " )}<br/> <b>Value</b>:${info.value}`)
          .style('display','block')
          .style("position", "absolute")
          .style("left", `${d.pageX}px`)
          .style("top", `${d.pageY + 25}px`)
      }

      let mouseover2 = function (d) {
        Tooltip.style("opacity", 1)
        
        let info = d.srcElement.__data__
        
        Tooltip
          .html(`<b>${tooltip}:</b> ${info.name}\n${info.value.toLocaleString()}`)
          .style("position", "absolute")
          .style("left", `${d.pageX}px`)
          .style("top", `${d.pageY + 25}px`)
      }

      let mouseclick = function (d) {
        
        let info = d.srcElement.__data__
        let selectedGroup = ".pName-" + info.names[0].replace('/','').replace(/ /g, '_').replace(/\./g, '_')
        
        d3.selectAll("#ribbons")
          .interrupt() // stop mouse leave events
          .transition()
          .duration(10)
          .style("opacity", 0.2)
          
        d3.selectAll(selectedGroup)
          .interrupt()
          .transition()
          .duration(50)
          .style("opacity", 1)
          .style("mix-blend-mode", "normal") // don't mix the colors

      }

      let mouseclick2 = function (d) {
        let info = d.srcElement.__data__
        let selectedGroup = ".pName-" + info.name.replace('/','').replace(/ /g, '_').replace(/\./g, '_')
        
        d3.selectAll("#ribbons")
          .interrupt() // stop mouse leave events
          .transition()
          .duration(10)
          .style("opacity", 0.2)
          
        d3.selectAll(selectedGroup)
          .interrupt()
          .transition()
          .duration(50)
          .style("opacity", 1)
          .style("mix-blend-mode", "normal") // don't mix the colors

      }

      let mouseleave = function (d) {
        
        Tooltip
          .style("opacity", 0)
        }

      let mouseclickoff = function (d) {
        d3.selectAll("#ribbons")
        .transition()
        .duration(10)
        .style("opacity", 0.5)
        .style("mix-blend-mode", "soft-light")

      }

      // get nodes and iinks for data based on keys
      let graph = makeGraph(keys, data)

      // main d3-sankey function
      let sankey = d3Sankey.sankey()
          .nodeSort(null)
          .linkSort(null)
          .nodeWidth(4)
          .nodePadding(20)
          .extent([[0, 5], [width, height - 5]])

      // start svg
      const svg =  d3.select(`#${divId}`)
          .append('svg')
          .attr("width", width + margin.left + margin.right)
          .attr("height", height + margin.top + margin.bottom)
          .attr("viewBox", [0, 0, width, height])
      
      // create a blank background area for user to click on to deselect ribbons
      svg.append('rect')
          .attr('x', 0)
          .attr('y', 0)
          .attr('width', width + margin.left + margin.right)
          .attr("height", height + margin.top + margin.bottom)
          .attr("opacity", 0)
          .on("click", function(d) {mouseclickoff(d)})

      const {nodes, links} = sankey({
          nodes: graph.nodes.map(d => Object.assign({}, d)),
          links: graph.links.map(d => Object.assign({}, d))
      })

      svg.append("g")
          .selectAll("rect") // the lines for each grouping
          .data(nodes)
          .join("rect")
          .attr("x", d => d.x0)
          .attr("y", d => d.y0)
          .attr("height", d => d.y1 - d.y0)
          .attr("width", d => d.x1 - d.x0)
          .style("fill", "white")
          .attr("stroke", "black")
          .attr("stroke-width", 1)
          .on("click", function(d) {mouseclick2(d)})
          .on("mouseover", function(d) {mouseover2(d)})
          .on("mouseleave", mouseleave)
      
      let getClass = function (d) {
        let className = [ ]
        for (let i=0; i < d.names.length; i++) {
          className[i] = d.names[i].replace('/','').replace(/ /g, '_').replace(/\./g, '_')
          className[i] = "pName-" + className[i]
        }
        className = className.join(' ')
        return className
      }

      svg.append("g") // the connecting ribbons
          .style("isolation", "isolate") // isolate colors from background as to not blend with it
          .attr("fill", "none")
          .selectAll("g")
          .data(links)
          .join("path")
          .attr("d", d3Sankey.sankeyLinkHorizontal())
          .attr("stroke", function (d) { return myColor(d.names[0]) })
          .attr("stroke-width", d => d.width)
          .style("opacity", 0.5)
          .style("mix-blend-mode", "soft-light") // how to blend colors of overlapping ribbons
          .attr("id", "ribbons")
          .attr("class", function (d) { return getClass(d) })
          .on("mouseover", function(d) {mouseover(d)})
          .on("click", function(d) {mouseclick(d)})
          .on("mouseleave", mouseleave)
      
      svg.append("g")
          .style("font", "11px sans-serif")
          .selectAll("text")
          .data(nodes)
          .join("text")
          .attr("x", d => d.x0 < width / 2 ? d.x1 + 6 : d.x0 - 6)
          .attr("y", d => (d.y1 + d.y0) / 2)
          .attr("dy", "0.35em")
          .attr("text-anchor", d => d.x0 < width / 2 ? "start" : "end")
          .append("tspan")
          .attr('startOffset','20%')
          .text(d => d.name)
          .style('fill', 'none')
          .style('stroke', '#fff')
          .style('stroke-width', 3)
          .style('stroke-linejoin', 'round')
          .attr('startOffset','20%')
          .text(d => d.name)
          .append("tspan")
          .style("fill", "white")
          .attr("fill-opacity", 0.8)
          .text(d => ` ${d.value.toLocaleString()}`) // numeric values
        
      svg.append("g") 
          .style("font", "11px sans-serif")
          .selectAll("text")
          .data(nodes)
          .join("text")
          .attr("x", d => d.x0 < width / 2 ? d.x1 + 6 : d.x0 - 6)
          .attr("y", d => (d.y1 + d.y0) / 2)
          .attr("dy", "0.35em")
          .attr("text-anchor", d => d.x0 < width / 2 ? "start" : "end")
          .append("tspan")
          .style("fill", "black")
          .text(d => d.name) // group names listed under keys
          .append("tspan")
          .style("fill", "black")
          .attr("fill-opacity", 0.8)
          .text(d => ` ${d.value.toLocaleString()}`) // numeric values
     
      setDrawn(true)
      svg.node()
    }
  }

  useEffect(() => {
    if (props.data) {
      drawChart(props.data, props.tooltip, props.colors, props.width, props.height)
    }
  }, [props])

  return (
    <div id={divId}></div>
  )
}

export default Parallel