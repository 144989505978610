export default {
    defaultScheme: [
        "gold", 
        "#157DD8",
        "#F28E2C", 
        "#03DAC6", 
        "#7033FF", 
        "lightgrey", 
        "#BB86FC", 
        "#018786", 
        "#A1580E"
    ],
    tableauScheme: [
        "#4e79a7",
        "#f28e2c",
        "#59a14f",
        "#edc949",
        "#af7aa1",
        "#ff9da7",
        "#9c755f",
        "#bab0ab"
    ]

}