import React, { useState, useEffect } from 'react'
import { Typography, Grid, Link } from '@material-ui/core'
import { Reports } from './Reports'

function ReportList(props) {
  const [reports, setReports] = useState([])

  useEffect(() => {
    let items = []
    Reports.forEach((report) => {
      items.push({
        name: report.title,
        onClick: props.toggleDrawer(report),
      })
    })
    setReports(items)
  }, [Reports])

  return (
    <div id="ReportList" style={{ marginTop: '5em' }}>
      <div>
        <Typography variant="h4">Reports</Typography>
      </div>
      <Grid
        style={{ marginTop: '2em' }}
        className="workbookGrid"
        container
        alignItems="stretch"
        direction="column"
        spacing={1}
      >
        {reports.map((report) => (
          <Grid item xs={3}>
            <Typography>
              <Link
                onClick={report.onClick}
                className="workbookLinks"
                component="button"
                style={{ color: 'white' }}
              >
                {report.name}
              </Link>
            </Typography>
          </Grid>
        ))}
      </Grid>
    </div>
  )
}

export default ReportList
