export const Reports = [
  {
    id: 'mparAIR',
    title: 'MPAR Actionable Intelligence Report',
    desc: 'The MPAR AIR uses the BDX login as the email for the report. Additional emails can be added after the report is sent to prevent spamming. The facility id for each site can be found on the MPAR Report. Request more than one report for multi facility customer IDNs.',
    input: 'Facility ID for Report',
    url: '/email/mpar-air',
  },
  {
    id: 'mparReport',
    title: 'Pyxis Proactive Customer Alerts',
    desc: 'The Pyxis Proactive Customer Alerts uses the BDX login as the email for the report. Additional emails can be added after the report is sent to prevent spamming. The facility id for each site can be found on the MPAR Report. Request more than one report for multi facility customer IDNs.',
    input: 'Facility ID for Report',
    url: '/email/mpar-report',
  },
  {
    id: 'mparBatchReport',
    title: 'Pyxis Proactive Customer Alerts Batch',
    desc: 'The Pyxis Proactive Customer Alerts Batch uses the BDX login as the email for the report. Additional emails can be added after the report is sent to prevent spamming. The facility id for each site can be found on the MPAR Report. Request more than one report for multi facility customer IDNs.',
    input: 'Customer ID for Batch Reports',
    url: '/email/mpar-batch-report',
  },
  {
    id: 'mparSuggestedPockets',
    title: 'MPAR Suggested Pockets',
    desc: 'The MPAR Suggested Pocket Report uses the BDX login as the email for the report. Additional emails can be added after the report is sent to prevent spamming. The facility id for each site can be found on the MPAR Report. Request more than one report for multi facility customer IDNs.',
    input: 'Facility ID for Report',
    url: '/email/mpar',
  }
]
