import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { API } from 'aws-amplify'
import color from '../shared/color'
import StoryTitle from '../components/StoryTitle'
import StoryTable from '../components/StoryTable'
import { DonutChart, ThresholdBarChart } from '../d3'
import { Typography, Divider, Grid, CircularProgress, Paper} from '@material-ui/core'
import '../story.css'

function SQLEndOfLife() {
    const [isLoading, setIsLoading] = useState(true)
    const [title] = useState("Microsoft SQL Server End of Life")
    const [summary] = useState("The current state of our SQL Versions installed on all connected customer MMS Devices and their support status.")
    const [categories] = useState(["database","microsoft"])
    const [data, setData] = useState({})
    const [table1Layout, setTable1Layout] = useState(null)
    const [table2Layout, setTable2Layout] = useState(null)
    const [table3Layout, setTable3Layout] = useState(null)
    let history = useHistory()
    
    useEffect(() => {
        setTable1Layout([
            {column:"Microsoft SQL Support", field:"Validity"},
            {column:"Total Devices", field: "DeviceCounts"},
            {column:"% of Total Devices",field:"%ofTotalDevices"}
        ])
        setTable2Layout([
            {column:"SQL Application", field:"SQLApplication"},
            {column:"Release Version", field: "ReleaseVersion"},
            {column:"Extended Support End Date",field:"SupportEndDate"},
            {column:"% of Devices",field:"%ofDevices"}
        ])
        setTable3Layout([
            {column:"Microsoft SQL Support", field:"MicrosoftSQLSupport"},
            {column:"% of Med ES Stations", field: "%ofMedESStations"},
            {column:"% of PAS ES Devices",field:"%ofPASDevices"},
            {column:"% of Legacy & PLX Servers",field:"%ofLegacy&PLXServers"}
        ])
        async function fetch() {
            //Read the data
            await API.get('qs-web-api', '/data/sqlendoflife/sqlendoflife.json', {})
                .then((storyData) => {
                    setData(storyData)
                })
                .catch(function (error) {
                    console.log(error)
                })
            setIsLoading(false)
        }
        fetch()
    }, [])
    if (isLoading) {
        return (<div className="circular-progress"><CircularProgress disableShrink /></div>)
    } else {
        return (
            <div id="story">
                <StoryTitle title={title} date={data.date} categories={categories}></StoryTitle>

                <Paper className="storySection">
                <Grid container justify="center" spacing={2} alignItems="stretch">
                    <div className="subText">
                        <Typography>
                            Microsoft supports SQL Server application throughout the life of a product. The end of life (EOL) depends on the SQL Application release version.
                            This article focuses on tracking the SQL Version installed on all the MMS Devices and their EOL.
                            All the Online, Live, Production, MMS device types with a SQL Server Application are taken into consideration.
                        </Typography>
                    </div>
                </Grid>
                </Paper>

                <Paper className="storySection">
                    <Grid container justify="center" spacing={2} alignItems="stretch">
                        <div className="description">
                            <Typography variant="h6" fontWeight="bold">
                                Device Counts based on their End of Life Support
                            </Typography>
                        </div>
                    </Grid>
                    <Grid container justify="center" spacing={2} alignItems="stretch">
                        <ThresholdBarChart data={data.stackedbarEOLdrill} colors={["#157DD8","#B00020"]} axis={{x: "Number of Devices",y: "Date of Expiry"}} width={900} height={450} threshold={Date.now()} tooltip={"Devices on this version:<br>"}></ThresholdBarChart>
                    </Grid>
                </Paper>

                <Paper className="storySection">
                    <Grid container justify="center" spacing={2} alignItems="stretch">
                        <div className="description">
                            <Typography variant="h6" fontWeight="bold">
                                Overall Compliance Status of Microsoft Support in MMS Devices and Servers
                            </Typography>
                        </div>
                    </Grid>
                    <StoryTable data={data.tableCompliance} layout={table1Layout}></StoryTable>
                    <Grid container justify="center" spacing={2} alignItems="stretch">
                        <div className="subText">
                            <Typography>
                                On a total of {data.totaldevices} devices, {data.expireddevices} have SQL Applications for which Microsoft no longer provides support.
                                Among those non-compliant devices, SQL 2016 SP1 support has expired on 2019-07-09 and SQL 2012 SP3 has expired on 2018-10-09, which are the top 2 most common versions found.
                            </Typography>
                        </div>
                    </Grid>
                </Paper>

                <Paper className="storySection">
                    <Grid container justify="center" spacing={2} alignItems="stretch">
                        <div className="description">
                            <Typography variant="h6" fontWeight="bold">
                                Device Counts per SQL Application Version (Only Non-Compliant)
                            </Typography>
                        </div>
                    </Grid>
                    <Grid container justify="center" spacing={2} alignItems="stretch">
                        <DonutChart id={1} data={data.donutExpiredCounts} colors={color.defaultScheme} tooltip={"Number of Devices: "}
                            width={800} height={400} radius={200}></DonutChart>
                    </Grid>
                    <Grid container justify="center" spacing={2} alignItems="stretch">
                        <div className="subText">
                            <Typography>
                                {data.validdevices} devices have SQL Applications for which Microsoft Support is valid.
                                Majority of them have SQL Server 2016 SP2, which expires in July, 2026
                            </Typography>
                        </div>
                    </Grid>
                </Paper>

                <Paper className="storySection">
                    <Grid container justify="center" spacing={2} alignItems="stretch">
                        <div className="description">
                            <Typography variant="h6" fontWeight="bold">
                                Percentage of devices per SQL App Version (all types of MMS devices and servers)
                            </Typography>
                        </div>
                    </Grid>
                    <StoryTable data={data.tableGeneralScores} layout={table2Layout}></StoryTable>
                </Paper>

                <Paper className="storySection">
                <Grid container justify="center" spacing={2} alignItems="stretch">
                    <div className="description">
                        <Typography variant="h6" fontWeight="bold">
                            Focusing SQL EOL Compliance on MED ES Station, PAS-ES Station, and PLX Servers
                        </Typography>
                    </div>
                </Grid>
                <StoryTable data={data.tableESPASPLX} layout={table3Layout}></StoryTable>
                </Paper>

                <Paper className="storySection">
                    <Grid container justify="center" spacing={2} alignItems="stretch">
                        <div className="description">
                            <Typography variant="h6" fontWeight="bold">
                                MED ES Station SQL Compliance by Device Version
                            </Typography>
                        </div>
                    </Grid>
                    <Grid container justify="center" spacing={2} alignItems="stretch">
                        <DonutChart id={1} data={data.donutES} colors={color.defaultScheme} tooltip={"Number of Devices: "}
                            width={800} height={400} radius={200}></DonutChart>
                    </Grid>
                <Grid container justify="center" spacing={2} alignItems="stretch">
                    <div className="subText">
                        <Typography>
                            In Med ES Station, all the devices in 1.7 Version are SQL Compliant. ES 1.6 Version has both SQL Compliant and SQL Non-Compliant devices.
                            In all the other ES Versions, devices have only SQL Non-Complaint Applications.
                        </Typography>
                    </div>
                </Grid>
                </Paper>

                <Paper className="storySection">
                    <Grid container justify="center" spacing={2} alignItems="stretch">
                        <div className="description">
                            <Typography variant="h6" fontWeight="bold">
                                PAS ES SQL Compliance by Device Version
                            </Typography>
                        </div>
                    </Grid>
                    <Grid container justify="center" spacing={2} alignItems="stretch">
                        <DonutChart id={1} data={data.donutPAS} colors={color.defaultScheme} tooltip={"Number of Devices: "}
                            width={800} height={400} radius={200}></DonutChart>
                    </Grid>
                   <Grid container justify="center" spacing={2} alignItems="stretch">
                        <div className="subText">
                            <Typography>
                                Similar to Med ES Station, in PAS ES all the devices in 1.7 Version are SQL Compliant.
                                PAS 1.6 Version has both SQL Compliant and SQL Non-Compliant devices. In all the other PAS Versions, devices have only SQL Non-Complaint Applications.
                            </Typography>
                        </div>
                    </Grid>
                </Paper>

                <Paper className="storySection">
                <Grid container justify="center" spacing={2} alignItems="stretch">
                    <div className="description">
                        <Typography variant="h6" fontWeight="bold">
                            PLX Server and Legacy Compliance by Version
                        </Typography>
                    </div>
                </Grid>
                <Grid container justify="center" spacing={2} alignItems="stretch">
                    <DonutChart id={1} data={data.donutPLX} colors={color.defaultScheme} tooltip={"Number of Devices: "}
                        width={900} height={400} radius={200}></DonutChart>
                </Grid>
                    <Grid container justify="center" spacing={2} alignItems="stretch">
                        <div className="subText">
                            <Typography>
                                In Legacy and PLX Servers, majority of the Legacy devices have Non-Compliant SQL Server Application.
                                Device Version is identified based on the device application version.
                                In cases when the application information is not available, the version found in RSS is directly used.
                                A version is marked unknown, when neither of the 2 values are available, but the device type is found.
                                Data source is RSS.
                            </Typography>
                        </div>
                    </Grid>
                </Paper>
            </div>
        )
    }
}
export default SQLEndOfLife