import React, { useEffect, useState } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { embedDashboard } from 'amazon-quicksight-embedding-sdk'
import { API, Analytics } from 'aws-amplify'
import './SelectDashboard.css'

export default function SelectedDashboard(props) {
  const [isLoading, setIsLoading] = useState(true)
  const onVisualLoaded = () => {}

  const onError = (error) => {
    console.log(error)
  }

  useEffect(async () => {
    var iframe = document.getElementsByClassName(
      'quicksight-embedding-iframe dashboard',
    )
    if (iframe) {
      if (iframe[0]) {
        iframe[0].remove()
      }
    }

    if (props.match.params.dashboardId) {
      try {
        let dashboards = []
        await API.get('qs-web-api', '/dashboards', {})
          .then((listDashboardsResponse) => {
            listDashboardsResponse.forEach((dashboard) => {
              let name = dashboard.Name.replace(/ /g, '_')
              dashboards.push({
                name: dashboard.Name,
                url: `/dashboard/${name}`,
                id: dashboard.DashboardId,
              })
            })
          })
          .catch(function (error) {
            console.log(error)
          })

        for (let i = 0; i < dashboards.length; i++) {
          let workbook = dashboards[i]
          if (`/dashboard/${props.match.params.dashboardId}` == workbook.url) {
            API.get('qs-web-api', `/dashboards/${workbook.id}/embedurl`, {})
              .then((getEmberUrlResponse) => {
                var options = {
                  url: getEmberUrlResponse.EmbedUrl,
                  parameters: window.location.search
                    .slice(1)
                    .split('&')
                    .map((p) => p.split('='))
                    .reduce(
                      (obj, [key, value]) => ({ ...obj, [key]: value }),
                      {},
                    ),
                  container: document.getElementById('root'),
                  className: 'dashboard',
                }
                const dashboard = embedDashboard(options)
                dashboard.on('error', onError)
                dashboard.on('load', onVisualLoaded)
                setIsLoading(false)
              })
              .catch(function (error) {
                console.log('Error obtaining QuickSight dashboard embed url.')
                console.log(error)
              })
          }
        }
      } catch (error) {
        console.log(error)
      }
    }
  }, [props.auth.user, props.match.params.dashboardId])

  if (isLoading) {
    return (
      <div className="circular-progress">
        <CircularProgress disableShrink />
      </div>
    )
  } else {
    return <div></div>
  }
}
