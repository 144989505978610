import React, { useEffect, useState } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { API } from 'aws-amplify'
import { Typography, Paper, Grid, Divider, Link } from "@material-ui/core"
import AutoIcons from './AutoIcons'
import './Home.css'

function Home(props) {

    const [isLoading, setIsLoading] = useState(true)
    const [data, setData] = useState(null)

    useEffect(() => {
        var iframe = document.getElementsByClassName("quicksight-embedding-iframe dashboard")
        if (iframe && iframe[0]) {
            iframe[0].remove()
        }

        async function fetch() {
            await API.get('qs-web-api', '/data/home/bdlyticsMainPage.json', {})
                .then((data) => {
                    setData(data)
                })
                .catch(function (error) {
                    console.log(error)
                })
            setIsLoading(false)
        }
        fetch()

    }, [props.auth.isAuthenticated])


    if (isLoading) {
        return (<div className="circular-progress"><CircularProgress disableShrink /></div>)
    } else {
        return (
            <div className="root">
                <Grid container spacing={2} className="homeTop">
                    <Grid className="homeTitle">
                        <Typography variant="h5">
                            Welcome to BDlytics
                            </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12} className="topDivider"></Grid>
                    <Grid justify="center" spacing={4} className="homeDesc">
                        <Typography>
                            Surfacing relevant, impactful, data driven insights that can help organize and evolve the way we distribute, implement and support our products for our customers.
                            </Typography>
                    </Grid>
             
                    <Grid container xs={12} justify="center">
                        <Grid container justify="center" spacing={2} alignItems="stretch" >
                            {data.stories.sort((a, b) => (a.modified < b.modified) ? 1 : -1).slice(0, 3).map((story) => (
                                <Grid item key={story.name} xs={4} className="topCards" >
                                    <Link underline="none" href={story.name}>
                                        <Paper className="paper" elevation={3}>
                                            <div className="upperIcon">
                                                <AutoIcons categories={story.categories} />
                                            </div>
                                            <div className="cardTitle">
                                                <Typography variant="h5">
                                                    {story.title}
                                                </Typography>
                                            </div>
                                            <div className="revisionDate">
                                                <Typography variant="subtitle2">
                                                    Last updated {(new Date(Date.parse(story.modified))).toDateString()}
                                                </Typography>
                                            </div>
                                            <Typography variant="subtitle1" color="textSecondary">
                                                {story.description}
                                            </Typography>
                                        </Paper>
                                    </Link>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} className="topDivider">
                    <Divider />
                </Grid>
                <Grid container justify="center" spacing={2} alignItems="stretch" className="lowerSection">
                    {data.stories.map((story) => (
                        <Grid item key={story.name} xs className="lowerCards" >
                            <Link underline="none" href={story.name}>
                                <Paper className="paperLower">
                                    <div className="lowerIcon">
                                        <AutoIcons categories={story.categories} />
                                    </div>
                                    <div className="cardTitle">
                                        <Typography variant="body1">
                                            {story.title}
                                        </Typography>
                                    </div>
                                    <Typography variant="subtitle2" color="textSecondary">
                                        {story.description}
                                    </Typography>
                                </Paper>
                            </Link>
                        </Grid>
                    ))}
                </Grid>

            </div >
        )
    }
}
export default Home