import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import * as d3 from "d3"

function DonutChart(props) {
    const [isLoading, setIsLoading] = useState(true);
    const [divId] = useState("DonutChart" + Math.floor(Math.random() * Math.floor(100000)))
    const [drawn, setDrawn] = useState(false)

    let history = useHistory()

    const drawChart = async (data, width, height, colors, radius, tooltip) => {
        if (!drawn) {
            if (!width)
                width = 500
            if (!height)
                height = 400
            if (!radius)
                radius = 150
            if (!tooltip)
                tooltip = "This cell contains: "
            let dimensions = ({
                margin: {
                    top: 30,
                    right: 30,
                    bottom: 30,
                    left: 30,
                }
            })

            if(!Array.isArray(colors)){
                colors = colors.values()
              }
        
            let domain = []
            Object.keys(data).forEach(member => {
                if (!domain.includes(member)) {
                    domain.push(member)
                }
            })

            // create a tooltip
            let Tooltip = d3.select(`#${divId}`)
                .append("div")
                .style("opacity", 0)
                .style('display','none')
                .attr("class", "tooltip")
                .style("background-color", "#323232")
                .style("border", "solid")
                .style("border-width", "2px")
                .style("border-radius", "5px")
                .style("padding", "5px")

            // Three function that change the tooltip when user hover / move / leave a cell
            let mouseover = function (d) {
                Tooltip.style("opacity", 1)
                d3.select(this)
                    .style("stroke", "white")
                    .style("opacity", 1)
            }

            let mousemove = function (d) {
                Tooltip
                .html(`<b>${d.srcElement.__data__.data[0]}</b><br/>${tooltip}${d.srcElement.__data__.value}`)
                    .style('display','block')
                    .style("position", "absolute")
                    .style("left", `${d.pageX}px`)
                    .style("top", `${d.pageY + 25}px`)
            }

            let mouseleave = function (d) {
                Tooltip
                    .style("opacity", 0)
                d3.select(this)
                    .style("stroke", "none")
                    .style("opacity", 0.8)
            }

            let svg = d3.select(`#${divId}`)
                .append("svg")
                .attr("width", width)
                .attr("height", height)
                .append("g")
                .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")")

            let color = d3.scaleOrdinal()
                .domain(domain)
                .range(colors)

            let pie = d3.pie()
                .sort(null)
                .value(function (d) { return d[1] })

            let data_ready = pie(Object.entries(data))

            let arc = d3.arc()
                .innerRadius(radius * 0.5)
                .outerRadius(radius * 0.8)

            // Another arc that won't be drawn. Just for labels positioning
            let outerArc = d3.arc()
                .innerRadius(radius * 0.9)
                .outerRadius(radius * 0.9)

            svg
                .selectAll('allSlices')
                .data(data_ready)
                .enter()
                .append('path')
                .attr('d', arc)
                .attr('fill', function (d) { return (color(d.data[0])) })
                .style("stroke-width", "2px")
                .style("opacity", 0.7)
                .on("mouseover", mouseover)
                .on("mousemove", mousemove)
                .on("mouseleave", mouseleave)

            // Add the polylines between chart and labels:    
            svg
                .selectAll('allPolylines')
                .data(data_ready)
                .enter()
                .append('polyline')
                .attr("stroke", "grey")
                .style("fill", "none")
                .attr("stroke-width", 1)
                .attr('points', function (d) {
                    if(d.endAngle - d.startAngle<10*Math.PI/180) {
                        return 
                    } else {
                        let posA = arc.centroid(d) // line insertion in the slice
                        let posB = outerArc.centroid(d) // line break: we use the other arc generator that has been built only for that
                        let posC = outerArc.centroid(d); // Label position = almost the same as posB
                        let midangle = d.startAngle + (d.endAngle - d.startAngle) / 2 // we need the angle to see if the X position will be at the extreme right or extreme left
                        posC[0] = radius * 0.95 * (midangle < Math.PI ? 1 : -1); // multiply by 1 or -1 to put it on the right or on the left
                        return [posA, posB, posC]
                    }
                })

            // Add the polylines between chart and labels:
            let labels = svg
                .selectAll('allLabels')
                .data(data_ready)
                .enter()
                .append('text')
                .text(function (d) { if(d.endAngle - d.startAngle<10*Math.PI/180) { return "" } else { return d.data[0] }})

                .attr('transform', function (d) {
                    let pos = outerArc.centroid(d);
                    let midangle = d.startAngle + (d.endAngle - d.startAngle) / 2
                    pos[0] = radius * 0.95 * (midangle < Math.PI ? 1 : -1);
                    return 'translate(' + pos + ')';
                })
                .style('text-anchor', function (d) {
                    let midangle = d.startAngle + (d.endAngle - d.startAngle) / 2
                    return (midangle < Math.PI ? 'start' : 'end')
                })
                .style("fill", "white")
        }
        setDrawn(true)
    }

    useEffect(() => {
        if (props.data) {
            drawChart(props.data, props.width, props.height, props.colors, props.radius, props.tooltip)
        }
    }, [props])

    return (
        <div id={divId}></div>
    )

}

export default DonutChart