import React, { useEffect, useState } from 'react'
import { API } from 'aws-amplify'
import { Typography, TextField, Select, MenuItem, Grid, Button, Divider, FormControl, InputLabel } from "@material-ui/core"

function WorkRequest(props) {

    const [type, setType] = useState('')
    const [title, setTitle] = useState('')
    const [details, setDetails] = useState('')
    const Close = async (event) => {
        props.close(null)
    }

    const Submit = async (event) => {
        props.close(null)
        let user = localStorage.getItem('user').replace('bd-login_', '')
        await API.post('qs-web-api', '/email/jiraRequest', {
            headers: {}, body: {
                request: {
                    type: type,
                    title: title,
                    details: details,
                }, user: user
            }
        })
            .then((response) => {
                console.log(response)
            })
            .catch(function (error) {
                console.log(error)
            })
    }

    const UpdateType = async (event) => {
        setType(event.target.value)
    }

    const UpdateTitle = async (event) => {
        setTitle(event.target.value)
    }

    const UpdateDetails = async (event) => {
        setDetails(event.target.value)
    }

    useEffect(() => {

    })

    return (
        <div className="drawer">
            <Grid container justify="center" spacing={2} alignItems="stretch">
                <div className="homeTitle">
                    <Typography variant="h5">
                        Data Requests
                    </Typography>
                </div>
            </Grid>
            <Divider />
            <Grid container justify="center" spacing={2} alignItems="stretch">
                <div className="reportDesc">
                    <Typography>
                        Please enter a detailed description of your issue below.  We will automatically track these issues in JIRA
                        and prioritize the efforts.
                    </Typography>
                </div>
            </Grid>
            <Grid container justify="center" spacing={2} alignItems="stretch">
                <FormControl sx={{ m: 1, minWidth: 120 }} className="requestInput">
                    <InputLabel id="request-type-label">Request Type</InputLabel>
                    <Select
                        labelId="request-type-label"
                        id="request-type"
                        value={type}
                        label="Request Type"
                        onChange={UpdateType}
                        defaultValue="placeholder-item"
                    >
                        <MenuItem value={'Data Acquisition'}>Data Acquisition</MenuItem>
                        <MenuItem value={'Dashboards'}>Dashboard</MenuItem>
                        <MenuItem value={'Article'}>Article</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid container justify="center" spacing={2} alignItems="stretch">
            <div className="requestInput">
                    <TextField
                        id="request-title"
                        label="Request Title"
                        className="requestInput"
                        variant="outlined"
                        value={title}
                        onChange={UpdateTitle}
                    />
                </div>
            </Grid>
            <Grid container justify="center" spacing={2} alignItems="stretch">
            <div className="requestInput">
                    <TextField
                        multiline
                        rows={4}
                        id="request-details"
                        label="Request Details"
                        className="requestInput"
                        variant="outlined"
                        value={details}
                        onChange={UpdateDetails}
                    />
                </div>
            </Grid>
            <Grid container justify="center" spacing={2} alignItems="stretch">
                <div className="reportDesc">
                    <Button onClick={Submit}>Submit</Button>
                    <Button onClick={Close}>Close</Button>
                </div>
            </Grid>
        </div>
    )
}

export default WorkRequest