import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { API } from 'aws-amplify'
import color from '../shared/color'
import StoryTitle from '../components/StoryTitle'
import StoryTable from '../components/StoryTable'
import { DonutChart, ThresholdBarChart, ScatterPlot} from '../d3'
import { Typography, Divider, Grid, CircularProgress, Paper} from '@material-ui/core'
import '../story.css'

function DeviceUsage() {
    const [isLoading, setIsLoading] = useState(true)
    const [title] = useState("Device Usage Measurement")
    const [summary] = useState("The Pattern of Usages of Med ES Devices on different days of the week")
    const [categories] = useState(["implementation"])
    const [data, setData] = useState({})
    const [table1Layout, setTable1Layout] = useState(null)
    const [table2Layout, setTable2Layout] = useState(null)
    const [table3Layout, setTable3Layout] = useState(null)
    let history = useHistory()
    
    useEffect(() => {
        async function fetch() {
            //Read the data
            await API.get('qs-web-api', '/data/deviceusage/deviceusage.json', {})
                .then((storyData) => {
                    setData(storyData)
                })
                .catch(function (error) {
                    console.log(error)
                })
            setIsLoading(false) 
        }
        fetch()
    }, [])
    if (isLoading) {
        return (<div className="circular-progress"><CircularProgress disableShrink /></div>)
    } else {
        return (
            <div id="story">

                <StoryTitle 
                    title={title} 
                    date={data.date} 
                    categories={categories}>
                </StoryTitle>

                <Paper className="storySection">
                <Grid container justify="center" spacing={2} alignItems="stretch">
                    <div className="subText">
                        <Typography>
                            Usage of a device is measured based on the time spent by a user at the device. This metric is captured throughout the day with timestamp 
                            to determine the usage pattern. The level of device usage is measured from Monday 00:00:01 till Sunday 11:59:59, for a period of time and the average usage
                            at each of the week is identified to narrate the most used and least used time of a device in a week.
                            <p></p>
                            The usage is mapped over the 168 hours range. 168 hours in a week = 7 days * 24 hours
                            <p></p>
                            Device Usage Measurement can be applied to: 
                                <li>Determine the best time to upgrade a device</li>
                                <li>Understand customer workflows</li>
                                <li>Plan on-site visits </li>
                                <li>Remotely reboot and apply patch </li> 
                            <p></p>
                            This article focuses on the usage of Med ES devices across all the facilities. Device Usage Measurement workbook in Bdlytics
                            can be used for narrowing down to a specific facility or device. 
                        </Typography>
                    </div>
                </Grid>
                </Paper>

                <Paper className="storySection">
                    <Grid container justify="center" spacing={2} alignItems="stretch">
                        <div className="description">
                            <Typography variant="h6" fontWeight="bold">
                                Level of Device Usage spread across the 168 hour range
                            </Typography>
                        </div>
                    </Grid>
                    <Grid container justify="center">
                        <ScatterPlot data = {data.scatterCounts} axis={{x: "Hours Timeline - Monday to Sunday", y: ""}} 
                                        width={900} height = {450} xType={"number"} yRange = {data.yrange} tooltip = {"Number of Devices: "} 
                                        colors = {["#03DAC6"]}>
                        </ScatterPlot>
                    </Grid>
                     <Grid container justify="center" spacing={2} alignItems="stretch">
                            <div className="subText">
                                <Typography>
                                    0 hour in X-axis represents Monday 00:00 and 168 represents Sunday 11:59, similarly 104 is Friday 8:00 and so on.
                                    Based on the trend from the above graph, devices are highly used on Monday mornings and least used during midnights.
                                </Typography>
                            </div>
                        </Grid>
                </Paper>

            </div>
        )
    }
}
export default DeviceUsage