import React, { useEffect, useState } from 'react'
import { API } from 'aws-amplify'
import { Typography, TextField, Grid, Button, Divider } from "@material-ui/core"

function EmailReport(props) {

    const [input, setInput] = useState('')

    const Close = async (event) => {
        props.close(null)
    }

    const Submit = async (event) => {
        props.close(null)

        let user = localStorage.getItem('user').replace('bd-login_','')

        await API.post('qs-web-api', props.report.url, { headers: {}, body: { input: input, user: user }})
        .then((response) => {
            console.log(response)
        })
        .catch(function (error) {
            console.log(error)
        })
 
    }

    const Update = async (event) => {
        setInput(event.target.value)
    }

    useEffect(() => {

    })

    return (
        <div className="drawer">
            <Grid container justify="center" spacing={2} alignItems="stretch" >
                <div className="homeTitle">
                    <Typography variant="h5">
                        {props.report.title}
                    </Typography>
                </div>
            </Grid>
            <Divider />
            <Grid container justify="center" spacing={2} alignItems="stretch" >
                <div className="reportDesc">
                    <Typography>
                        {props.report.desc}
                    </Typography>
                </div>
            </Grid>
            <Grid container justify="center" spacing={2} alignItems="stretch" >
                <div>
                    <TextField id="standard-basic" label={props.report.input} variant="outlined" value={input} onChange={Update}/>
                </div>
            </Grid>
            <Grid container justify="center" spacing={2} alignItems="stretch" >
                <div className="reportDesc">
                    <Button onClick={Submit}>Send</Button>
                    <Button onClick={Close}>Close</Button>
                </div>
            </Grid>
        </div>
    )
}

export default EmailReport