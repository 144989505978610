
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { API } from 'aws-amplify'
import StoryTitle from '../components/StoryTitle'
import StoryTable from '../components/StoryTable'
import color from '../shared/color'
import {DonutChart, BubbleChart, ChoroplethUS, HeatMap} from '../d3'
import { Typography, Grid, CircularProgress, Paper } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import '../story.css'
import * as d3 from "d3";

function Mpar() {


    const [title] = useState("Machine Performance Assessment Report")
    const [summary] =  useState("An assessment of Machine Peformance and failures across our customer base.")
    const [categories] = useState(["performance"])

    const [isLoading, setIsLoading] = useState(true)
    const [data, setData] = useState(null)
    const [choropleth, setChoroplath] = useState(null)
    const [counties, setCounties] = useState(null)
    const [facilityLayout] = useState([
        { column: "Facility", field: "facilityname" },
        { column: "Customeer Name", field: "customername" },
        { column: "Total Failure Counts", field: "TotalFailureCounts" },
        { column: "Access Counts", field: "AccessCounts" },
        { column: "Device Not On Bus", field: "DeviceNotOnBus" },
        { column: "Failed To Lock", field: "FailedToLock" },
        { column: "Failed To Open", field: "FailedToOpen" },
        { column: "Failed To Release", field: "FailedToRelease" },
        { column: "Failed To Stay Closed", field: "FailedToStayClosed" },
        { column: "Failed To Unlock", field: "FailedToUnlock" },
        { column: "Mini Specific Error", field: "MiniSpecificError" }
    ])

    const [bubbleColor, setBubColor] = useState(null)
    const [DonutColor, setDonutColor] = useState(null)
    const useStyles = makeStyles({
        table: {
            minWidth: 650,
        },
    })

    let history = useHistory()

    useEffect(() => {
        setBubColor(new Map([
            ["Scheduled_with_permission", "#4e79a7"],
            ["Customer_Managed", "#f28e2c"],
            ["Scheduled_wo_permission", "#59a14f"]
        ]))
        setDonutColor(new Map([
            ["FailedToRelease", "#4e79a7"],
            ["FailedToStayClosed", "#f28e2c"],
            ["FailedToLock", "#edc949"],
            ["FailedToLock", "#af7aa1"],
            ["DeviceNotOnBus", "#ff9da7"],
            ["FailedToOpen", "#9c755f"],
            ["MiniSpecificError", "#bab0ab"]
        ]))
        async function fetch() {
            //Read the data

            await API.get('qs-web-api', '/data/home/counties-albers-10m.json', {})
                .then((storyData) => {
                    setCounties(storyData)
                })



        await API.get('qs-web-api', '/data/mpar/totaldevices.json', {})
                .then((storyData) => {

                    setData(storyData)


                })
                .catch(function (error) {
                    console.log(error)
                })

            setIsLoading(false)


            await API.get('qs-web-api', '/data/mpar/mparsuggestedpocket.json', {})
                .then((storyData) => {

                    setChoroplath(new Map(storyData.fips.data))


                })
                .catch(function (error) {
                    console.log(error)
                })

            setIsLoading(false)
        }

        fetch()
    }, [])


    if (isLoading) {
        return (<div className="circular-progress"><CircularProgress disableShrink /></div>)
    } else {
        return (
            <div id="story">
                <StoryTitle title={title} date={data.date} categories={categories}></StoryTitle>
                <Paper className="storySection">
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <div className="description">
                        <Typography>
                            Machine Performance Assessment Report (MPAR) was the first workbook created in AWS for field support.  
                            The overall project is an evolution of the MS4000 MPAR reporting.  
                            The GCS FST led project has given great insights into over 12 million pieces of hardware in the field to extract specific machine errors.
                            MPAR updates daily to the MPAR/MPAR Mobile workbook for each piece of hardware captured in file collection.  MPAR's goal is 90 days of runtime monitored.
                            <p>Using the MPAR workbook takes a top down look at issues in the field with data from the facility level down to the pocket. </p>
                            <p>Ensuring that every error is captured gives the telemetry for our field teams to make the best decisions to save time and effort for our customers. </p>

                        </Typography>
                        </div>
                    </Grid>
                </Paper>
                <Paper className="storySection">
                    <Grid container justify="center" spacing={2} >
                        <div className="storyTitle">
                            <Typography variant="h5" fontWeight="bold">
                                MPAR Number of Failures Per 100,000 Accesses by County
                            </Typography>
                        </div>
                    </Grid>
                    <Grid container justify="center" spacing={2} alignItems="stretch">
                        <ChoroplethUS data={choropleth} counties={counties} red={true} threshold={[1,70]} />
                    </Grid>
                    <Grid container justify="center" spacing={2} >
                        <div className="description">
                            <Typography>
                                Using a geospatial heat map of the US can help trends in areas with errors. While the heat map will not identify smaller sites, the overall trend can show regions with issues.
                            </Typography>
                        </div>
                    </Grid>
                </Paper>


                <Paper className="storySection">
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <div className="description">
                            <Typography variant="h6">
                                Top Errors % By MPAR
                            </Typography>
                        </div>
                    </Grid>
                    <StoryTable data={data.tableTopErrors.failurePercent} layout={facilityLayout}></StoryTable>

                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <div className="subText">
                            <Typography>
                                Normalized failure counts by the access counts  over access counts plus failures. The goal is to show the sites with the most amount of failure experience per usage. Please use the MPAR workbook for a deeper dive for stations and pockets.
                            </Typography>
                        </div>
                    </Grid>

                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <div className="description">
                            <Typography variant="h6">
                                Top Total Errors by MPAR
                            </Typography>
                        </div>
                    </Grid>
                    <StoryTable data={data.tableTopErrors.TotalFailureCounts} layout={facilityLayout}></StoryTable>

                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <div className="subText">
                            <Typography>
                                Errors Monitored daily and aggregated at a facility level. See the MPAR workbook to dig into a station level.
                            </Typography>
                        </div>
                    </Grid>



                </Paper>

                <Paper className="storySection">
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <div className="storyTitle">
                            <Typography variant="h5" fontWeight="bold">
                                Distribution of Errors
                        </Typography>
                        </div>
                    </Grid>
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <DonutChart data={data.donutTotalErrors.data} colors={DonutColor} tooltip={"The value of<br>this cell is: "} width={750} height={400} radius={200}></DonutChart>
                    </Grid>
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <div className="storyTitle">
                            <Typography>
                                Overall errors across MPAR against MMS customer base for Med ES and PAS.
                                <p> Each donut chart below focuses on a specific storage space type and the overall trend of errors in MPAR. </p>
                        </Typography>
                        </div>
                    </Grid>
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <div className="storyTitle">
                            <Typography variant="h5" fontWeight="bold">
                                Distribution of Errors for PAS Matrix Drawer
                        </Typography>
                        </div>
                    </Grid>
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <DonutChart data={data.donutTotalErrors.ACCMTRXDRWR1} colors={DonutColor} tooltip={"The value of<br>this cell is: "} width={750} height={400} radius={200}></DonutChart>
                    </Grid>
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <div className="storyTitle">
                            <Typography variant="h5" fontWeight="bold">
                                Distribution of Errors for Legacy Cubie Drawer
                        </Typography>
                        </div>
                    </Grid>
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <DonutChart data={data.donutTotalErrors.CUBIEDRWR1} colors={DonutColor} tooltip={"The value of<br>this cell is: "} width={750} height={400} radius={200}></DonutChart>
                    </Grid>
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <div className="storyTitle">
                            <Typography variant="h5" fontWeight="bold">
                                Distribution of Errors for Smart Cubie Drawer
                        </Typography>
                        </div>
                    </Grid>
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <DonutChart data={data.donutTotalErrors.CUBIEDRWR2} colors={DonutColor} tooltip={"The value of<br>this cell is: "} width={750} height={400} radius={200}></DonutChart>
                    </Grid>
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <div className="storyTitle">
                            <Typography variant="h5" fontWeight="bold">
                                Distribution of Errors for Legacy Cubie Pocket
                        </Typography>
                        </div>
                    </Grid>
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <DonutChart data={data.donutTotalErrors.CUBIEPKT1} colors={DonutColor} tooltip={"The value of<br>this cell is: "} width={750} height={400} radius={200}></DonutChart>
                    </Grid>
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <div className="storyTitle">
                            <Typography variant="h5" fontWeight="bold">
                                Distribution of Errors for Smart Cubie Pocket
                        </Typography>
                        </div>
                    </Grid>
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <DonutChart data={data.donutTotalErrors.CUBIEPKT2} colors={DonutColor} tooltip={"The value of<br>this cell is: "} width={750} height={400} radius={200}></DonutChart>
                    </Grid>
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <div className="storyTitle">
                            <Typography variant="h5" fontWeight="bold">
                                Distribution of Errors for Med Tall Door
                        </Typography>
                        </div>
                    </Grid>
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <DonutChart data={data.donutTotalErrors.MEDTALLDOOR1} colors={DonutColor} tooltip={"The value of<br>this cell is: "} width={750} height={400} radius={200}></DonutChart>
                    </Grid>
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <div className="storyTitle">
                            <Typography variant="h5" fontWeight="bold">
                                Distribution of Errors for Mini Drawer
                        </Typography>
                        </div>
                    </Grid>
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <DonutChart data={data.donutTotalErrors.MINIDRWR1} colors={DonutColor} tooltip={"The value of<br>this cell is: "} width={750} height={400} radius={200}></DonutChart>
                    </Grid>
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <div className="storyTitle">
                            <Typography variant="h5" fontWeight="bold">
                                Distribution of Errors for Matrix Drawer
                        </Typography>
                        </div>
                    </Grid>
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <DonutChart data={data.donutTotalErrors.MTRXDRWR1} colors={DonutColor} tooltip={"The value of<br>this cell is: "} width={750} height={400} radius={200}></DonutChart>
                    </Grid>
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <div className="storyTitle">
                            <Typography variant="h5" fontWeight="bold">
                                Distribution of Errors for Remote Drawer
                        </Typography>
                        </div>
                    </Grid>
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <DonutChart data={data.donutTotalErrors.REMOTEMGR1} colors={DonutColor} tooltip={"The value of<br>this cell is: "} width={750} height={400} radius={200}></DonutChart>
                    </Grid>
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <div className="storyTitle">
                            <Typography variant="h5" fontWeight="bold">
                                Distribution of Errors for Smart Remote Drawer
                        </Typography>
                        </div>
                    </Grid>
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <DonutChart data={data.donutTotalErrors.SMRTREMOTEMGR1} colors={DonutColor} tooltip={"The value of<br>this cell is: "} width={750} height={400} radius={200}></DonutChart>
                    </Grid>


                    </Paper>





            </div >
        )
    }
}

export default Mpar