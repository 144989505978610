import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import * as d3 from "d3"
import { rgb } from 'd3'

function Sunburst(props) {
    const [divId, setDivId] = useState("Sunburst" + Math.floor(Math.random() * Math.floor(100000)))
    const [drawn, setDrawn] = useState(false)
    let history = useHistory()
    
    const drawChart = (data, colors, w, tooltip) => {
        if (!drawn) {

            if (typeof w == "undefined") {
                w = 800
            }
            if (typeof tooltip == "undefined") {
                tooltip = "Value of: "
            }
            if (typeof colors == "undefined") {
                colors = [
                  "gold", 
                  "#157DD8",
                  "#F28E2C", 
                  "#03DAC6", 
                  "#7033FF", 
                  "lightgrey", 
                  "#BB86FC", 
                  "#018786", 
                  "#A1580E"
                ]
            }
            
            for (let i = 0; i < colors.length; i++) {
              let s = new Option().style
              s.color = colors[i].toString()
              if (s.color == '') {
                  colors[i] = "blue"
              }
            }
            
            // make sure there are enough colors, if not, interpolate a color blend between last and first color
            if (colors.length < data.children.length) {
                let newColors = d3.quantize(d3.interpolateHcl(colors[colors.length-1], colors[0]), data.children.length - colors.length+2)
                newColors.shift()
                newColors.pop()
                colors = colors.concat(newColors)
            } 
            
            let color = d3.scaleOrdinal()
                .domain([1, data.children.length])
                .range(colors)

            // set the dimensions and margins of the graph
            let margin = {top: 10, right: 30, bottom: 30, left: 60}
            w = w - margin.left - margin.right
            
            let width = 932
            let radius = width / 6
            
            let format = d3.format(",d")
            
            let Tooltip = d3.select(`#${divId}`)
                .append("div")
                .style("opacity", 0)
                .style('display','none')
                .attr("class", "tooltip")
                .style("background-color", "#323232")
                .style("border", "solid")
                .style("border-width", "2px")
                .style("border-radius", "5px")
                .style("padding", "5px")

            let mouseover = function (d) {
                if(d.target.getAttribute("fill-opacity") > 0) {
                    Tooltip.style("opacity", 1)
                    d3.select(this)
                        .style("stroke", "white")
                        .style("opacity", 1)
                }
            }

            let mousemove = function (d) {
                if(d.target.getAttribute("fill-opacity") > 0) {
                    let lbl = `<b>${d.srcElement.__data__.data.name}</b><br/>${tooltip}${d.srcElement.__data__.value}`
                    Tooltip
                        .html(lbl)
                        .style('display','block')
                        .style("position", "absolute")
                        .style("left", `${d.pageX}px`)
                        .style("top", `${d.pageY + 25}px`)
                }
            }

            let mouseleave = function (d) {
                Tooltip
                    .style("opacity", 0)
                d3.select(this)
                    .style("stroke", "none")
                    .style("opacity", 0.8)
            }

            let partition = parts => {
                const root = d3.hierarchy(data)
                    .sum(d => d.value)
                    .sort((a, b) => b.value - a.value);
                return d3.partition()
                    .size([2 * Math.PI, root.height + 1])
                (root)
            }
            
            //const y = d3.scaleSqrt()
            //    .range([radius*0.1, radius]);
            
            let y = function(x) {
                if (x == 1) {x = 1}
                return(x)
            }

            let arc = d3.arc()
                .startAngle(d => d.x0)
                .endAngle(d => d.x1)
                .padAngle(d => Math.min((d.x1 - d.x0) / 2, 0.005))
                .padRadius(radius * 1.5) // padding between wedges
                .innerRadius(d => y(d.y0) * radius)
                .outerRadius(d => Math.max(d.y0 * radius, d.y1 * radius - 1))

            const root = partition(data)
        
            root.each(d => d.current = d)
        
            const svg = d3.select(`#${divId}`)
                .append('svg')
                .attr("width", w + margin.left + margin.right)
                .attr("height", w + margin.top + margin.bottom)
                .attr("viewBox", [0, 0, width, width])
                .style("font", "13px sans-serif")
        
            const g = svg.append("g")
                .attr("transform", `translate(${width / 2},${width / 2})`)
        
            const path = g.append("g")
            .selectAll("path")
            .data(root.descendants().slice(1))
            .join("path")
                .attr("fill", d => { while (d.depth > 1) d = d.parent; return color(d.data.name); })
                .attr("fill-opacity", d => arcVisible(d.current) ? (d.children ? 0.6 : 0.4) : 0)
                .attr("d", d => arc(d.current))

            path.filter(d => d.children)
                .style("cursor", "pointer")
                .on("click", clicked)
       
            path
            .on("mouseover", mouseover)
            .on("mousemove", mousemove)
            .on("mouseleave", mouseleave)

            const label = g.append("g")
                .attr("pointer-events", "none")
                .attr("text-anchor", "middle")
                .style("user-select", "none")
                .style("fill", "white")
            .selectAll("text")
            .data(root.descendants().slice(1))
            .join("text")
                .attr("dy", "0.35em")
                .attr("fill-opacity", d => +labelVisible(d.current))
                .attr("transform", d => labelTransform(d.current))
                .text(d => d.data.name.replace("Group:", "").replace("...", " et al."))
                .attr("font-weight", d => d.data.name.includes("Group:") ? "bold" : "plain")
            
            const parent = g.append("circle")
                .datum(root)
                .attr("r", radius)
                .attr("fill", "none")
                .attr("pointer-events", "all")
                .on("click", clicked)
        
            function clicked(event, p) {
                parent.datum(p.parent || root)
            
                root.each(d => d.target = {
                    x0: Math.max(0, Math.min(1, (d.x0 - p.x0) / (p.x1 - p.x0))) * 2 * Math.PI,
                    x1: Math.max(0, Math.min(1, (d.x1 - p.x0) / (p.x1 - p.x0))) * 2 * Math.PI,
                    y0: Math.max(0, d.y0 - p.depth),
                    y1: Math.max(0, d.y1 - p.depth)
            })
        
            const t = g.transition().duration(750)
        
            // Transition the data on all arcs, even the ones that aren’t visible,
            // so that if this transition is interrupted, entering arcs will start
            // the next transition from the desired position.
            path.transition(t)
                .tween("data", d => {
                    const i = d3.interpolate(d.current, d.target)
                    return t => d.current = i(t)
                })
                .filter(function(d) {
                return +this.getAttribute("fill-opacity") || arcVisible(d.target)
                })
                .attr("fill-opacity", d => arcVisible(d.target) ? (d.children ? 0.6 : 0.4) : 0)
                .attrTween("d", d => () => arc(d.current))
        
            label.filter(function(d) {
                return +this.getAttribute("fill-opacity") || labelVisible(d.target)
                }).transition(t)
                .attr("fill-opacity", d => +labelVisible(d.target))
                .attrTween("transform", d => () => labelTransform(d.current))
            }
            
            function arcVisible(d) {
                return d.y1 <= 3 && d.y0 >= 1 && d.x1 > d.x0
            }
        
            function labelVisible(d) {
                // <= 2 will only label inner arc, <=3 will label both arcs
                return d.y1 <= 2 && d.y0 >= 1 && (d.y1 - d.y0) * (d.x1 - d.x0) > 0.03
            }
        
            function labelTransform(d) {
                const x = (d.x0 + d.x1) / 2 * 180 / Math.PI
                const y = (d.y0 + d.y1) / 2 * radius * 0.9
                return `rotate(${x - 90}) translate(${y},0) rotate(${x < 180 ? 0 : 180})`
            }

            svg.node()
            setDrawn(true)
        }
    }
        
    useEffect(() => {
        if (props.data) {
            drawChart(props.data, props.colors, props.w, props.tooltip)
        }
    }, [props])

    return (
    <div id={divId}></div>
    )
}

export default Sunburst