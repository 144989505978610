import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { API } from 'aws-amplify'
import color from '../shared/color'
import StoryTitle from '../components/StoryTitle'
import StoryTable from '../components/StoryTable'
import { Parallel, ScatterPlot, StackBarChart, Sunburst } from '../d3'
import {
  Typography,
  Divider,
  Grid,
  CircularProgress,
  colors,
} from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import '../story.css'

function AnomalyApps() {
  const [title] = useState('Anomaly Apps')
  const [summary] = useState(
    'Analysis of the internal and 3rd party software distributed across the fleet of ES stations and servers.',
  )
  const [categories] = useState([
    'security',
    'implementation',
    'microsoft',
    'risk',
  ])
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState(null)
  const [deviceLayout] = useState([
    { column: 'Device ID', field: 'deviceId' },
    { column: 'Facility', field: 'facilityname' },
    { column: 'Product Version', field: 'appVersion' },
    { column: 'OS Version', field: 'osname' },
    { column: 'Anomaly Apps (Total)', field: 'anomCount' },
  ])
  let history = useHistory()


  useEffect(() => {
    async function fetch() {
      await API.get('qs-web-api', '/data/lpvt/appsAnom.json', {})
        .then((storyData) => {
          setData(storyData)
        })
        .catch(function (error) {
          console.log(error)
        })

      setIsLoading(false)
    }


    fetch()
  }, [])

  if (isLoading) {
    return (
      <div className="circular-progress">
        <CircularProgress disableShrink />
      </div>
    )
  } else {
    console.log('AA')
    console.log(deviceLayout)
    return (
      <div id="story">
        <StoryTitle
          title={title}
          date={
            new Date(
              data.date.toLocaleString('en-US', {
                timeZone: 'America/Los_Angeles',
              }),
            )
          }
          categories={categories}
        ></StoryTitle>
        <Paper className="storySection">
          <Grid container justify="center" spacing={2} alignItems="stretch">
            <div className="description">
              <Typography>
                <p>
                  Anomaly apps are defined as apps installed on a minority of
                  devices of the same product version and OS version. It would
                  be expected that all devices of a specific product version and
                  OS version have the same set of apps installed.
                </p>
                <p>
                  This tool can be used to find apps unique to particular
                  devices, versions, or facilities that may cause performance
                  issues. These could be outdated or wrong versions of vital
                  apps/hot fixes, or apps that the user installed. Such user
                  installed apps could be incompatible with the environment or
                  be too memory/processor intensive; for example, virus scanners
                  that run constantly in the background and that may erroneously
                  quarantine vital files.
                </p>
                <p>
                  Having installed apps in the field conform by product and OS
                  version is a vital step in ensuring quality control and
                  performance integrity.
                </p>
              </Typography>
            </div>
          </Grid>
        </Paper>

        <Paper className="storySection">
          <Grid container justify="center" spacing={2} alignItems="stretch">
            <div className="description">
              <Typography variant="h6" fontWeight="bold">
                Anomaly Apps by Name
              </Typography>
            </div>
          </Grid>
          <Grid container justify="center" spacing={2} alignItems="stretch">
            <div className="subText">
              <Typography>
                The plot below displays any such anomaly app that's installed on
                any device from any version. The size of the chart wedge
                corresponds to how many times that app has been installed. For
                clarity, the apps are divided into different groups based on
                similarity (groups will be bold face on the chart and start with
                'Group' in the tooltip), and these groups can be further
                explored by clicking on their wedge. Click on the center to zoom
                back out. The Group names and apps in each group are
                automatically assigned based on the data. Take advantage of this
                interactive chart to find outdated or unwanted apps.
              </Typography>
            </div>
          </Grid>
          <Grid container justify="center" spacing={2} alignItems="stretch">
            <div className="subText">
              <Typography>
                Click on a group to explore, or cick in the center to zoom out.
              </Typography>
            </div>
          </Grid>
          <Grid container justify="center">
            <Sunburst
              data={data.sunburstAppsAnom[0]}
              colors={color.defaultScheme}
              w={800}
            ></Sunburst>
          </Grid>
          <Grid container justify="center" spacing={2} alignItems="stretch">
            <div className="subText">
              <Typography>
                <p>
                  There are {data.metaData[0].noUniqueApps} unique anomaly apps
                  installed across the devices, adding up to{' '}
                  {data.metaData[0].totalIntstall} total installations. These
                  apps include several user apps, such as{' '}
                  {data.metaData[0].intAppsPresent}
                </p>
                <p>
                  {data.metaData[0].difVersionApps} {data.metaData[0].hotFix}{' '}
                  {data.metaData[0].sameApp}
                </p>
              </Typography>
            </div>
          </Grid>
        </Paper>

        <Paper className="storySection">
          <Grid container justify="center" spacing={2} alignItems="stretch">
            <div className="description">
              <Typography variant="h6" fontWeight="bold">
                Anomaly App Count by Facility and Device Version
              </Typography>
            </div>
          </Grid>
          <Grid container justify="center" spacing={2} alignItems="stretch">
            <div className="subText">
              <Typography>
                <p>
                  The facilities with the greatest average number of anomaly
                  apps per device, the greatest total number of anomaly apps
                  across all devices, or the greatest number of anomally apps
                  for a specific product-OS version group are shown.
                </p>
                <p>
                  This graph can highlight faciities that stand out for abnormal
                  application use, and also highlight product versions that are
                  not conforming in terms of applications installed. This may
                  also highlight facilities that feel the need to install more
                  updates, patches, and tools like disc space or network
                  monitoring applications.
                </p>
                <p>
                  The chart below shows total number of anomaly apps across all
                  devices at each facility by product and OS version.
                </p>
              </Typography>
            </div>
          </Grid>
          <Grid container justify="center" spacing={2} alignItems="stretch">
            <div className="description">
              <Typography>Hover over an item to display the info.</Typography>
            </div>
          </Grid>
          <Grid container justify="center">
            <StackBarChart
              data={data.stackedbarFacAnom}
              axis={{ y: 'Total' }}
              colors={color.defaultScheme}
              tooltip={'Product-OS Version'}
              height={350}
            ></StackBarChart>
          </Grid>
          <Grid container justify="center" spacing={2} alignItems="stretch">
            <div className="subText">
              <Typography>
                <p>
                  The facility with the most anomaly apps per device was{' '}
                  {data.metaData[0].highestAnFacNam}. The average number of
                  anomaly apps installed on the devices at this facility was{' '}
                  {data.metaData[0].highestAnFacNum} across &nbsp;
                  {data.metaData[0].highestAnFacDNum}
                  &nbsp;device{data.metaData[0].highestAnFacDNumT}. There were{' '}
                  {data.metaData[0].highestAnFacAUN} unique anomaly apps at this
                  facility, for example: {data.metaData[0].highestAnFacAS}.
                </p>
                <p>
                  Looking at only larger facilities, in the top half of total
                  number of devices, the facility with the most anomaly apps per
                  device was {data.metaData[0].highestAnFacNam2}. The average
                  number of anomaly apps installed on the devices at this
                  facility was {data.metaData[0].highestAnFacNum2} across &nbsp;
                  {data.metaData[0].highestAnFacDNum2}
                  &nbsp;device{data.metaData[0].highestAnFacDNumT2}. There were{' '}
                  {data.metaData[0].highestAnFacAUN2} unique anomaly apps at
                  this facility, for example: {data.metaData[0].highestAnFacAS2}
                  .
                </p>
              </Typography>
            </div>
          </Grid>
        </Paper>

        <Paper className="storySection">
          <Grid container justify="center" spacing={2} alignItems="stretch">
            <div className="description">
              <Typography variant="h6" fontWeight="bold">
                Connecting Facility, Version, and Apps
              </Typography>
            </div>
          </Grid>
          <Grid container justify="center" spacing={2} alignItems="stretch">
            <div className="subText">
              <Typography>
                <p>
                  To better visualze the distribution of anomaly apps across the
                  population, select facilities from the chart above along with
                  the most common anomaly apps are plotted below. The ribbon
                  from each facility is split into all the product versions at
                  that facility for these apps, which are then split into the OS
                  versions for these apps, which are then connected to the apps.
                  Again, some apps may not be anomalies per se, but because of
                  version numbering or naming inconsistency were only on a
                  minority of devices for that version.
                </p>
                <p>
                  The plot below shows the most common anomaly apps at some of
                  the facilities with the most anomaly apps. The total value for
                  each group is displayed.
                </p>
              </Typography>
            </div>
          </Grid>
          <Grid container justify="center" spacing={2} alignItems="stretch">
            <div className="subText">
              <Typography>
                Hover over a ribbon to see more details. Click on a facility to
                highlight the information from that facility, or click on a
                grouping box that the ribbons pass through to highlight that
                relative information. Click on the background of the graph to
                deselect.
              </Typography>
            </div>
          </Grid>
          <Grid container justify="center">
            <Parallel
              data={data.parallelFacVerAnom}
              colors={color.defaultScheme}
              width={800}
              height={600}
            ></Parallel>
          </Grid>
          <Grid container justify="center" spacing={2} alignItems="stretch">
            <div className="subText">
              <Typography>
                <p>
                  This plot will help visualize what causes anomalies at
                  specific facilities or what is common across facilities. For
                  clarity, the app names are abrreviated without version info.
                  The displayed total value is the sum across those versions.
                </p>
              </Typography>
            </div>
          </Grid>
        </Paper>

        <Paper className="storySection">
          <Grid container justify="center" spacing={2} alignItems="stretch">
            <div className="description">
              <Typography variant="h6" fontWeight="bold">
                Examples of App Installation Rate
              </Typography>
            </div>
          </Grid>
          <Grid container justify="center" spacing={2} alignItems="stretch">
            <div className="subText">
              <Typography>
                <p>
                  Ideally, app installation should converge across all devices
                  in a specific version population. One would expect the
                  majority of apps to be installed on nearly 100% of the devices
                  of the same version, hence the lines in the plot below should
                  quickly jump up to ~100% and level off there.
                </p>
                <p>
                  The plot below shows the percentage of devices each app
                  (indexed on the bottom axis) is installed on. The apps on the
                  bottom axis are the apps present in that specific version
                  population, ordered from least common to most common. Each
                  line represents a different product version-OS version, with a
                  select sampling of versions shown.
                </p>
              </Typography>
            </div>
          </Grid>
          <Grid container justify="center" spacing={2} alignItems="stretch">
            <div className="subText">
              <Typography>
                Hover over the chart to see more information.
              </Typography>
            </div>
          </Grid>
          <Grid container justify="center">
            <ScatterPlot
              data={data.scatterAppPerDevice}
              axis={{ x: 'Arbitrary App #', y: 'Percent Installed On' }}
              colors={color.defaultScheme}
              addDots={false}
              addLine={true}
              width={920}
              height={500}
            ></ScatterPlot>
          </Grid>
          <Grid container justify="center" spacing={2} alignItems="stretch">
            <div className="subText">
              <Typography>
                <p>{data.metaData[0].xyInfo}</p>
              </Typography>
            </div>
          </Grid>
        </Paper>

        <Paper className="storySection">
          <Grid container justify="center" spacing={2} alignItems="stretch">
            <div className="description">
              <Typography variant="h6">
                Top 100 Devices with Anomaly Apps
              </Typography>
            </div>
          </Grid>
          <StoryTable
            data={data.tableDeviceInfo}
            layout={deviceLayout}
          ></StoryTable>
        </Paper>

        <Paper className="storySection">
          <Grid container justify="center" spacing={2} alignItems="stretch">
            <div className="description">
              <Typography variant="h6" fontWeight="bold">
                Summary
              </Typography>
            </div>
          </Grid>
          <Grid container justify="center" spacing={2} alignItems="stretch">
            <div className="subText">
              <Typography>
                <p>
                  Applications with unusually low install rates for a specifc
                  product version-OS version were highlighted. Facilities with a
                  higher rate of anomaly apps were shown, and specific device
                  versions with higher anomaly app rates were visualized. The
                  interaction between facility, product version, OS version, and
                  anomaly apps was investigated. The specific devices with
                  higher anomaly apps are available in the table. This tool
                  highlights areas to follow up on to resolve possible issues
                  related to the apps present.
                </p>
              </Typography>
            </div>
          </Grid>
        </Paper>
      </div>
    )
  }
}

export default AnomalyApps
