import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import * as d3 from 'd3'

function StackBarChart(props) {
  const [divId, setDivId] = useState(
    'StackBarChart' + Math.floor(Math.random() * Math.floor(100000)),
  )
  const [drawn, setDrawn] = useState(false)
  let history = useHistory()

  const drawChart = async (data, colors, xType, tooltip, axis, width, height, legendOffsetX, legendOffsetY) => {
    if (!drawn) {
      if (typeof tooltip == 'undefined') {
        tooltip = 'Stack Group'
      }
      if (typeof xType == 'undefined') {
        xType = 'unknown'
      }
      if (typeof axis == 'undefined') {
        axis = { x: 'X Values', y: 'Y Values' }
      }
      if (!('x' in axis)) {
        axis.x = 'X Value'
      }
      if (!('y' in axis)) {
        axis.y = 'Y Value'
      }
      if (typeof width == 'undefined') {
        width = 900
      }
      if (typeof height == 'undefined') {
        height = 600
      }
      if (typeof colors == 'undefined') {
        colors = [
          'gold',
          '#157DD8',
          '#F28E2C',
          '#03DAC6',
          '#7033FF',
          'lightgrey',
          '#BB86FC',
          '#018786',
          '#A1580E',
        ]
      }

      if (typeof tooltip == "undefined") {
        tooltip = "Stack Group"
      }
      if (typeof xType == "undefined") {
          xType = "unknown"
      }
      if (typeof axis == "undefined") {
          axis = {x: "X Values", y: "Y Values"}
      }
      if (!("x" in axis)) {
          axis.x = "X Value"
      }
      if (!("y" in axis)) {
          axis.y = "Y Value"
      }
      if (typeof width == "undefined") {
          width = 900
      }
      if (typeof height == "undefined") {
          height = 600
      }
      if (typeof legendOffsetX == "undefined") {
        legendOffsetX = 0
      }
      if (typeof legendOffsetY == "undefined") {
        legendOffsetY = 0
      }
      if (typeof colors == "undefined") {
          colors = [
            "gold", 
            "#157DD8",
            "#F28E2C", 
            "#03DAC6", 
            "#7033FF", 
            "lightgrey", 
            "#BB86FC", 
            "#018786", 
            "#A1580E"
          ]
      }
      
      for (let i = 0; i < colors.length; i++) {
        let s = new Option().style
        s.color = colors[i].toString()
        if (s.color == '') {
          colors[i] = 'blue'
        }
      }

      // get all distinct group names to add to legend
      let domain = []
      data.forEach((member) => {
        if (!domain.includes(member.group)) {
          domain.push(member.group)
        }
      })

      let margin = { top: 20, right: 30, bottom: 20, left: 50 }

      // x-axis can be textual factors or numbers,
      // and "number" or "text" string should be passed
      // as function argument to choose, if not passed,
      // or invalid selection, then decide based on
      // first index in array
      // Also increases bottom margin if text labels

      if ((xType != 'number') & (xType != 'text')) {
        if (!isNaN(data[0].x)) {
          xType = 'number'
        } else {
          margin.bottom = 60
          xType = 'text'
        }
      }

      let color
      if (Array.isArray(colors)) {
        color = d3.scaleOrdinal().domain(domain).range(colors)
      } else {
        color = (key) => {
          return colors.get(key)
        }
      }

      if (xType == 'text') {
        // adjust bottom margin for longer text labels
        margin.bottom = margin.bottom * 1.65
      }

      let series = d3
        .stack()
        .keys(domain)
        .value((group, key) => group.get(key).y)
        .order(d3.stackOrderReverse)(
          d3
            .rollup(
              data,
              ([d]) => d,
              (d) => d.x,
              (d) => d.group,
            )
            .values(),
        )
        .map((s) => (s.forEach((d) => (d.data = d.data.get(s.key))), s))

      let x = d3
        .scaleBand()
        .domain(data.map((d) => d.x))
        .rangeRound([margin.left, width - margin.right])

      let y = d3
        .scaleLinear()
        .domain([0, d3.max(series, (d) => d3.max(d, (d) => d[1]))])
        .nice()
        .range([height - margin.bottom, margin.top])

      // x-axis can be textual factors or numbers,
      // if numbers, can display at set interval,
      // if text display all and rotate 90 degrees to fit
      var xAxis
      if (xType == 'number') {
        xAxis = (g) =>
          g.attr('transform', `translate(0,${height - margin.bottom})`).call(
            d3
              .axisBottom(x)
              .tickValues(
                d3.ticks(...d3.extent(x.domain()), (width - margin.left) / 80),
              )
              .tickSizeOuter(0),
          )
      } else {
        xAxis = (g) =>
          g
            .attr('transform', `translate(0,${height - margin.bottom})`)
            .call(d3.axisBottom(x).ticks(width / 100, '%'))
            .call((g) => g.selectAll('.domain').remove())
      }

      // format number on y-axis, so 1000000 becomes 1M
      let yAxis = (g) =>
        g
          .attr('transform', `translate(${margin.left},0)`)
          .call(d3.axisLeft(y).ticks(null, 's'))
          .call((g) => g.selectAll('.domain').remove())

      // create a tooltip
      let Tooltip = d3
        .select(`#${divId}`)
        .append('div')
        .style('opacity', 0)
        .style('display', 'none')
        .attr('class', 'tooltip')
        .style('background-color', '#323232')
        .style('border', 'solid')
        .style('border-width', '2px')
        .style('border-radius', '5px')
        .style('padding', '5px')

      let mouseover = function (d, i) {
        Tooltip.style('opacity', 1)
        d3.select(this).style('stroke', 'white').style('opacity', 1)
      }

      let mousemove = function (d, i) {
        let info = d.srcElement.__data__
        Tooltip.html(
          '<b>' +
            tooltip +
            ':</b> ' +
            info.data.group +
            '<br/> <b>X</b>: ' +
            info.data.x +
            '<br/> <b>Value</b>: ' +
            info.data.y,
        )
          .style('display', 'block')
          .style('position', 'absolute')
          .style('left', `${d.pageX}px`)
          .style('top', `${d.pageY + 25}px`)
      }

      let mouseleave = function (d, i) {
        Tooltip.style('opacity', 0)
        d3.select(this).style('stroke', 'none').style('opacity', 0.8)
      }

      let svg = d3
        .select(`#${divId}`)
        .append('svg')
        .attr('width', width + margin.left + margin.right)
        .attr('height', height + margin.top + margin.bottom)
        .attr('viewBox', [0, 0, width * 1.25, height]) // * 1.25 to leave room for legend

      svg
        .append('g')
        .selectAll('g')
        .data(series)
        .join('g')
        .attr('fill', ({ key }) => {
          return color(key)
        })
        .call((g) =>
          g
            .selectAll('rect')
            .data((d) => d)
            .join('rect')
            .attr('x', (d) => x(d.data.x))
            .attr('y', (d) => y(d[1]))
            .attr('width', x.bandwidth() - 0) // - 0, leaving no space between bars looks better, can increase this number to increase space
            .attr('height', (d) => Math.max(0, y(d[0]) - y(d[1]))),
        )
        .on('mouseover', mouseover)
        .on('mousemove', mousemove)
        .on('mouseleave', mouseleave)

      // Y axis label
      svg
        .append('text')
        .attr('text-anchor', 'end')
        .attr('transform', 'rotate(-90)')
        .attr('y', 12)
        .attr('x', -height / 2 + margin.bottom / 2)
        .text(axis.y)
        .style('fill', 'white')

      if (xType == 'text') {
        svg
          .append('g')
          .call(xAxis)
          .selectAll('text')
          .attr('y', 0)
          .attr('x', 9)
          .attr('dy', '.35em')
          .attr('transform', 'rotate(40)')
          .style('text-anchor', 'start')
      } else {
        svg.append('g').call(xAxis).selectAll('text').attr('style', 'white')

        // X axis label needed only for non-text, which is its own label
        svg
          .append('text')
          .attr('text-anchor', 'end')
          .attr('x', width / 2 + margin.left + margin.right)
          .attr('y', height - 30)
          .text(axis.x)
          .style('fill', 'white')
      }

      svg.append('g').call(yAxis).selectAll('text').attr('fill', 'white')

      // make legend
      let legend = svg.append('g')
                .attr('class', 'legend')
                // .attr('transform', 'translate(' - legendOffsetX + width + ', 0)') // + width, put to right side of graph

      legend
        .selectAll('rect')
        .data(domain)
        .enter()
        .append('rect')
        .attr('x', width - legendOffsetX)
        .attr('y', function (d, i) {
          return i * 18 + legendOffsetY
        })
        .attr('width', 12)
        .attr('height', 12)
        .attr('fill', (key) => {
          return color(key)
        })
        .attr('fill', (key) => {
          return color(key)
        })

      legend
        .selectAll('text')
        .data(domain)
        .enter()
        .append('text')
        .text(function (d) {
          return d
        })
        .attr('x', 18 + width - legendOffsetX)
        .attr('y', function (d, i) {
          return i * 18 + legendOffsetY
        })
        .style('fill', 'white')
        .attr('text-anchor', 'start')
        .attr('alignment-baseline', 'hanging')

      svg.node()
    }
    setDrawn(true)
  }

  useEffect(() => {
    if (props.data) {
      drawChart(props.data, props.colors, props.xtype, props.tooltip, props.axis, props.width, props.height, props.legendOffsetX, props.legendOffsetY)
    }
  }, [props])

  return <div id={divId}></div>
}

export default StackBarChart
