import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import * as d3 from "d3"

function ScatterPlot(props) {
    const [drawn, setDrawn] = useState(false)
    const [divId, setDivId] = useState("ScatterPlot" + Math.floor(Math.random() * Math.floor(100000)))
    
    let history = useHistory()

    const drawChart = async (data, addDots, addLine, yRange, xType, colors, tooltip, axis, width, height, legendOffsetX, legendOffsetY) => {
        if (!drawn) {

            if (typeof tooltip == "undefined") {
                tooltip = "Value: "
            }
            if (typeof addDots == "undefined") {
                addDots = true
            }
            if (typeof addLine == "undefined") {
                addLine = true
            }
            if (typeof yRange == "undefined") {
                yRange = [d3.min(data, function(d) {return d.y}), d3.max(data, function(d) {return d.y})]
            }
            if (typeof xType == "undefined") {
                xType = "unknown"
            }
            if (typeof axis == "undefined") {
                axis = {x: "X Values", y: "Y Values"}
            }
            if (!("x" in axis)) {
                axis.x = "X Value"
            }
            if (!("y" in axis)) {
                axis.y = "Y Value"
            }
            if (typeof width == "undefined") {
                width = 900
            }
            if (typeof height == "undefined") {
                height = 700
            }
            if (typeof legendOffsetX == "undefined") {
                legendOffsetX = 0
            }
            if (typeof legendOffsetY == "undefined") {
                legendOffsetY = 0
            }
            if (typeof colors == "undefined") {
                colors = [
                  "gold", 
                  "#157DD8",
                  "#F28E2C", 
                  "#03DAC6", 
                  "#7033FF", 
                  "lightgrey", 
                  "#BB86FC", 
                  "#018786", 
                  "#A1580E"
                ]
            }
            
            for (let i = 0; i < colors.length; i++) {
                let s = new Option().style
                s.color = colors[i].toString()
                if (s.color == '') {
                    colors[i] = "blue"
                }
            }
           
            if (!addDots & !addLine) {
                addDots = true
            }

            // get groups so can track colors
            // also get axis range
            let domain = []
            data.forEach(member => {
                if (!domain.includes(member.group)) {
                    domain.push(member.group)
                }
            })
            
            // set the dimensions and margins of the graph
            let margin = {top: 10, right: 60, bottom: 110, left: 85}
            
            // x-axis can be date or numbers, 
            // and "number" or "date" string should be passed
            // as function argument to choose, if not passed,
            // or invalid selection, then decide based on
            // first index in array
            // Also increases bottom margin if text labels
            
            if (xType != "number" & xType != "date") {
                if (!isNaN(data[0].x)) {
                    xType = "number"
                } else {
                    xType = "date"
                }
            }

            var opac // opacity for the dots
            if (addDots) {
                opac = 0.8
            } else {
                opac = 0
            }
            
            // append the svg object to the body of the page
            let svg = d3.select(`#${divId}`)
            .append("svg")
                .attr("width", width + margin.left + margin.right)
                .attr("height", height + margin.top + margin.bottom)
                .attr("viewBox", [0, 0, width * 1.3, height])
            .append("g")
                .attr("transform",
                    "translate(" + margin.left + "," + margin.top + ")")

            // get ranges for x axis
            let xRange = [d3.min(data, function(d) {return d.x}), d3.max(data, function(d) {return d.x})]
            
            // make axis with good padding values
            
            // X axis
            var x
            if (xType == "number") {

                x = d3.scaleLinear()
                    .range([0, width])

                x.domain([-(xRange[1] * 30 / height), xRange[1]])

                svg.append("g")
                    .attr("transform", "translate(0," + height + ")")
                    .call(d3.axisBottom(x))

            } else { // date
                
                let parseDate = d3.timeParse("%Y-%m-%d")

                data.forEach(function(d) {
                    d.x = parseDate(d.x)
                })

                x = d3.scaleTime()
                    .range([0, width])
                
                x.domain(d3.extent(data, function(d) { return d.x }))
                
                svg.append("g")
                .attr("class", "axis")
                .attr("transform", "translate(0," + height + ")")
                .call(d3.axisBottom(x)
                        .tickFormat(d3.timeFormat("%Y-%m-%d")))

            }

            // Y axis
            let y = d3.scaleLinear()
                .range([ height, 0])

            y.domain([yRange[0]-(yRange[1] * 30 / height), yRange[1]])
            
            svg.append("g")
                .call(d3.axisLeft(y))

            // Color scale: give me a specie name, I return a color
            const color = d3.scaleOrdinal()
                .domain(domain)
                .range(colors)

            // create a tooltip
            let Tooltip = d3.select(`#${divId}`)
                .append("div")
                .style("opacity", 0)
                .style('display','none')
                .attr("class", "tooltip")
                .style("background-color", "#323232")
                .style("border", "solid")
                .style("border-width", "2px")
                .style("border-radius", "5px")
                .style("padding", "5px")

            // Three functions that change the tooltip when user hover / move / leave a point and highlight related points
            
            let mouseover = function(d) {
                let selectedGroup = d.srcElement.__data__.group
                let selectedGroup2 = selectedGroup.replace('/','').replace(/ /g, '_').replace(/\./g, '_')
                
                d3.selectAll("#scatter")
                .transition()
                .duration(200)
                .style("opacity", opac/2)
                .attr("r", 3)

                d3.selectAll(".dot-" + selectedGroup2)
                .transition()
                .duration(200)
                .style("opacity", opac*1.25)
                .attr("r", 7)

                d3.selectAll("#legendText")
                .transition()
                .duration(200)
                .style("opacity", 0.5)

                d3.selectAll(".legendText-" + selectedGroup2)
                .transition()
                .duration(200)
                .style("opacity", 1)
                
                d3.selectAll("#legendDot")
                .transition()
                .duration(200)
                .style("opacity", 0.5)

                d3.selectAll(".legendDot-" + selectedGroup2)
                .transition()
                .duration(200)
                .style("opacity", 1)
                
                Tooltip.style("opacity", 1)
                d3.select(this)
                .style("stroke", "black")
                .style("opacity", 1)

                if (addLine) {
                    d3.selectAll("#linePlot")
                    .transition()
                    .duration(100)
                    .attr("stroke-width", 0.5)

                    d3.selectAll(".line-" + selectedGroup2)
                    .transition()
                    .duration(100)
                    .attr("stroke-width", 2)
                }
            }
            
            let mousemove = function (d) {
                Tooltip
                  .html(`<b>${d.srcElement.__data__.group}</b><br/><b>${tooltip}</b>${d.srcElement.__data__.y}<br/><b>X: </b>${d.srcElement.__data__.x}`)
                  .style('display','block')
                  .style("position", "absolute")
                  .style("left", `${d.pageX}px`)
                  .style("top", `${d.pageY + 25}px`)
              }

            let mouseleave = function(d) {
                
                d3.selectAll("#scatter")
                .transition()
                .duration(1000)
                .attr("r", 5 )
                .style("opacity", opac)

                d3.selectAll("#legendText")
                .transition()
                .duration(200)
                .style("opacity", 1)
                
                d3.selectAll("#legendDot")
                .transition()
                .duration(200)
                .style("opacity", 1)

                if (addLine) {
                    d3.selectAll("#linePlot")
                    .transition()
                    .duration(500)
                    .attr("stroke-width", 1)
                }

                Tooltip
                .style("opacity", 0)
                d3.select(this)
                .style("stroke", "none")
                .style("opacity", 0.8)
            }

            let mouseoverL = function(d, i) {
                
                let selectedGroup = i[0].group
                let selectedGroup2 = selectedGroup.replace('/','').replace(/ /g, '_').replace(/\./g, '_')
                
                d3.selectAll("#linePlot")
                .transition()
                .duration(100)
                .attr("stroke-width", 0.5)

                d3.select(this)
                .transition()
                .duration(100)
                .attr("stroke-width", 2)

                d3.selectAll("#legendText")
                .transition()
                .duration(200)
                .style("opacity", 0.5)

                d3.selectAll(".legendText-" + selectedGroup2)
                .transition()
                .duration(200)
                .style("opacity", 1)
                
                d3.selectAll("#legendDot")
                .transition()
                .duration(200)
                .style("opacity", 0.5)

                d3.selectAll(".legendDot-" + selectedGroup2)
                .transition()
                .duration(200)
                .style("opacity", 1)
                
            }
            
            let mouseleaveL = function(d) {

                d3.selectAll("#linePlot")
                .transition()
                .duration(500)
                .attr("stroke-width", 1)

                d3.selectAll("#legendText")
                .transition()
                .duration(200)
                .style("opacity", 1)

                d3.selectAll("#legendDot")
                .transition()
                .duration(200)
                .style("opacity", 1)
                
            }

            let mouseoverLT = function(d, i) {
                
                let selectedGroup = i
                let selectedGroup2 = selectedGroup.replace('/','').replace(/ /g, '_').replace(/\./g, '_')
                
                if (addLine) {
                    d3.selectAll("#linePlot")
                    .transition()
                    .duration(100)
                    .attr("stroke-width", 0.5)
                    
                    d3.select(".line-" + selectedGroup2)
                    .transition()
                    .duration(100)
                    .attr("stroke-width", 2)
                }

                d3.selectAll("#legendText")
                .transition()
                .duration(200)
                .style("opacity", 0.5)

                d3.selectAll(".legendText-" + selectedGroup2)
                .transition()
                .duration(200)
                .style("opacity", 1)
                
                if (addDots) {
                    d3.selectAll("#scatter")
                    .transition()
                    .duration(200)
                    .style("opacity", opac/2)
                    .attr("r", 3)
    
                    d3.selectAll(".dot-" + selectedGroup2)
                    .transition()
                    .duration(200)
                    .style("opacity", opac*1.25)
                    .attr("r", 7)
                }

                d3.selectAll("#legendDot")
                .transition()
                .duration(200)
                .style("opacity", 0.5)

                d3.selectAll(".legendDot-" + selectedGroup2)
                .transition()
                .duration(200)
                .style("opacity", 1)
                
            }

            let mouseleaveLT = function(d, i) {
                
                d3.selectAll("#linePlot")
                .transition()
                .duration(500)
                .attr("stroke-width", 1)

                d3.selectAll("#scatter")
                .transition()
                .duration(1000)
                .attr("r", 5 )
                .style("opacity", opac)

                d3.selectAll("#legendText")
                .transition()
                .duration(200)
                .style("opacity", 1)

                d3.selectAll("#legendDot")
                .transition()
                .duration(200)
                .style("opacity", 1)
                
            }
            
            // Add dots - add no matter what for tool tip
            svg.append('g')
                .selectAll("dot")
                .data(data)
                .enter()
                .append("circle")
                .attr("id", "scatter")
                .attr("class", function (d) { return "dot-" + d.group.replace('/','').replace(/ /g, '_').replace(/\./g, '_') } )
                .attr("cx", function (d) { return x(d.x); } )
                .attr("cy", function (d) { return y(d.y); } )
                .attr("r", 5)
                .style("fill", function (d) { return color(d.group) } )
                .style("opacity", opac)
                .on("mouseover", mouseover)
                .on("mousemove", mousemove)
                .on("mouseleave", mouseleave)
        
            if (addLine) {
                // Add the lines
                let line = d3.line()
                    .x(function(d) { return x(d.x) })
                    .y(function(d) { return y(d.y) })
                
                for (let i=0; i < domain.length; i++) {
                    let dSubset = data.filter((data) => {return data.group == domain[i]})
                    
                    svg.append("path")
                        .datum(dSubset)
                        .attr("class", "line-" + domain[i].replace('/','').replace(/ /g, '_').replace(/\./g, '_'))
                        .attr("stroke", color(domain[i]))
                        .attr("id", "linePlot")
                        .attr("fill", "none")
                        .attr("d", line)
                        .on("mouseover", mouseoverL) // in case line only is shown
                        .on("mouseleave", mouseleaveL)
        
                }
            }

            // Y axis label
            svg.append("text")
                .attr("text-anchor", "end")
                .attr("transform", "rotate(-90)")
                .attr("y", -50)
                .attr("x", - height / 2 + margin.bottom/2)
                .text(axis.y)
                .style('fill','white')

            // X axis label
            svg.append("text")
                .attr("text-anchor", "end")
                .attr("x", width / 2 + margin.left + margin.right)
                .attr("y", height + 40)
                .text(axis.x)
                .style('fill','white')

            // Legend Groups
            // Add one dot in the legend for each name.
            let size = 20
            svg.selectAll("myrect")
                .data(domain)
                .enter()
                .append("circle")
                .attr("cx", width + 10 - legendOffsetX)
                .attr("cy", function (d, i) { return 10 + legendOffsetY + i * (size + 5) }) // 100 is where the first dot appears. 25 is the distance between dots
                .attr("r", 3)
                .attr("class", function (d) { return "legendDot-" + d.replace('/','').replace(/ /g, '_').replace(/\./g, '_') })
                .attr("id", "legendDot")
                .style("fill", function (d) { return color(d) })
                .on("mouseover", mouseoverLT)
                .on("mouseleave", mouseleaveLT)
        
            // Add labels beside legend dots
            svg.selectAll("mylabels")
                .data(domain)
                .enter()
                .append("text")
                .attr("x", width + 10 - legendOffsetX + size * .8)
                .attr("y", function (d, i) { return i * (size + 5) + (size / 2) + legendOffsetY}) // 100 is where the first dot appears. 25 is the distance between dots
                .style("fill", function (d) { return color(d) })
                .text(function (d) { return d })
                .attr("text-anchor", "left")
                .attr("class", function(d) { return "legendText-" + d.replace('/','').replace(/ /g, '_').replace(/\./g, '_') })
                .attr("id", "legendText")
                .style("alignment-baseline", "middle")
                .on("mouseover", mouseoverLT)
                .on("mouseleave", mouseleaveLT)
        
            setDrawn(true)        
        }
    }

    useEffect(() => {
        if (props.data) {
          drawChart(props.data, props.addDots, props.addLine, props.yRange, props.xType, props.colors, props.tooltip, props.axis, props.width, props.height, props.legendOffsetX, props.legendOffsetY)
        }
      }, [props])
    
      return (
        <div id={divId}></div>
      )
}

export default ScatterPlot