import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import * as d3 from "d3"

function ThresholdBarChart(props) {
    const [divId, setDivId] = useState("StackBarChart" + Math.floor(Math.random() * Math.floor(100000)))
    const [drawn, setDrawn] = useState(false)
    let history = useHistory()

    const drawChart = (data, width, height, colors, tooltip, threshold, axis) => {
        if (!drawn) {
            if (!width)
                width = 900
            if (!height)
                height = 450
            if (!tooltip)
                tooltip = "This cell contains: "
            if (!Array.isArray(colors)) {
                colors = colors.values()
            }

            let margin = ({ top: 10, right: 10, bottom: 60, left: 70 })

            for (let i = 0; i < data.length; i++) {
                if (typeof data[i].y == "string") {
                    data[i].date = data[i].y
                    data[i].y = Date.parse(data[i].y)
                }
            }

            let x = d3.scaleLinear()
                .domain([0, d3.max(data, d => d.x)])
                .range([margin.left, width - margin.right])

            let y = d3.scaleLinear()
                .domain([d3.min(data, function (d) { return d.y }), d3.max(data, function (d) { return d.y * 1.01 })])
                .rangeRound([margin.top, height - margin.bottom])
            // .padding(0.1)

            let xAxis = g => g
                .attr("transform", `translate(0,${height - margin.bottom})`)
                .call(d3.axisBottom(x)
                    .tickValues(d3.ticks(...d3.extent(x.domain()), width / 80))
                    .tickSizeOuter(0))

            let yAxis = g => g
                .attr("transform", `translate(${margin.left},0)`)
                .call(d3.axisLeft(y)
                    .tickFormat(y => new Date(y).getFullYear())
                    .tickValues(d3.ticks(...d3.extent(y.domain()), height / 80)))

            let Tooltip = d3.select(`#${divId}`)
                .append("div")
                .style("opacity", 0)
                .style('display','none')
                .attr("class", "tooltip")
                .style("background-color", "#323232")
                .style("border", "solid")
                .style("border-width", "2px")
                .style("border-radius", "5px")
                .style("padding", "5px")


            let mouseover = function (d) {
                Tooltip.style("opacity", 1)
                d3.select(this)
                    .style("stroke", "white")
                    .style("opacity", 1)
            }

            let mousemove = function (d) {
                let lbl = `<b>${d.srcElement.__data__.label}</b><br/>${tooltip}${d.srcElement.__data__.x}`
                if (d.srcElement.__data__.date) {
                    lbl = `<b>${d.srcElement.__data__.label}</b><br/>${tooltip}${d.srcElement.__data__.x}<br>${d.srcElement.__data__.group}: ${d.srcElement.__data__.date}`
                }
                Tooltip
                    .html(lbl)
                    .style('display','block')
                    .style("position", "absolute")
                    .style("left", `${d.pageX}px`)
                    .style("top", `${d.pageY + 25}px`)
            }

            let mouseleave = function (d) {
                Tooltip
                    .style("opacity", 0)
                d3.select(this)
                    .style("stroke", "none")
                    .style("opacity", 0.8)
            }

            let svg = d3.select(`#${divId}`)
                .append("svg")
                .attr("width", width + margin.left + margin.right)
                .attr("height", height + margin.top + margin.bottom)
                .attr("viewBox", [0, 0, width, height])

            svg.append("g")
                .attr("fill", colors[0])
                .selectAll("rect")
                .data(data)
                .join("rect")
                .attr("x", x(0))
                .attr("y", (d, i) => y(d.y))
                .attr("width", d => x(d.x) - x(0))
                .attr("height", ((height - margin.top - margin.bottom) / data.length) - 3)
                .attr("fill", (d) => { if (d.y < threshold) { return colors[1] } })
                .on("mouseover", mouseover)
                .on("mousemove", mousemove)
                .on("mouseleave", mouseleave)

            svg.append("g")
                .attr("fill", "white")
                .attr("text-anchor", "end")
                .attr("font-family", "sans-serif")
                .attr("font-size", 12)
                .selectAll("text")
                .data(data)
                .join("text")
                .attr("x", d => x(d.x))
                .attr("y", (d, i) => y(d.y) + 5 / 2)
                .attr("dy", ".65em")
                .attr("dx", -4)
                .text((d) => { if (x(d.x) > 100) { return d.label } })  //Inject Custom Text
                .call(text => text.filter(d => x(d.x) - x(0) < 100)
                    .attr("dx", +4)
                    .attr("fill", "white")
                    .attr("text-anchor", "start"))

            svg.append("g")
                .call(xAxis);

            svg.append("g")
                .call(yAxis)

            // Y axis label
            svg.append("text")
                .attr("text-anchor", "end")
                .attr("transform", "rotate(-90)")
                .attr("y", + 20)
                .attr("x", - height / 2 + margin.bottom)
                .text(axis.y)
                .style('fill','white')

            // X axis label
            svg.append("text")
                .attr("text-anchor", "end")
                .attr("x", width / 2 + margin.left + margin.right)
                .attr("y", height)
                .text(axis.x)
                .style('fill','white')

            // Append a vertical line to highlight the separation
            svg.append("line")
                .attr("x1", x(0))
                .attr("x2", x(d3.max(data, d => d.x)))
                .attr("y1", y(threshold))
                .attr("y2", y(threshold))
                .attr("stroke", "white")
                .attr("stroke-dasharray", "4")

            svg.node()
        }
        setDrawn(true)
    }

    useEffect(() => {
        if (props.data && props.colors && props.axis && props.threshold) {
            drawChart(props.data, props.width, props.height, props.colors, props.tooltip, props.threshold, props.axis)
        }
    }, [props])

    return (
        <div id={divId}></div>
    )
}

export default ThresholdBarChart