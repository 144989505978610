import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import * as d3 from "d3"

function BubbleChart(props) {
  const [divId, setDivId] = useState("BubbleChart" + Math.floor(Math.random() * Math.floor(100000)))
  const [drawn, setDrawn] = useState(false)
  let history = useHistory()

  const drawChart = (data, w, h, colors, tooltip, scale) => {
    if (!drawn) {
      if (!w)
        w = 450
      if (!h)
        h = 450
      if (!tooltip)
        tooltip = "This cell contains: "
      if(!Array.isArray(colors)){
        colors = colors.values()
      }

      let domain = []
      for (let i = 0; i < data.length; i++) {
        if(typeof data[i].group == "string") {
          data[i].group = data[i].group.replace('/','').replace(/ /g, '_')
          if (!domain.includes(data[i].group)) {
            domain.push(data[i].group)
          }
        } else {
          console.log(data[i].group)
        }
      }

      // Add a scale for bubble color
      let myColor = d3.scaleOrdinal()
        .domain(domain)
        .range(colors)

      // set the dimensions and margins of the graph
      let margin = { top: 50, right: 150, bottom: 50, left: 80 }
      let width = w - margin.left - margin.right
      let height = h - 100 - margin.top - margin.bottom

      // append the svg object to the body of the page
      let svg = d3.select(`#${divId}`)
        .append("svg")
        .attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom)
        .append("g")
        .attr("transform",
          "translate(" + margin.left + "," + margin.top + ")")

      // Add X axis
      let x = d3.scaleLinear()
        .domain([scale.xMin, scale.xMax])
        .range([0, width - 100])

      svg.append("g")
        .attr("transform", "translate(0," + height + ")")
        .call(d3.axisBottom(x))

      // Add Y axis
      let y = d3.scaleLinear()
        .domain([scale.yMin, scale.yMax])
        .range([height, 0])

      svg.append("g")
        .call(d3.axisLeft(y))

      // Add a scale for bubble size
      let z = d3.scaleLinear()
        .domain([scale.valueMin, scale.valueMax])
        .range([3, 30])

      // create a tooltip
      let Tooltip = d3.select(`#${divId}`)
        .append("div")
        .style("opacity", 0)
        .style('display','none')
        .attr("class", "tooltip")
        .style("background-color", "#323232")
        .style("border", "solid")
        .style("border-width", "2px")
        .style("border-radius", "5px")
        .style("padding", "5px")

      // Three function that change the tooltip when user hover / move / leave a cell
      let mouseover = function (d) {
        Tooltip.style("opacity", 1)
        d3.select(this)
          .style("stroke", "white")
          .style("opacity", 1)
      }

      let mousemove = function (d) {
        Tooltip
          .html(`<b>${d.srcElement.__data__.element}</b><br/>${tooltip}${d.srcElement.__data__.value}`)
          .style('display','block')
          .style("position", "absolute")
          .style("left", `${d.pageX}px`)
          .style("top", `${d.pageY + 25}px`)
      }

      let mouseleave = function (d) {
        Tooltip
          .style("opacity", 0)
        d3.select(this)
          .style("stroke", "none")
          .style("opacity", 0.8)
      }

      // Add dots
      svg.append('g')
        .selectAll("dot")
        .data(data)
        .enter()
        .append("circle")
        .attr("class", function (d) { return "bubbles group-" + d.group })
        .attr("cx", function (d) { return x(d.x); })
        .attr("cy", function (d) { return y(d.y); })
        .attr("r", function (d) { return z(d.value); })
        .style("fill", function (d) { return myColor(d.group) })
        .on("mouseover", mouseover)
        .on("mousemove", mousemove)
        .on("mouseleave", mouseleave)

      // ADD LEGEND

      // Legend Mouseover
      let highlight = function (d) {
        // reduce opacity of all groups
        d3.selectAll(".bubbles").style("opacity", .05)
        // expect the one that is hovered
        d3.selectAll(".group-" + d.srcElement.__data__).style("opacity", 1)
      }

      // And when it is not hovered anymore
      let noHighlight = function (d) {
        d3.selectAll(".bubbles").style("opacity", 1)
      }

      // Legend Groups
      // Add one dot in the legend for each name.
      let size = 20
      svg.selectAll("myrect")
        .data(domain)
        .enter()
        .append("circle")
        .attr("cx", width - 100)
        .attr("cy", function (d, i) { return 10 + i * (size + 5) }) // 100 is where the first dot appears. 25 is the distance between dots
        .attr("r", 3)
        .style("fill", function (d) { return myColor(d) })
        .on("mouseover", highlight)
        .on("mouseleave", noHighlight)

      // Add labels beside legend dots
      svg.selectAll("mylabels")
        .data(domain)
        .enter()
        .append("text")
        .attr("x", width - 100 + size * .8)
        .attr("y", function (d, i) { return i * (size + 5) + (size / 2) }) // 100 is where the first dot appears. 25 is the distance between dots
        .style("fill", function (d) { return myColor(d) })
        .text(function (d) { return d })
        .attr("text-anchor", "left")
        .style("alignment-baseline", "middle")
        .on("mouseover", highlight)
        .on("mouseleave", noHighlight)

      //Legend Scale

      // Add legend: circles
      let valuesToShow = [Math.round(scale.valueMin), Math.round(scale.valueMax / 2), Math.round(scale.valueMax)]
      let xCircle = width - 50
      let xLabel = width
      let xHeight = height

      svg
        .selectAll("legend")
        .data(valuesToShow)
        .enter()
        .append("circle")
        .attr("cx", xCircle)
        .attr("cy", function (d) { return xHeight - z(d) })
        .attr("r", function (d) { return z(d) })
        .style("fill", "none")
        .attr("stroke", "white")

      // Add legend: segments
      svg
        .selectAll("legend")
        .data(valuesToShow)
        .enter()
        .append("line")
        .attr('x1', function (d) { return xCircle + z(d) })
        .attr('x2', xLabel)
        .attr('y1', function (d) { return xHeight - z(d) })
        .attr('y2', function (d) { return xHeight - z(d) })
        .attr('stroke', 'white')
        .style('stroke-dasharray', ('2,2'))

      // Add legend: labels
      svg
        .selectAll("legend")
        .data(valuesToShow)
        .enter()
        .append("text")
        .attr('x', xLabel)
        .attr('y', function (d) { return xHeight - z(d) })
        .text(function (d) { return d })
        .style("font-size", 10)
        .attr('alignment-baseline', 'middle')
        .style('fill','white')

      // Legend title
      svg.append("text")
        .attr('x', xCircle)
        .attr("y", xHeight - 70)
        .text(scale.valueText)
        .attr("text-anchor", "middle")
        .style('fill','white')

      // Y axis label
      svg.append("text")
        .attr("text-anchor", "end")
        .attr("transform", "rotate(-90)")
        .attr("y", -margin.left + 30)
        .attr("x", - height / 2 + margin.bottom)
        .text(scale.yText)
        .style('fill','white')

      // X axis label
      svg.append("text")
        .attr("text-anchor", "end")
        .attr("x", width / 2)
        .attr("y", height + 40 )
        .text(scale.xText)
        .style('fill','white')
    }
    setDrawn(true)
  }

  useEffect(() => {
    if (props.data && props.scale &&
      props.scale.xMin >= 0 && props.scale.xMax >= 0 &&
      props.scale.yMin >= 0 && props.scale.yMax >= 0 &&
      props.scale.valueMin >= 0 && props.scale.valueMax >= 0) {
      drawChart(props.data, props.width, props.height, props.colors, props.tooltip, props.scale)
    }
  }, [props])

  return (
    <div id={divId}></div>
  )

}

export default BubbleChart