import React, { useEffect, useState } from 'react'
import { Switch, Route } from 'react-router-dom'
import { Auth, Analytics } from 'aws-amplify'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import { API } from 'aws-amplify'
import {
  AppBar,
  Toolbar,
  Link,
  Button,
  Container,
  Drawer,
  Menu,
  MenuItem,
} from '@material-ui/core'
import Home from './components/Home'
import SelectedDashboard from './components/SelectedDashboard'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Reports } from './components/Reports'
import ReportList from './components/ReportList'
import EmailReport from './components/EmailReport'
import WorkRequest from './components/WorkRequest'
import DashboardList from './components/DashboardList'
import './App.css'

// Stories
import Example from './stories/Example'
import Time from './stories/Time'
import SQLEndOfLife from './stories/SQLEndOfLife'
import WinEndOfLife from './stories/WinEndOfLife'
import PatchTracking from './stories/PatchTracking'
import MparBatteryIssue from './stories/MparBatteryIssue'
import MparReboots from './stories/MparReboots'
import Mpar from './stories/Mpar'
import AnomalyApps from './stories/AnomalyApps'
import JHReport from './stories/JHReport'
import ESETAntiVirus from './stories/ESETAntiVirus'
import Drivespacemonitoring from './stories/DriveSpaceMonitoring'
import StationStability from './stories/StationStability'
import ES161RiskAssessment from './stories/ES161RiskAssessment'
import DeviceUsage from './stories/DeviceUsage'
import TfsCurrentState from './stories/TfsCurrentState'
import DevOpsKPI from './stories/DevOpsKPI'
import DevOpsKPI_ChangeVolume from './stories/DevOpsKPI_ChangeVolume'
import DevOpsKPI_DeploymentTime from './stories/DevOpsKPI_DeploymentTime'
import DevOpsKPI_FailedDeployments from './stories/DevOpsKPI_FailedDeployments'
import DevOpsKPI_LeadTime from './stories/DevOpsKPI_LeadTime'
import DevOpsKPI_MeanTime from './stories/DevOpsKPI_MeanTime'

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [user, setUser] = useState(
    localStorage.getItem('user')
      ? localStorage.getItem('user').replace('bd-login_', '')
      : 'Not Signed In',
  )
  const [isLoading, setIsLoading] = useState(true)
  const [links, setLinks] = useState([])
  const [menus, setMenus] = useState([])
  const [workbooks, setWorkbooks] = useState([])
  const [requestAnchor, setRequests] = useState(false)
  const [workbookAnchor, setWorkbookAnchor] = useState(false)
  const [drawerAnchor, setDrawerAnchor] = useState(false)
  const [selectedReport, setReport] = useState(null)
  const [reports, setReports] = useState(Reports)
  const [anchorReports, setAnchorReports] = useState(null)
  const [reportMenu, setReportMenu] = useState(false)

  const theme = createMuiTheme({
    typography: {
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"georgia"',
        '"serif"',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
    },
    palette: {
      type: 'dark',
    },
  })

  useEffect(() => {
    ;(async () => {
      try {
        // Authentication
        await Auth.currentSession()
        await Auth.currentAuthenticatedUser()
        let redirectUrl = localStorage.getItem('redirectUrl')
        if (redirectUrl) {
          localStorage.removeItem('redirectUrl')
          window.location.assign(redirectUrl)
        }

        setIsLoading(false)
        setIsAuthenticated(true)

        if (localStorage.getItem('user')) {
          setUser(localStorage.getItem('user').replace('bd-login_', ''))
        } else {
          Object.keys(localStorage).forEach((key) => {
            if (key.indexOf('LastAuthUser') != -1) {
              setUser(localStorage[key].replace('bd-login_', ''))
              localStorage.setItem('user', localStorage[key])
            }
          })
        }
      } catch (error) {
        localStorage.setItem('redirectUrl', window.location.href)
        Auth.federatedSignIn({ provider: 'bd-login' })
      }

      // PageView
      try {
        Analytics.record({
          name: 'pageview',
          Endpoint: user,
          attributes: {
            Timestamp: new Date().toISOString(),
            ChannelType: 'EMAIL',
            Path: window.location.pathname,
            Address: user,
          },
          immediate: true,
        })
      } catch (error) {}

      // Links
      setLinks([
        { name: 'Workbooks', onClick: toggleWorkbooks() },
        { name: 'Data Request', onClick: toggleDataRequest() },
      ])

      let workbookList = []
      await API.get('qs-web-api', '/dashboards', {})
        .then((listDashboardsResponse) => {
          listDashboardsResponse.forEach((dashboard) => {
            let name = dashboard.Name.replace(/ /g, '_')
            workbookList.push({
              name: dashboard.Name,
              url: `/dashboard/${name}`,
              id: dashboard.DashboardId,
            })
          })
          workbookList = workbookList.sort((a, b) =>
            a.name > b.name ? 1 : b.name > a.name ? -1 : 0,
          )
          localStorage.dashboards = JSON.stringify(menus[0].items)
        })
        .catch(function (error) {
          console.log(error)
        })

      setWorkbooks(workbookList)

      let menuItems = []
      Reports.forEach((report) => {
        menuItems.push({
          name: report.title,
          onClick: toggleDrawer(report),
        })
      })
      setMenus(menuItems)
    })()
  }, [])

  const toggleWorkbooks = () => (event) => {
    if (event.currentTarget.label == 'workbookOpen') {
      event.currentTarget.label = null
      setWorkbookAnchor(false)
    } else {
      event.currentTarget.label = 'workbookOpen'
      setWorkbookAnchor(true)
    }
  }

  const toggleDataRequest = () => (event) => {
    if (event.currentTarget.label == 'requestsOpen') {
      event.currentTarget.label = null
      setRequests(false)
    } else {
      event.currentTarget.label = 'requestsOpen'
      setRequests(true)
    }
  }

  const toggleDrawer = (report) => (event) => {
    if (report) {
      setReport(report)
    }
    setAnchorReports(null)
    setDrawerAnchor(!drawerAnchor)
  }

  const reportOpen = (event) => {
    setReportMenu(true)
    setAnchorReports(event.currentTarget)
  }

  const reportClose = (event) => {
    setReportMenu(false)
    setAnchorReports(null)
  }

  const handleLogout = async (event) => {
    event.preventDefault()
    try {
      await Auth.signOut()
      setIsAuthenticated(false)
    } catch (error) {}
  }

  const authProps = {
    isAuthenticated: isAuthenticated,
    setIsAuthenticated: setIsAuthenticated,
  }

  if (isLoading) {
    return (
      <div className="circular-progress">
        <CircularProgress disableShrink />
      </div>
    )
  } else {
    return (
      <Container className="root">
        <ThemeProvider theme={theme}>
          <AppBar position="fixed">
            <Toolbar>
              <Link href="/" color="inherit" variant="h5" className="siteTitle">
                BDlytics
              </Link>
              <div style={{ marginRight: '-600px' }}></div>
              {links.map((link) => {
                return (
                  <Button onClick={link.onClick} color="inherit" variant="h6">
                    {link.name}
                  </Button>
                )
              })}
              <Button onClick={reportOpen} color="inherit" variant="h6">
                Reports
              </Button>
              <Menu
                id="reports-menu"
                aria-labelledby="reports-menu"
                anchorEl={anchorReports}
                open={reportMenu}
                onClose={reportClose}
              >
                {menus.map((menu) => {
                  return <MenuItem onClick={menu.onClick}>{menu.name}</MenuItem>
                })}
              </Menu>

              {isAuthenticated && (
                <Button onClick={handleLogout} color="inherit">
                  Logout
                </Button>
              )}
            </Toolbar>
          </AppBar>
          <Drawer
            anchor="top"
            open={workbookAnchor}
            onClose={toggleWorkbooks(null)}
          >
            <DashboardList workbooks={workbooks} close={setWorkbookAnchor} />
          </Drawer>
          <Drawer anchor="top" open={drawerAnchor} onClose={toggleDrawer(null)}>
            <EmailReport report={selectedReport} close={setDrawerAnchor} />
          </Drawer>
          <Drawer
            anchor="top"
            open={requestAnchor}
            onClose={toggleDataRequest(null)}
          >
            <WorkRequest close={setRequests} />
          </Drawer>
          <Switch>
            <Route
              exact
              path="/"
              render={(props) => <Home {...props} auth={authProps} />}
            />
            <Route
              exact
              path="/reports"
              render={(props) => (
                <ReportList
                  {...props}
                  toggleDrawer={toggleDrawer}
                  auth={authProps}
                />
              )}
            />
            <Route
              exact
              path="/dashboard"
              render={(props) => <Home {...props} auth={authProps} />}
            />
            <Route
              exact
              path="/dashboard/:dashboardId"
              render={(props) => (
                <SelectedDashboard {...props} auth={authProps} />
              )}
            />
            {/* Stories  */}
            <Route
              path="/example"
              render={(props) => <Example {...props} auth={authProps} />}
            />
            <Route
              path="/anomalyApps"
              render={(props) => <AnomalyApps {...props} auth={authProps} />}
            />
            <Route
              path="/jhReport"
              render={(props) => <JHReport {...props} auth={authProps} />}
            />
            <Route
              path="/time"
              render={(props) => <Time {...props} auth={authProps} />}
            />
            <Route
              path="/patchtracking"
              render={(props) => <PatchTracking {...props} auth={authProps} />}
            />
            <Route
              path="/sqlendoflife"
              render={(props) => <SQLEndOfLife {...props} auth={authProps} />}
            />
            <Route
              path="/winendoflife"
              render={(props) => <WinEndOfLife {...props} auth={authProps} />}
            />
            <Route
              path="/mpar"
              render={(props) => <Mpar {...props} auth={authProps} />}
            />
            <Route
              path="/StationStability"
              render={(props) => (
                <StationStability {...props} auth={authProps} />
              )}
            />
            <Route
              path="/DeviceUsage"
              render={(props) => <DeviceUsage {...props} auth={authProps} />}
            />
          </Switch>
        </ThemeProvider>
      </Container>
    )
  }
}

export default App
