
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { API } from 'aws-amplify'
import * as d3 from 'd3'
import color from '../shared/color'
import StoryTitle from '../components/StoryTitle'
import StoryTable from '../components/StoryTable'
import { 
    ScatterPlot, 
    HeatMap, 
    BarChart, 
    StackBarChart, 
    Parallel,
    Sunburst,
    Violin, 
    DonutChart, 
    BubbleChart, 
    ThresholdBarChart, 
    ChoroplethUS 
} from '../d3'
import { Typography, Paper, Grid, CircularProgress } from '@material-ui/core'
import { Analytics } from 'aws-amplify'
import '../story.css'

function Example() {
    const [isLoading, setIsLoading] = useState(true)
    const [title, setTitle] = useState("BDlytics d3.js component example showcase")
    const [data, setData] = useState(null)
    const [heatData, setHeatData] = useState(null)
    const [donut, setDonut] = useState(null)
    const [violin, setViolin] = useState(null)
    const [bubble, setBubble] = useState(null)
    const [scale, setScale] = useState(null)
    const [threshold, setThreshold] = useState(null)
    const [choropleth, setChoroplath] = useState(null)
    const [counties, setCounties] = useState(null)
    let history = useHistory()

    useEffect(() => {
        async function fetch() {

            //Read the data
            try {
                let heat = await d3.csv("https://raw.githubusercontent.com/holtzy/D3-graph-gallery/master/DATA/heatmap_data.csv", function (data) { return data })
                setHeatData(heat)

                setChoroplath(new Map())

            } catch (e) {

            }
            await API.get('qs-web-api', '/data/home/counties-albers-10m.json', {})
            .then((storyData) => {
                setCounties(storyData)
            })

            await API.get('qs-web-api', '/data/example/example-bubble.json', {})
                .then((storyData) => {
                    setBubble(storyData)
                    setScale({
                        yMin: 35,
                        yMax: 95,
                        xMin: 0,
                        xMax: 15000,
                        valueMin: 20000,
                        valueMax: 1500000000,
                        yText: "Y Description",
                        xText: "X Description",
                        valueText: "Description of bubble values"
                    })
                })
                .catch(function (error) {
                    console.log(error)
                })

            await API.get('qs-web-api', '/data/example/stackedbar.json', {})
                .then((storyData) => {
                    setData(storyData)
                })
                .catch(function (error) {
                    console.log(error)
                })

            await API.get('qs-web-api', '/data/example/donutchart.json', {})
                .then((storyData) => {
                    setDonut(storyData)
                })
                .catch(function (error) {
                    console.log(error)
                })

            await API.get('qs-web-api', '/data/example/thresholdbarchart.json', {})
                .then((storyData) => {
                    setThreshold(storyData)
                })
                .catch(function (error) {
                    console.log(error)
                })

            setIsLoading(false)
        }

        fetch()
    }, [])

    if (isLoading) {
        return (<div className="circular-progress"><CircularProgress disableShrink /></div>)
    } else {
        return (
            <div id="story">
                <StoryTitle title={title} date={new Date(Date.now()).toDateString()}></StoryTitle>

                <Paper className="storySection">
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <div className="description">
                            <Typography>
                                This is an example layout combining React components in an advanced layout alongside D3js visualizations.
                                All reusable d3js components and example data will be displayed here as a showcase of reacts flexibility and
                                as a working example of each reusable d3 component added to the BDlytics platform.
                        </Typography>
                        </div>
                    </Grid>
                </Paper>

                <Paper className="storySection">
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <div className="storyTitle">
                            <Typography variant="h5" fontWeight="bold">
                                DonutChart
                        </Typography>
                        </div>
                    </Grid>
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <pre>
                            {`
<DonutChart 
    data={donut} 
    colors={color.defaultScheme} 
    tooltip={"The value of<br>this cell is: "} 
    width={500} 
    height={400} 
    radius={200}
></DonutChart>
                        `}
                        </pre>
                    </Grid>
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <DonutChart data={donut} colors={color.defaultScheme} tooltip={"The value of<br>this cell is: "} width={500} height={400} radius={200}></DonutChart>
                    </Grid>
                    <Grid container justify="center" spacing={2} >
                        <Typography>
                            Example data for DonutChart:
                    </Typography>
                    </Grid>
                    <Grid container justify="center" spacing={2} >
                        <pre>
                            {`
{ 
    "type": 9,
    "b": 20,
    "c": 30,
    "d": 8,
    "e": 12,
    "f": 3,
    "g": 7,
    "h": 14
}
                        `}
                        </pre>
                    </Grid>
                </Paper>

                <Paper className="storySection">
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <div className="storyTitle">
                            <Typography variant="h5" fontWeight="bold">
                                HeatMap
                        </Typography>
                        </div>
                    </Grid>
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <pre>
                            {`
<HeatMap 
    data={heatData} 
    colors={["yellow", "orange", "red"]} 
    tooltip={"The value of<br>this cell is: "} 
    width={500} 
    height={500}
></HeatMap>
                        `}
                        </pre>
                    </Grid>
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <HeatMap data={heatData} colors={["yellow", "orange", "red"]} tooltip={"The value of<br>this cell is: "} width={500} height={500}></HeatMap>
                    </Grid>
                    <Grid container justify="center" spacing={2} >
                        <div className="description">
                            <Typography>
                                Example data for the HeatMap:
                    </Typography>
                        </div>
                    </Grid>
                    <Grid container justify="center" spacing={2} >
                        <pre>
                            {`
[
    {"group": "A", "variable": "v1", "value": "30"}
    {"group": "A", "variable": "v2", "value": "95"}
    {"group": "A", "variable": "v3", "value": "22"}
    {"group": "A", "variable": "v4", "value": "14"}
    {"group": "A", "variable": "v5", "value": "59"}
    {"group": "A", "variable": "v6", "value": "52"}
    {"group": "A", "variable": "v7", "value": "88"}
    {"group": "A", "variable": "v8", "value": "20"}
    {"group": "A", "variable": "v9", "value": "99"}
    {"group": "A", "variable": "v10", "value": "66"}
    {"group": "B", "variable": "v1", "value": "37"}
    {"group": "B", "variable": "v2", "value": "50"}
    {"group": "B", "variable": "v3", "value": "81"}
    {"group": "B", "variable": "v4", "value": "79"}
    {"group": "B", "variable": "v5", "value": "84"}
    {"group": "B", "variable": "v6", "value": "91"}
    {"group": "B", "variable": "v7", "value": "82"}
]
                        `}
                        </pre>
                    </Grid>
                </Paper>

                <Paper className="storySection">
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <div className="storyTitle">
                            <Typography variant="h5" fontWeight="bold">
                                BubbleChart
                        </Typography>
                        </div>
                    </Grid>
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <pre>
                            {`
<BubbleChart 
    data={bubble} 
    scale={scale}
    colors={color.defaultScheme} 
    tooltip={"The value of<br>this cell is: "} 
    width={500} 
    height={400} 
    radius={200}
></BubbleChart>
                        `}
                        </pre>
                    </Grid>
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <BubbleChart data={bubble} scale={scale} colors={color.defaultScheme} tooltip={"The value of<br>this cell is: "} width={900} height={500}></BubbleChart>
                    </Grid>
                    <Grid container justify="center" spacing={2} >
                        <Typography>
                            Example data for BubbleChart:
                    </Typography>
                    </Grid>
                    <Grid container justify="center" spacing={2} >
                        <pre>
                            {`
[
    {"element":"Afghanistan","group":"Asia","y":43.828,"value":31889923,"x":974.5803384},
    {"element":"Albania","group":"Europe","y":76.423,"value":3600523,"x":5937.029526},
    {"element":"Algeria","group":"Africa","y":72.301,"value":33333216,"x":6223.367465},
    {"element":"Angola","group":"Africa","y":42.731,"value":12420476,"x":4797.231267},
    {"element":"Argentina","group":"Americas","y":75.32,"value":40301927,"x":12779.37964},
    {"element":"Australia","group":"Oceania","y":81.235,"value":20434176,"x":34435.36744},
    {"element":"Austria","group":"Europe","y":79.829,"value":8199783,"x":36126.4927},
    {"element":"Bahrain","group":"Asia","y":75.635,"value":708573,"x":29796.04834},
    {"element":"Bangladesh","group":"Asia","y":64.062,"value":150448339,"x":1391.253792},
    {"element":"Belgium","group":"Europe","y":79.441,"value":10392226,"x":33692.60508},
    {"element":"Benin","group":"Africa","y":56.728,"value":8078314,"x":1441.284873},
    {"element":"Bolivia","group":"Americas","y":65.554,"value":9119152,"x":3822.137084},
    {"element":"Bosnia and Herzegovina","group":"Europe","y":74.852,"value":4552198,"x":7446.298803},
    {"element":"Botswana","group":"Africa","y":50.728,"value":1639131,"x":12569.85177}
]
                        `}
                        </pre>
                    </Grid>
                    <Grid container justify="center" spacing={2} >
                        <Typography>
                            Scale Data for BubbleChart:
                    </Typography>
                    </Grid>
                    <Grid container justify="center" spacing={2} >
                        <pre>
                            {`
{
    "yText": "Description of axis",
    "xText": "Description of axis",
    "valueText": "Description of bubble values",
    "yMin": 35,
    "yMax": 95,
    "xMin": 0,
    "xMax": 15000,
    "valueMin": 20000,
    "valueMax": 1500000000
}
                            `}
                        </pre>
                    </Grid>
                </Paper>

                <Paper className="storySection">
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <div className="storyTitle">
                            <Typography variant="h5" fontWeight="bold">
                                StackBarChart
                        </Typography>
                        </div>
                    </Grid>
                    <Grid container justifyContent='center'>
                        <div className="subText">
                            Additional Optional Arguments<br/>
                            xType = 'text' for text labels on x-axis, 'number' for numeric data on x-axis<br/>
                            legendOffsetX = number, for how far to move the legend left<br/>
                            legendOffsetY = number, for how far to move the legend down<br/>
                            Note, with a single group this will render a vertical bar chart and can be used with pre-transformed data to create a histogram
                        </div>
                    </Grid>
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <pre>
                            {`
<StackBarChart
 data={data}
 axis = {{y:"total"}}
 colors={color.defaultScheme}
 tooltip={""}
 width={800}
 height={400}
 ></StackBarChart>
                        `}
                        </pre>
                    </Grid>
                    <Grid container justify="center" spacing={2} >
                        <StackBarChart
                            data={data.stackedbarFacAnom}
                            axis = {{y:"total"}}
                            colors={color.defaultScheme}
                            tooltip={""}
                            width={800}
                            height={400}>
                        </StackBarChart>
                    </Grid>
                    <Grid container justify="center" spacing={2} >
                        <div className="description">
                            <Typography>
                                Example data for StackBarChart:
                    </Typography>
                        </div>
                    </Grid>
                    <Grid container justify="center" spacing={2} >
                        <pre>
                            {`
[
    {"group":"1.15.0.9-6.3","x":"DWIGHT D EISENHOWER VAMC (LVN)","y":0},
    {"group":"3.22.0.18-6.3","x":"DWIGHT D EISENHOWER VAMC (LVN)","y":0},
    {"group":"3.18.1.7-6.1","x":"DWIGHT D EISENHOWER VAMC (LVN)","y":0},
    {"group":"Other","x":"DWIGHT D EISENHOWER VAMC (LVN)","y":31},
    {"group":"3.25.0.26-6.1","x":"DWIGHT D EISENHOWER VAMC (LVN)","y":0},
    {"group":"3.18.2.5-6.3","x":"DWIGHT D EISENHOWER VAMC (LVN)","y":0},
    {"group":"3.25.0.26-6.3","x":"DWIGHT D EISENHOWER VAMC (LVN)","y":0},
    {"group":"1.18.0.27-6.1","x":"DWIGHT D EISENHOWER VAMC (LVN)","y":0},
    {"group":"1.18.0.27-6.3","x":"DWIGHT D EISENHOWER VAMC (LVN)","y":0},
    {"group":"3.22.0.18-6.1","x":"DWIGHT D EISENHOWER VAMC (LVN)","y":0},
    {"group":"1.15.0.9-6.3","x":"Cape Fear Valley Hospital","y":0},
    {"group":"3.22.0.18-6.3","x":"Cape Fear Valley Hospital","y":0},
    {"group":"3.18.1.7-6.1","x":"Cape Fear Valley Hospital","y":0},
    {"group":"Other","x":"Cape Fear Valley Hospital","y":0},
    {"group":"3.25.0.26-6.1","x":"Cape Fear Valley Hospital","y":255},
    {"group":"3.18.2.5-6.3","x":"Cape Fear Valley Hospital","y":0},
    {"group":"3.25.0.26-6.3","x":"Cape Fear Valley Hospital","y":0},
    {"group":"1.18.0.27-6.1","x":"Cape Fear Valley Hospital","y":34},
    {"group":"1.18.0.27-6.3","x":"Cape Fear Valley Hospital","y":1},
    {"group":"3.22.0.18-6.1","x":"Cape Fear Valley Hospital","y":0},
    {"group":"1.15.0.9-6.3","x":"LAKELAND MED CTR  ST JOSEPH","y":0},
    {"group":"3.22.0.18-6.3","x":"LAKELAND MED CTR  ST JOSEPH","y":2},
    {"group":"3.18.1.7-6.1","x":"LAKELAND MED CTR  ST JOSEPH","y":0},
    {"group":"Other","x":"LAKELAND MED CTR  ST JOSEPH","y":0},
    {"group":"3.25.0.26-6.1","x":"LAKELAND MED CTR  ST JOSEPH","y":201},
    {"group":"3.18.2.5-6.3","x":"LAKELAND MED CTR  ST JOSEPH","y":0},
    {"group":"3.25.0.26-6.3","x":"LAKELAND MED CTR  ST JOSEPH","y":161},
    {"group":"1.18.0.27-6.1","x":"LAKELAND MED CTR  ST JOSEPH","y":0},
    {"group":"1.18.0.27-6.3","x":"LAKELAND MED CTR  ST JOSEPH","y":0},
    {"group":"3.22.0.18-6.1","x":"LAKELAND MED CTR  ST JOSEPH","y":0},
    {"group":"1.15.0.9-6.3","x":"Monongalia Emergency Medical Services","y":0},
    {"group":"3.22.0.18-6.3","x":"Monongalia Emergency Medical Services","y":0},
    {"group":"3.18.1.7-6.1","x":"Monongalia Emergency Medical Services","y":0},
    {"group":"Other","x":"Monongalia Emergency Medical Services","y":0},
    {"group":"3.25.0.26-6.1","x":"Monongalia Emergency Medical Services","y":0},
    {"group":"3.18.2.5-6.3","x":"Monongalia Emergency Medical Services","y":0},
    {"group":"3.25.0.26-6.3","x":"Monongalia Emergency Medical Services","y":20},
    {"group":"1.18.0.27-6.1","x":"Monongalia Emergency Medical Services","y":0},
    {"group":"1.18.0.27-6.3","x":"Monongalia Emergency Medical Services","y":0},
    {"group":"3.22.0.18-6.1","x":"Monongalia Emergency Medical Services","y":0}
]
`}
                        </pre>
                    </Grid>
                </Paper>

                <Paper className="storySection">
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <div className="storyTitle">
                            <Typography variant="h5" fontWeight="bold">
                                ScatterPlot
                        </Typography>
                        </div>
                    </Grid>
                    <Grid container justifyContent='center'>
                        <div className="subText">
                            Additional Optional Arguments<br/>
                            xType = 'date' for date on x-axis<br/>
                            yRange to manually set y-axis range<br/>
                            legendOffsetX = number, for how far to move the legend left<br/>
                            legendOffsetY = number, for how far to move the legend down<br/>
                            Note, can manually add trend line by adding another group
                        </div>
                    </Grid>
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <pre>
                            {`
<ScatterPlot
data={data}
axis = {{x:"count", y:"total"}}
colors={color.defaultScheme}
xType = "number"
addDots = {false}
addLine = {true}
tooltip={"ScatterPlot Example"} 
width={800}
height={400}
></ScatterPlot>
                        `}
                        </pre>
                    </Grid>
                    <Grid container justify="center" spacing={2} >
                        <ScatterPlot
                            data={JSON.parse('[{"group":"A","x":1,"y":2.2079},{"group":"A","x":2,"y":3.1604},{"group":"A","x":3,"y":3.7002},{"group":"A","x":4,"y":5.5868},{"group":"A","x":5,"y":5.5585},{"group":"A","x":6,"y":4.7234},{"group":"A","x":7,"y":6.4267},{"group":"A","x":8,"y":6.7754},{"group":"A","x":9,"y":8.5266},{"group":"A","x":10,"y":9.3796},{"group":"trend line","x":1,"y":2.3545},{"group":"trend line","x":2,"y":3.0767},{"group":"trend line","x":3,"y":3.799},{"group":"trend line","x":4,"y":4.5212},{"group":"trend line","x":5,"y":5.2434},{"group":"trend line","x":6,"y":5.9657},{"group":"trend line","x":7,"y":6.6879},{"group":"trend line","x":8,"y":7.4102},{"group":"trend line","x":9,"y":8.1324},{"group":"trend line","x":10,"y":8.8546}]')}
                            axis = {{x:"count", y:"total"}}
                            colors={color.defaultScheme}
                            xType = "number"
                            addDots = {false}
                            addLine = {true}
                            tooltip={"ScatterPlot Example"} 
                            width={800}
                            height={400}>
                        </ScatterPlot>
                    </Grid>
                    <Grid container justify="center" spacing={2} >
                        <div className="description">
                            <Typography>
                                Example data for ScatterPlot:
                    </Typography>
                        </div>
                    </Grid>
                    <Grid container justify="center" spacing={2} >
                        <pre>
                            {`
[
    {"group": "A", "x": 1, "y": 2.2079},
    {"group": "A", "x": 2, "y": 3.1604},
    {"group": "A", "x": 3, "y": 3.7002},
    {"group": "A", "x": 4, "y": 5.5868},
    {"group": "A", "x": 5, "y": 5.5585},
    {"group": "A", "x": 6, "y": 4.7234},
    {"group": "A", "x": 7, "y": 6.4267},
    {"group": "A", "x": 8, "y": 6.7754},
    {"group": "A", "x": 9, "y": 8.5266},
    {"group": "A", "x": 10, "y": 9.3796},
    {"group": "trend line", "x": 1, "y": 2.3545},
    {"group": "trend line", "x": 2, "y": 3.0767},
    {"group": "trend line", "x": 3, "y": 3.799},
    {"group": "trend line", "x": 4, "y": 4.5212},
    {"group": "trend line", "x": 5, "y": 5.2434},
    {"group": "trend line", "x": 6, "y": 5.9657},
    {"group": "trend line", "x": 7, "y": 6.6879},
    {"group": "trend line", "x": 8, "y": 7.4102},
    {"group": "trend line", "x": 9, "y": 8.1324},
    {"group": "trend line", "x": 10, "y": 8.8546}
]
`}
                        </pre>
                    </Grid>
                </Paper>

                <Paper className="storySection">
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <div className="storyTitle">
                            <Typography variant="h5" fontWeight="bold">
                                Parallel
                        </Typography>
                        </div>
                    </Grid>
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <pre>
                            {`
<Parallel
data={data}
axis = {{x:"count", y:"total"}}
colors={color.defaultScheme}
tooltip="Group"
width={800}
height={400}
></Parallel>
                        `}
                        </pre>
                    </Grid>
                    <Grid container justify="center" spacing={2} >
                        <Parallel
                            data={JSON.parse('[{"Valid Dose":"Valid Dose: No","Total Volume":"Total Volume: Missing","Matched Name Not Dose":"Matched Name Not Dose: No","Type":"Type: Non-Formulary","BDL ID":"BDL ID: Not Found","value":1},{"Valid Dose":"Valid Dose: No","Total Volume":"Total Volume: Missing","Matched Name Not Dose":"Matched Name Not Dose: Yes","Type":"Type: Formulary","BDL ID":"BDL ID: Not Found","value":2},{"Valid Dose":"Valid Dose: No","Total Volume":"Total Volume: Provided","Matched Name Not Dose":"Matched Name Not Dose: Yes","Type":"Type: Formulary","BDL ID":"BDL ID: Not Found","value":1},{"Valid Dose":"Valid Dose: Yes","Total Volume":"Total Volume: Missing","Matched Name Not Dose":"Matched Name Not Dose: NA","Type":"Type: Formulary","BDL ID":"BDL ID: Found","value":4},{"Valid Dose":"Valid Dose: Yes","Total Volume":"Total Volume: Provided","Matched Name Not Dose":"Matched Name Not Dose: No","Type":"Type: Formulary","BDL ID":"BDL ID: Not Found","value":2}]')}
                            colors={color.defaultScheme}
                            tooltip="Group"
                            width={800}
                            height={400} >
                        </Parallel>
                    </Grid>
                    <Grid container justify="center" spacing={2} >
                        <div className="description">
                            <Typography>
                                Example data for Parallel:
                    </Typography>
                        </div>
                    </Grid>
                    <Grid container justify="center" spacing={2} >
                        <pre>
                            {`
[
    {
        "Valid Dose": "Valid Dose: No",
        "Total Volume": "Total Volume: Missing",
        "Matched Name Not Dose": "Matched Name Not Dose: No",
        "Type": "Type: Non-Formulary",
        "BDL ID": "BDL ID: Not Found",
        "value": 1
    },
    {
        "Valid Dose": "Valid Dose: No",
        "Total Volume": "Total Volume: Missing",
        "Matched Name Not Dose": "Matched Name Not Dose: Yes",
        "Type": "Type: Formulary",
        "BDL ID": "BDL ID: Not Found",
        "value": 2
    },
    {
        "Valid Dose": "Valid Dose: No",
        "Total Volume": "Total Volume: Provided",
        "Matched Name Not Dose": "Matched Name Not Dose: Yes",
        "Type": "Type: Formulary",
        "BDL ID": "BDL ID: Not Found",
        "value": 1
    },
    {
        "Valid Dose": "Valid Dose: Yes",
        "Total Volume": "Total Volume: Missing",
        "Matched Name Not Dose": "Matched Name Not Dose: NA",
        "Type": "Type: Formulary",
        "BDL ID": "BDL ID: Found",
        "value": 4
    },
    {
        "Valid Dose": "Valid Dose: Yes",
        "Total Volume": "Total Volume: Provided",
        "Matched Name Not Dose": "Matched Name Not Dose: No",
        "Type": "Type: Formulary",
        "BDL ID": "BDL ID: Not Found",
        "value": 2
    }
]
`}
                        </pre>
                    </Grid>
                </Paper>

                <Paper className="storySection">
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <div className="storyTitle">
                            <Typography variant="h5" fontWeight="bold">
                                Sunburst
                        </Typography>
                        </div>
                    </Grid>
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <pre>
                            {`
<Sunburst
data={data}
colors={color.defaultScheme}
w=800
></Sunburst>
                        `}
                        </pre>
                    </Grid>
                    <Grid container justify="center" spacing={2} >
                        <Sunburst
                            data={JSON.parse('{"name":"center hole","children":[{"name":"Animals","children":[{"name":"Birds","children":[{"name":"Crows","value":3},{"name":"Ravens","value":3}]},{"name":"Dogs","children":[{"name":"Beagles","value":3},{"name":"Retrievers","value":3},{"name":"Corgi","value":3}]},{"name":"Cats","value":1}]},{"name":"Plants","children":[{"name":"Trees","children":[{"name":"Oaks","value":2},{"name":"Pines","value":3}]},{"name":"Bushes","value":3},{"name":"Flowers","value":1}]}]}')}
                            colors={color.defaultScheme}
                            w={800} >
                        </Sunburst>
                    </Grid>
                    <Grid container justify="center" spacing={2} >
                        <div className="description">
                            <Typography>
                                Example data for Sunburst:
                    </Typography>
                        </div>
                    </Grid>
                    <Grid container justify="center" spacing={2} >
                        <pre>
                            {`
{
    "name": "center hole",
    "children": [
        {
            "name": "Animals",
            "children": [
                {
                    "name": "Birds",
                    "children": [
                        {
                            "name": "Crows",
                            "value": 3
                        },
                        {
                            "name": "Ravens",
                            "value": 3
                        }
                    ]
                },
                {
                    "name": "Dogs",
                    "children": [
                        {
                            "name": "Beagles",
                            "value": 3
                        },
                        {
                            "name": "Retrievers",
                            "value": 3
                        },
                        {
                            "name": "Corgi",
                            "value": 3
                        }
                    ]
                },
                {
                    "name": "Cats",
                    "value": 1
                }
            ]
        },
        {
            "name": "Plants",
            "children": [
                {
                    "name": "Trees",
                    "children": [
                        {
                            "name": "Oaks",
                            "value": 2
                        },
                        {
                            "name": "Pines",
                            "value": 3
                        }
                    ]
                },
                {
                    "name": "Bushes",
                    "value": 3
                },
                {
                    "name": "Flowers",
                    "value": 1
                }
            ]
        }
    ]
}
`}
                        </pre>
                    </Grid>
                </Paper>

                <Paper className="storySection">
                    <Grid container justify="center" spacing={2} >
                        <div className="storyTitle">
                            <Typography variant="h5" fontWeight="bold">
                                Threshold Bar Chart
                            </Typography>
                        </div>
                    </Grid>
                    <Grid container justify="center" spacing={2} alignItems="stretch">
                        <pre>
                            {`
  <ThresholdBarChart 
    data={threshold} 
    colors={["#157DD8","#B00020"]} 
    axis={{x: "Number of Devices",y: "Date of Expiry"}} 
    width={900} 
    height={450} 
    threshold={Date.now()} 
    tooltip={"devices on this version:<br>"}
  ></ThresholdBarChart>                              
                            `}
                        </pre>
                    </Grid>
                    <Grid container justify="center" spacing={2} alignItems="stretch">
                        <ThresholdBarChart data={threshold}  colors={["#157DD8","#B00020"]} axis={{ x: "Number of Devices", y: "Date of Expiry" }} width={900} height={450} threshold={Date.now()} tooltip={"devices on this version:<br>"}></ThresholdBarChart>
                    </Grid>
                    <Grid container justify="center" spacing={2} >
                        <div className="description">
                            <Typography>
                                Example data for ThresholdBarChart:
                            </Typography>
                        </div>
                    </Grid>
                    <Grid container justify="center" spacing={2} >
                        <pre>
                            {`
[
    { "group": "Expired On", "label": "SQL 2005", "y": "2007-07-10", "x": 7 }, 
    { "group": "Expired On", "label": "SQL 2008", "y": "2010-04-13", "x": 13 }, 
    { "group": "Expired On", "label": "SQL 2005 SP3", "y": "2012-01-10", "x": 5 }, 
    { "group": "Expired On", "label": "SQL 2008 R2", "y": "2012-07-10", "x": 713 }, 
    { "group": "Expired On", "label": "SQL 2008 SP2", "y": "2012-10-09", "x": 22 }, 
    { "group": "Expired On", "label": "SQL 2008 R2 SP1", "y": "2013-10-08", "x": 1123 }, 
    { "group": "Expired On", "label": "SQL 2012", "y": "2014-01-14", "x": 142 }, 
    { "group": "Expired On", "label": "SQL 2012 SP1", "y": "2015-07-14", "x": 102 }, 
    { "group": "Expired On", "label": "SQL 2008 R2 SP2", "y": "2015-10-13", "x": 189 }, 
    { "group": "Expired On", "label": "SQL 2008 SP3", "y": "2015-10-13", "x": 9 }, 
    { "group": "Expired On", "label": "SQL 2005 SP4", "y": "2016-04-12", "x": 22 }, 
    { "group": "Expired On", "label": "SQL 2014", "y": "2016-07-12", "x": 7 }, 
    { "group": "Expired On", "label": "SQL 2012 SP2", "y": "2017-01-10", "x": 8425 }, 
    { "group": "Expired On", "label": "SQL 2014 SP1", "y": "2017-10-10", "x": 12 }, 
    { "group": "Expired On", "label": "SQL 2016", "y": "2018-01-09", "x": 67 }, 
    { "group": "Expired On", "label": "SQL 2012 SP3", "y": "2018-10-09", "x": 28175 }, 
    { "group": "Expired On", "label": "SQL 2008 R2 SP3", "y": "2019-07-09", "x": 327 }, 
    { "group": "Expired On", "label": "SQL 2016 SP1", "y": "2019-07-09", "x": 35423 }, 
    { "group": "Expired On", "label": "SQL 2008 SP4", "y": "2019-07-09", "x": 8 }, 
    { "group": "Expired On", "label": "SQL 2014 SP2", "y": "2020-01-14", "x": 11 }, 
    { "group": "Valid Until", "label": "SQL 2012 SP4", "y": "2022-07-12", "x": 535 }, 
    { "group": "Valid Until", "label": "SQL 2014 SP3", "y": "2024-07-09", "x": 136 }, 
    { "group": "Valid Until", "label": "SQL 2016 SP2", "y": "2026-07-14", "x": 13795 }, 
    { "group": "Valid Until", "label": "SQL 2017", "y": "2027-10-12", "x": 521 }
]
                            `}
                        </pre>
                    </Grid>
                    <Grid container justify="center" spacing={2} >
                        <Typography>
                            This component accepts numbers or dates in ISO format for Y values.
                            </Typography>
                    </Grid>
                </Paper>

                <Paper className="storySection">
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <div className="storyTitle">
                            <Typography variant="h5" fontWeight="bold">
                                StoryTable
                        </Typography>
                        </div>
                    </Grid>
                    <Grid container justifyContent='center'>
                        <div className="subText">
                            Additional Optional Arguments<br/>
                            rows = number of rows to display (default 5)<br/>
                            layout = can be ["as is"] to use JSON names, or can map JSON names to column names, like<br/>
                            <pre>
                            {`
                                const [layout] = useState([
                                    {column:"Original Name", field:"Original_Name"},
                                    {column:"JH Parsed Name", field:"JH_Parsed_Name"},
                                    {column:"Total Risk Score", field:"Total_Risk_Score"},
                                    {column:"BDLID Found", field:"BDLID_Found"}])
                            `}
                        </pre>
                        </div>
                    </Grid>
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <pre>
                            {`
<StoryTable
data={data}
layout={["as is"]}
rows={6}
></StoryTable>
                        `}
                        </pre>
                    </Grid>
                    <Grid container justify="center" spacing={2} >
                        <StoryTable
                            data={JSON.parse('[{"Original Name":"eravacycline 100 mg, 1 vial","JH Parsed Name":"Eravacycline 100 mg Powder for solution for injection, 1 each","Total Risk Score":6,"BDLID Found":"Y"},{"Original Name":"dulaglutide / 4.5 mg, 0.5 mL","JH Parsed Name":"Dulaglutide 4.5 mg/0.5 mL Solution for injection, 0.5 mL","Total Risk Score":6,"BDLID Found":"Y"},{"Original Name":"dulaglutide / 3 mg, 0.5 mL","JH Parsed Name":"Dulaglutide 3 mg/0.5 mL Solution for injection, 0.5 mL","Total Risk Score":6,"BDLID Found":"Y"},{"Original Name":"phenobarbital Inj 130 mg, 1 mL","JH Parsed Name":"PHENobarbital sodium 130 mg/1 mL Solution for injection, 1 mL","Total Risk Score":7,"BDLID Found":"Y"},{"Original Name":"PRAMIPEXOLE DIHYDROCHLORIDE 0.25 MG","JH Parsed Name":"Pramipexole Dihydrochloride 0.25 mg Oral solid, 1 each","Total Risk Score":9,"BDLID Found":"Y"},{"Original Name":"FLUOROURACIL 2500 MG/50 ML, 50 ML","JH Parsed Name":"Fluorouracil 50 mg/1 mL Solution for injection, 50 mL","Total Risk Score":9,"BDLID Found":"Y"}] ')}
                            layout={["as is"]}
                            rows={6} >
                        </StoryTable>
                    </Grid>
                    <Grid container justify="center" spacing={2} >
                        <div className="description">
                            <Typography>
                                Example data for StoryTable:
                    </Typography>
                        </div>
                    </Grid>
                    <Grid container justify="center" spacing={2} >
                        <pre>
                            {`
[
    {"Original Name":"eravacycline 100 mg, 1 vial","JH Parsed Name":"Eravacycline 100 mg Powder for solution for injection, 1 each","Total Risk Score":6,"BDLID Found":"Y"},
    {"Original Name":"dulaglutide / 4.5 mg, 0.5 mL","JH Parsed Name":"Dulaglutide 4.5 mg/0.5 mL Solution for injection, 0.5 mL","Total Risk Score":6,"BDLID Found":"Y"},
    {"Original Name":"dulaglutide / 3 mg, 0.5 mL","JH Parsed Name":"Dulaglutide 3 mg/0.5 mL Solution for injection, 0.5 mL","Total Risk Score":6,"BDLID Found":"Y"},
    {"Original Name":"phenobarbital Inj 130 mg, 1 mL","JH Parsed Name":"PHENobarbital sodium 130 mg/1 mL Solution for injection, 1 mL","Total Risk Score":7,"BDLID Found":"Y"},
    {"Original Name":"PRAMIPEXOLE DIHYDROCHLORIDE 0.25 MG","JH Parsed Name":"Pramipexole Dihydrochloride 0.25 mg Oral solid, 1 each","Total Risk Score":9,"BDLID Found":"Y"},
    {"Original Name":"FLUOROURACIL 2500 MG/50 ML, 50 ML","JH Parsed Name":"Fluorouracil 50 mg/1 mL Solution for injection, 50 mL","Total Risk Score":9,"BDLID Found":"Y"}
] 
`}
                        </pre>
                    </Grid>
                </Paper>

                <Paper className="storySection">
                    <Grid container justify="center" spacing={2} >
                        <div className="storyTitle">
                            <Typography variant="h5" fontWeight="bold">
                                US Choropleth
                            </Typography>
                        </div>
                    </Grid>
                    <Grid container justify="center" spacing={2} alignItems="stretch">
                        <pre>
                            {`
                         
                            `}
                        </pre>
                    </Grid>
                    <Grid container justify="center" spacing={2} alignItems="stretch">
                        <ChoroplethUS data={choropleth} counties={counties} colors={color.defaultScheme} />
                    </Grid>
                    </Paper>
            </div >
        )
    }
}

export default Example