import React, { useEffect, useRef, useState } from 'react'
import { Typography, Grid } from '@material-ui/core'
import { autoColor } from './AutoIcons'

function StoryTitle(props) {

    const [title, setTitle] = useState("story");
    const [date, setDate] = useState("Not Set");


    useEffect(() => {
        setDate(props.date)
        setTitle(props.title)
    },[props])

    return (
        <div>
            <Grid container justify="center">
            <div className="storyTitle">
                <Typography variant="h4">
                    {title}
                </Typography>
            </div>
            </Grid>
            <Grid container justify="center">
            <div>
                <Typography variant="subtitle2" color="textSecondary">
                    Metrics updated {(new Date(date)).toDateString()}
                </Typography>
            </div>
            </Grid>
            <Grid container justify="center">
            {props.categories ?
                props.categories.map((category) => {
                    return <div className="categoryText" style={{color: autoColor(category)}}>
                        {category}
                    </div>
                })
                : null
            }
            </Grid>
        </div>
    )
}

export default StoryTitle