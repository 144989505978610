import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import * as d3 from "d3"
import * as topojson from "topojson-client"

function ChoroplethUS(props) {
    const [divId] = useState("ChoroplethUS" + Math.floor(Math.random() * Math.floor(100000)))
    const [drawn, setDrawn] = useState(false)

    const drawChart = (data, us, width, height, tooltip, red, threshold) => {
        if (!drawn) {
            if (!threshold) {
                threshold = [1,10]
            }
            if (!width)
                width = 950
            if (!height)
                height = 620
            if (!tooltip)
                tooltip = "This cell contains: "

            let margin = ({ top: 10, right: 10, bottom: 10, left: 10 })

            let states = new Map(us.objects.states.geometries.map(d => [d.id, d.properties]))

            let path = d3.geoPath()
            
            let variation = d3.schemeBlues[9]
            if(red) {
                variation = d3.schemeReds[9]
            }
            let color = d3.scaleQuantize([threshold[0], threshold[1]], variation)

            // create a tooltip
            let Tooltip = d3.select(`#${divId}`)
                .append("div")
                .style("opacity", 0)
                .style('display','none')
                .attr("class", "tooltip")
                .style("background-color", "#323232")
                .style("border", "solid")
                .style("border-width", "2px")
                .style("border-radius", "5px")
                .style("padding", "5px")

            let mouseover = function (d, i) {
                Tooltip.style("opacity", 1)
                d3.select(this)
                    .style("stroke", "black")
                    .style("opacity", 1)
            }

            let mousemove = function (d, i) {
                let info = d.srcElement.__data__
                Tooltip
                    .html(`<b>${info.properties.name}, ${states.get(info.id.slice(0,2)).name}</b><br/>${tooltip} ${data.get(info.id) || 0}`)
                    .style('display','block')
                    .style("position", "absolute")
                    .style("left", `${d.pageX}px`)
                    .style("top", `${d.pageY + 25}px`)
            }

            let mouseleave = function (d, i) {
                Tooltip
                    .style("opacity", 0)
                d3.select(this)
                    .style("stroke", "none")
                    .style("opacity", 1)
            }

            let svg = d3.select(`#${divId}`)
                .append("svg")
                .attr("width", width + margin.left + margin.right)
                .attr("height", height + margin.top + margin.bottom)
                .attr("viewBox", [0, 0, width, height])

            svg.append("g")
                .selectAll("path")
                .data(topojson.feature(us, us.objects.counties).features)
                .join("path")
                .attr("fill", d => color(data.get(d.id)) || color(0))
                .attr("d", path)
                .on("mouseover", mouseover)
                .on("mousemove", mousemove)
                .on("mouseleave", mouseleave)

            svg.append("path")
                .datum(topojson.mesh(us, us.objects.states, (a, b) => a !== b))
                .attr("fill", "none")
                .attr("stroke", "black")
                .attr("stroke-linejoin", "round")
                .attr("d", path)

            setDrawn(true)
            return svg.node()
        }
   
    }

    useEffect(() => {
        if (props.data && props.counties) {
            drawChart(props.data, props.counties, props.width, props.height, props.tooltip, props.red, props.threshold)
        }
    }, [props])

    return (
        <div id={divId}></div>
    )
}

export default ChoroplethUS