import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import * as d3 from "d3"

function HeatMap(props) {
  const [drawn, setDrawn] = useState(false)
  const [divId, setDivId] = useState("heatMap")
  let history = useHistory()

  const drawChart = (data, w, h, colors, tooltip) => {
    if (!drawn) {
      if(!w) 
        w = 450
      if(!h)
        h = 450
      if (!tooltip)
        tooltip = "This cell contains: "

      let parent = d3.select(`#${divId}`)

      // set the dimensions and margins of the graph
      let margin = { top: 20, right: 25, bottom: 30, left: 40 }
      let width = w - margin.left - margin.right
      let height = h - margin.top - margin.bottom

      let myGroups = []
      let myVars = []

      data.forEach(member => {
        if (!myGroups.includes(member.group)) {
          myGroups.push(member.group)
        }
        if (!myVars.includes(member.variable)) {
          myVars.push(member.variable)
        }
      })

      // append the svg object to the body of the page
      let svg = parent
        .append("svg")
        .attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom)
        .append("g")
        .attr("transform",
          "translate(" + margin.left + "," + margin.top + ")");

      // Build X scales and axis:
      let x = d3.scaleBand()
        .range([0, width])
        .domain(myGroups)
        .padding(0.05)

      svg.append("g")
        .style("font-size", 15)
        .attr("transform", "translate(0," + height + ")")
        .call(d3.axisBottom(x).tickSize(0))
        .select(".domain").remove()

      // Build Y scales and axis:
      let y = d3.scaleBand()
        .range([height, 0])
        .domain(myVars)
        .padding(0.05)

      svg.append("g")
        .style("font-size", 15)
        .call(d3.axisLeft(y).tickSize(0))
        .select(".domain").remove()

      // Build color scale
      let myColor = d3.scaleSequential()
        .interpolator(d3.piecewise(d3.interpolateRgb.gamma(2.2), colors))
        .domain([1, 100])

      // create a tooltip
      let Tooltip = d3.select(`#${divId}`)
        .append("div")
        .style("opacity", 0)
        .style('display','none')
        .attr("class", "tooltip")
        .style("background-color", "#323232")
        .style("border", "solid")
        .style("border-width", "2px")
        .style("border-radius", "5px")
        .style("padding", "5px")

      // Three function that change the tooltip when user hover / move / leave a cell
      let mouseover = function (d) {
        Tooltip.style("opacity", 1)
        d3.select(this)
          .style("stroke", "white")
          .style("opacity", 1)
      }

      let mousemove = function (d) {
        Tooltip
          .html(tooltip + d.srcElement.__data__.value)
          .style('display','block')
          .style("position", "absolute")
          .style("left", `${d.pageX}px`)
          .style("top", `${d.pageY + 25}px`)
      }

      let mouseleave = function (d) {
        Tooltip
          .style("opacity", 0)
        d3.select(this)
          .style("stroke", "none")
          .style("opacity", 0.8)
      }

      // add the squares
      svg.selectAll()
        .data(data, function (d) { return d.group + ':' + d.variable })
        .enter()
        .append("rect")
        .attr("x", function (d) { return x(d.group) })
        .attr("y", function (d) { return y(d.variable) })
        .attr("rx", 4)
        .attr("ry", 4)
        .attr("width", x.bandwidth())
        .attr("height", y.bandwidth())
        .style("fill", function (d) { return myColor(d.value) })
        .style("stroke-width", 4)
        .style("stroke", "none")
        .style("opacity", 0.8)
        .on("mouseover", mouseover)
        .on("mousemove", mousemove)
        .on("mouseleave", mouseleave)

      setDrawn(true)
    }
  }

  useEffect(() => {
    if (props.data) {
      drawChart(props.data, props.width, props.height, props.colors, props.tooltip)
    }
  }, [props])

  return (
    <div id={divId}></div>
  )
}

export default HeatMap