
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { API } from 'aws-amplify'
import StoryTitle from '../components/StoryTitle'
import StoryTable from '../components/StoryTable'
import color from '../shared/color'
import { DonutChart, BubbleChart } from '../d3'
import { Typography, Grid, CircularProgress, Paper } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import '../story.css'

function PatchTracking() {
 
    const [title] = useState("Patch Tracking")
    const [summary] = useState("The current state of Windows and SQL Server security patches distributed across the fleet of ES stations and servers amongst our connected customers.")
    const [categories] = useState(["security","microsoft"])
    const [isLoading, setIsLoading] = useState(true)
    const [data, setData] = useState(null)
    const [facilityLayout] = useState([
        {column:"Facility", field:"facility_name"},
        {column:"Customeer Name", field: "customer_name"},
        {column:"Managed Services",field:"Managed_Service"},
        {column:"Days Behind",field:"facility_Patch Range"},
    ])
    const [deviceLayout] = useState([
        {column:"DeviceId", field:"deviceid"},
        {column:"Device Name", field: "device_name"},
        {column:"Managed Services",field:"Managed_Service"},
        {column:"Days Behind",field:"Patch Range"},
    ])
    const [bubbleColor, setBubColor] = useState(null)
    const useStyles = makeStyles({
        table: {
            minWidth: 650,
        },
    })

    let history = useHistory()

    useEffect(() => {
        setBubColor(new Map([
            ["Scheduled_with_permission", "#4e79a7"],
            ["Customer_Managed", "#f28e2c"],
            ["Scheduled_wo_permission", "#59a14f"]
          ]))
        async function fetch() {
            await API.get('qs-web-api', '/data/patching_monitoring/donut_data.json', {})
                .then((storyData) => {
                    setData(storyData)
                })
                .catch(function (error) {
                    console.log(error)
                })
            setIsLoading(false)
        }
        fetch()
    }, [])

    if (isLoading) {
        return (<div className="circular-progress"><CircularProgress disableShrink /></div>)
    } else {
        return (
            <div id="story">
                <StoryTitle title={title} date={data.date} categories={categories}></StoryTitle>

                <Paper className="storySection">
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <div className="subText">
                            <Typography >
                                The current state of Windows operating system and SQL Server patches distributed across the fleet of
                                ES stations and servers amongst our connected customers.  These patches are critical for the security and functionality of our
                                products.  They can directly impact many aspects of how our customers user experience and data security is delivered
                                within this product line.
                                <p>
                                Note, while this is the patching age the record is the last successful patch and does not track how many patches are not succeeding.
                                </p>
                                <li><b>2 weeks current</b> means at least one patch has been successfully installed within the last 14 days.</li>
                                <li><b>Month current</b> indicates at least one patch was successfully installed in the last 30 days.</li>
                                <li><b>3 month lag</b> means no patches have been installed within the last 30 days and may be as far as 90 days without patching.</li>
                                <li><b>Over 3 month</b> means that its been more than 90 days since any patches were applied to the system.</li>
                        </Typography>
                        </div>
                    </Grid>
                </Paper>
                
                <Paper className="storySection">
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <div className="description">
                            <Typography variant="h6">
                                All Devices
                            </Typography>
                        </div>
                    </Grid>
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <DonutChart data={data.donutAll} colors={color.defaultScheme} tooltip={"Number of ES Systems <br> in this time range: <br>"} width={800} height={400} radius={200}></DonutChart>
                    </Grid>
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <div className="subText">
                            <Typography >
                                This is a collection of all device types.
                                Collected patch data from RSS connected devices of at least one patch succeeding
                                within predefined time windows across the entire device population.
                        </Typography>
                        </div>
                    </Grid>
                </Paper>

                <Paper className="storySection">
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <div className="description">
                            <Typography variant="h6">
                                BD Managed Devices
                        </Typography>
                        </div>
                    </Grid>
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <DonutChart data={data.donutManaged} colors={color.defaultScheme} tooltip={"Number of devices: <br> "} width={800} height={400} radius={200}></DonutChart>
                    </Grid>
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <div className="subText">
                            <Typography>
                                BD managed devices are systems where BD has approval to reboot the device to apply patches.
                                Collected patch data from BD managed RSS connected devices of at least
                                one patch succeeding within predefined time windows across the managed population.
                            </Typography>
                        </div>
                    </Grid>
                </Paper>

                <Paper className="storySection">
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <div className="description">
                            <Typography variant="h6">
                                Customer Managed Devices
                            </Typography>
                        </div>
                    </Grid>
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <DonutChart data={data.donutUnmanaged} colors={color.defaultScheme} tooltip={"Number of devices: <br> "} width={800} height={400} radius={200}></DonutChart>
                    </Grid>
                    <Grid container justify="center" spacing={2} alignItems="stretch">
                        <div className="subText">
                            <Typography >
                                Customer Managed devices are controlled by the customer and cannot be patched by BD systems.
                                Collected patch data from RSS connected devices of at least one patch succeeding within
                                    predefined time windows across the unmanaged device population.
                            </Typography>
                        </div>
                    </Grid>
                </Paper>

                <Paper className="storySection">
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <div className="storyTitle">
                            <Typography variant="h5" fontWeight="bold">
                                Top 100 Least Patched Facilities
                        </Typography>
                        </div>
                    </Grid>
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <BubbleChart data={data.bubbleFacilityAgePatchSize.data} scale={data.bubbleFacilityAgePatchSize.scale} colors={bubbleColor} tooltip={"Total devices:<br>"} width={750} height={550}></BubbleChart>
                    </Grid>
                    <Grid container justify="center" spacing={2} alignItems="stretch">
                        <div className="subText">
                            <Typography>
                                The worst 100 facilities with patching that have been patched atleast oce, as there are {data.bubbleFacilityAgePatchSize.metaData.customValueUnpatchDevices} devices that have no records of patching.
                                While the age of the device does not inherently determine the patching age, older devices have a larger range. The patching age average is {data.bubbleFacilityAgePatchSize.metaData.customValueUnpatchDevicesMean} days and median is {data.bubbleFacilityAgePatchSize.metaData.customValueUnpatchDevicesMedian} days.
                            </Typography>
                        </div>
                    </Grid>
                </Paper>

                <Paper className="storySection">
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <div className="description">
                            <Typography variant="h6">
                                The top 5 ship-to facilities most out of compliance
                            </Typography>
                        </div>
                    </Grid>
                    <StoryTable data={data.facilityPatchAll} layout={facilityLayout}></StoryTable>
                </Paper>    

                <Paper className="storySection">
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <div className="description">
                            <Typography variant="h6">
                                The top 5 Managed ship-to facilities most out of compliance
                            </Typography>
                        </div>
                    </Grid>
                    <StoryTable data={data.facilityManaged} layout={facilityLayout}></StoryTable>
                </Paper>

                <Paper className="storySection">
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <div className="description">
                            <Typography variant="h6">
                                The top 5 Unmanaged ship-to facilities most out of compliance
                        </Typography>
                        </div>
                    </Grid>
                    <StoryTable data={data.facilityUnmanaged} layout={facilityLayout}></StoryTable>
                </Paper>

                <Paper className="storySection">
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <div className="description">
                            <Typography variant="h6">
                                The top 5 devices most out of compliance
                        </Typography>
                        </div>
                    </Grid>
                    <StoryTable data={data.deviceAll} layout={deviceLayout}></StoryTable>
                </Paper>

                <Paper className="storySection">
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <div className="description">
                            <Typography variant="h6">
                                The top 5 Managed devices most out of compliance
                        </Typography>
                        </div>
                    </Grid>
                    <StoryTable data={data.deviceManaged} layout={deviceLayout}></StoryTable>
                </Paper>

                <Paper className="storySection">
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                        <div className="description">
                            <Typography variant="h6">
                                The top 5 Unmanaged devices most out of compliance
                            </Typography>
                        </div>
                    </Grid>
                    <StoryTable data={data.deviceUnmanaged} layout={deviceLayout}></StoryTable>
                </Paper>
            </div >
        )
    }
}

export default PatchTracking