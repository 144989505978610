import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { API } from 'aws-amplify'
import color from '../shared/color'
import StoryTitle from '../components/StoryTitle'
import StoryTable from '../components/StoryTable'
import {ChoroplethUS, DonutChart, ScatterPlot, ThresholdBarChart} from '../d3'
import { Typography, Divider, Grid, CircularProgress, Paper} from '@material-ui/core'
import '../story.css'

function WinEndOfLife() {

    const [title] = useState("Windows OS End of Life")
    const [summary] =  useState("The current state of our Windows Versions installed on all connected customer MMS Devices and their support status.")
    const [categories] = useState(["microsoft"])
    const [isLoading, setIsLoading] = useState(true)
    const [data, setData] = useState({})
    const [table1Layout, setTable1Layout] = useState(null)
    const [table2Layout, setTable2Layout] = useState(null)
    const [table3Layout, setTable3Layout] = useState(null)
    const [counties, setCounties] = useState(null)
    const [Choroplath, setChoroplath] = useState(null)
    
    let history = useHistory()

    useEffect(() => {
        setTable1Layout([
            {column:"Windows OS Support", field:"Validity"},
            {column:"Total Devices", field: "DeviceCounts"},
            {column:"% of Total Devices",field:"%ofTotalDevices"}
        ])
        setTable2Layout([
            {column:"Windows OS Version", field:"WindowsOS"},
            {column:"Extended Support End Date", field:"ExtendedSupportEndDate"},
            {column:"Total Devices", field: "#ofDevices"},
            {column:"% of Total Devices",field:"%ofDevices"}
        ])
        setTable3Layout([
            {column:"Microsoft Windows Support", field:"MicrosoftWinOSSupport"},
            {column:"% of Med ES Stations", field: "%ofMedESStations"},
            {column:"% of PAS ES Devices",field:"%ofPASDevices"},
            {column:"% of PLX Servers",field:"%ofPLXServers"},
        ])
        async function fetch() {
            //Read the data
            await API.get('qs-web-api', '/data/winendoflife/winendoflife.json', {})
                .then((storyData) => {
                    setData(storyData)
                })
                .catch(function (error) {
                    console.log(error)
                })
            await API.get('qs-web-api', '/data/home/counties-albers-10m.json', {})
                .then((storyData) => {
                    setCounties(storyData)
                })

            await API.get('qs-web-api', '/data/winendoflife/winendoflife.json', {})
                .then((storyData) => {
                    setChoroplath(new Map(storyData.winmap))
                })
                .catch(function (error) {
                    console.log(error)
                })
                setIsLoading(false)
        }
        fetch()
    }, [])
    if (isLoading) {
        return (<div className="circular-progress"><CircularProgress disableShrink /></div>)
    } else {
        return (
            <div id="story">
                <StoryTitle title={title} date={data.date} categories={categories}></StoryTitle>
                <Paper className="storySection">
                    <Grid container justify="center" spacing={2} alignItems="stretch">
                        <div className="subText">
                            <Typography>
                                Microsoft supports Windows Operating System throughout the life of a product. The end of life (EOL) depends on the OS release version.
                                This article focuses on tracking the Windows OS Version installed in all the MMS Devices and their EOL.
                                All the Online, Live, Production, MMS Devices and Servers are taken into consideration.<p></p>
                                Few devices' operating system build version and service pack information are unavailable in RSS.
                                In such cases, if the support end date is same across all release versions, then version is defaulted to original release version.
                                If the support end dates differ across versions and the version information is unavailable then they are marked unknown.
                                Across all MMS Devices and Servers such unknown operating system reported in this article is less than 0.1%
                            </Typography>
                        </div>
                    </Grid>
                </Paper>
                <Paper className="storySection">
                    <Grid container justify="center" spacing={2} alignItems="stretch">
                        <div className="description">
                            <Typography variant="h6" fontWeight="bold">
                                MMS Device and Server Counts based on their Windows End of Life Support
                            </Typography>
                        </div>
                    </Grid>
                    <Grid container justify="center" spacing={2} alignItems="stretch">
                        <ThresholdBarChart data={data.stackedbarEOLdrill} colors={["#157DD8","#B00020"]} axis={{x: "Number of Devices",y: "Date of Expiry"}} width={900} height={450} threshold={Date.now()} tooltip={"Devices on this version:<br>"}></ThresholdBarChart>
                    </Grid>
                    <Grid container justify="center" spacing={2} alignItems="stretch">
                        <div className="subText">
                            <Typography>
                                Date of Expiry of Windows Operating System is the last date of extended product support end date from Microsoft.
                                Majority of the MMS Devices use Windows Embedded Standard (Win 7) operating system with service pack 1,
                                whose product support from Microsoft has ended on 2020-10-13.
                            </Typography>
                        </div>
                    </Grid>
                </Paper>
                <Paper className="storySection">
                    <Grid container justify="center" spacing={2} alignItems="stretch">
                        <div className="description">
                            <Typography variant="h6" fontWeight="bold">
                                Overall Compliance Status of Windows OS in MMS Devices and Servers
                            </Typography>
                        </div>
                    </Grid>
                    <StoryTable data={data.tableCompliance} layout={table1Layout}></StoryTable>
                    <Grid container justify="center" spacing={2} alignItems="stretch">
                        <div className="subText">
                            <Typography>
                                All the types of MMS Devices, that are marked online, not-deleted, and with live production status
                                in RSS are taken into consideration.
                            </Typography>
                        </div>
                    </Grid>
                </Paper>
                <Paper className="storySection">
                    <Grid container justify="center" spacing={2} alignItems="stretch">
                        <div className="description">
                            <Typography variant="h6" fontWeight="bold">
                                Counts per Windows OS Version (Only Non-Compliant)
                            </Typography>
                        </div>
                    </Grid>
                    <Grid container justify="center" spacing={2} alignItems="stretch">
                        <DonutChart id={1} data={data.donutExpiredCounts} colors={color.defaultScheme} tooltip={"Number of Devices: "}
                            width={950} height={400} radius={200}></DonutChart>
                    </Grid>
                    <Grid container justify="center" spacing={2} alignItems="stretch">
                        <div className="subText">
                            <Typography>
                                Only Microsoft Windows OS that have expired support from Microsoft is shown in the above chart.
                                Windows XP Service Pack 3 is the second highest non-compliant Windows OS found in MMS devices.
                            </Typography>
                        </div>
                    </Grid>
                </Paper>
                <Paper className="storySection">
                    <Grid container justify="center" spacing={2} alignItems="stretch">
                        <div className="description">
                            <Typography variant="h6" fontWeight="bold">
                                Percentage of devices per Windows OS Version (all types of MMS Devices and Servers)
                            </Typography>
                        </div>
                    </Grid>
                    <StoryTable data={data.tableGeneralScores} layout={table2Layout}></StoryTable>
                    <Grid container justify="center" spacing={2} alignItems="stretch">
                        <div className="subText">
                            <Typography>
                                To maintain transparency in the reporte data, a Windows OS version is marked unknown when the device
                                operating system information and operating system service pack information is unavailable in RSS.
                            </Typography>
                        </div>
                    </Grid>
                </Paper>
                <Paper className="storySection">
                <Grid container justify="center" spacing={2} alignItems="stretch">
                    <div className="description">
                        <Typography variant="h6" fontWeight="bold">
                            Focusing Win EOL Compliance on MED ES Station, PAS-ES Station and PLX Servers
                        </Typography>
                    </div>
                </Grid>
                <StoryTable data={data.tableESPASPLX} layout={table3Layout}></StoryTable>
                </Paper>
                <Paper className="storySection">
                    <Grid container justify="center" spacing={2} alignItems="stretch">
                        <div className="description">
                            <Typography variant="h6" fontWeight="bold">
                                MED ES Station Windows Compliance by Device Version
                            </Typography>
                        </div>
                    </Grid>
                    <Grid container justify="center" spacing={2} alignItems="stretch">
                        <DonutChart id={1} data={data.donutES} colors={color.defaultScheme} tooltip={"Number of Devices: "}
                            width={800} height={400} radius={200}></DonutChart>
                    </Grid>
                   <Grid container justify="center" spacing={2} alignItems="stretch">
                        <div className="subText">
                            <Typography>
                                Med ES Station devices have both compliant and non-compliant windows operating system, irrespective
                                of the device version.
                            </Typography>
                        </div>
                    </Grid>
                </Paper>
                <Paper className="storySection">
                    <Grid container justify="center" spacing={2} alignItems="stretch">
                        <div className="description">
                            <Typography variant="h6" fontWeight="bold">
                                PAS ES Windows Compliance by Device Version
                            </Typography>
                        </div>
                    </Grid>
                    <Grid container justify="center" spacing={2} alignItems="stretch">
                        <DonutChart id={1} data={data.donutPAS} colors={color.defaultScheme} tooltip={"Number of Devices: "}
                            width={800} height={400} radius={200}></DonutChart>
                    </Grid>
                   <Grid container justify="center" spacing={2} alignItems="stretch">
                        <div className="subText">
                            <Typography>
                                Across all the PAS Marketing Device Versions, PAS Devices have both Compliant and Non-Compliant Windows Operating System.
                            </Typography>
                        </div>
                    </Grid>
                </Paper>
                <Paper className="storySection">
                    <Grid container justify="center" spacing={2} alignItems="stretch">
                        <div className="description">
                            <Typography variant="h6" fontWeight="bold">
                                PLX Servers Windows Compliance by Version
                            </Typography>
                        </div>
                    </Grid>
                    <Grid container justify="center" spacing={2} alignItems="stretch">
                        <DonutChart id={1} data={data.donutPLX} colors={color.defaultScheme} tooltip={"Number of Servers: "}
                            width={900} height={400} radius={200}></DonutChart>
                    </Grid>
                   <Grid container justify="center" spacing={2} alignItems="stretch">
                        <div className="subText">
                            <Typography>
                                Almost all the Legacy devices are non-compliant.
                                Device Version is identified based on the device application version.
                                In cases when the application information is not available, the version found in RSS is directly used.
                                A version is marked unknown, when neither of the 2 values are available, but the device type is found to label it either ES or PAS or PLX.
                                Data source is RSS.
                            </Typography>
                        </div>
                    </Grid>
                </Paper>
                <Paper className="storySection">
                    <Grid container justify="center" spacing={2} alignItems="stretch">
                        <div className="description">
                            <Typography variant="h6" fontWeight="bold">
                                Win 7 Population across US in all MMS Devices
                            </Typography>
                        </div>
                    </Grid>
                    <Grid container justify="center" spacing={2} alignItems="stretch">
                        <ChoroplethUS data={Choroplath} counties={counties} red={true} threshold={[0,25]} />
                    </Grid>
                   <Grid container justify="center" spacing={2} alignItems="stretch">
                        <div className="subText">
                            <Typography>
                                The Map shows the count of all Live MMS Devices with Win 7 OS across US per county. Darker the color, higher the counts.
                                Hover over the map to know the number of Win 7 devices in each county.
                            </Typography>
                        </div>
                    </Grid>
                </Paper>
                <Paper className="storySection">
                    <Grid container justify="center" spacing={2} alignItems="stretch">
                        <div className="description">
                            <Typography variant="h6" fontWeight="bold">
                                Counts Trend of Med ES Stations with Windows 7 OS
                            </Typography>
                        </div>
                    </Grid>
                   <Grid container justify="center" spacing={2} alignItems="stretch">
                        <div className="subText">
                            <Typography>
                                In the current trend of reduction in Win 7 OS, about {data.esdeviceperday} Med ES stations are upgraded per day.
                                At this rate, the upgrade will be completed on {data.esendate}
                            </Typography>
                        </div>
                    </Grid>
                    <Grid container justify="center">
                        <ScatterPlot data={data.scatterESCounts} axis={{x: "Data Extracted Date", y: "# of Med ES Stations"}}
                                     width={900} height = {450} xType={"date"} yRange = {data.esyrange} tooltip = {"Number of Med ES Stations: "}
                                     colors = {["#157DD8","#90ee90"]}></ScatterPlot>
                    </Grid>
                   <Grid container justify="center" spacing={2} alignItems="stretch">
                        <div className="subText">
                            <Typography>
                                All the trend includes counts of all online devices with production status - live, test and unspecified.
                                Whereas the rest of the article reports only on live production status.
                            </Typography>
                        </div>
                    </Grid>
                </Paper>
                <Paper className="storySection">
                    <Grid container justify="center" spacing={2} alignItems="stretch">
                        <div className="description">
                            <Typography variant="h6" fontWeight="bold">
                                Counts Trend of PAS Stations with Windows 7 OS
                            </Typography>
                        </div>
                    </Grid>
                   <Grid container justify="center" spacing={2} alignItems="stretch">
                        <div className="subText">
                            <Typography>
                                In the current trend of reduction in Win 7 OS, about {data.pasdeviceperday} PAS Stations are upgraded per day.
                                At this rate, the upgrade will be completed on {data.pasendate}
                            </Typography>
                        </div>
                    </Grid>
                    <Grid container justify="center">
                        <ScatterPlot data={data.scatterPASCounts} axis={{x: "Data Extracted Date", y: "# of PAS Stations"}}
                                     width={900} height = {450} xType={"date"} yRange = {data.pasyrange} tooltip = {"Number of PAS Stations: "}
                                     colors = {["#157DD8","#90ee90"]}></ScatterPlot>
                    </Grid>
                   <Grid container justify="center" spacing={2} alignItems="stretch">
                        <div className="subText">
                            <Typography>
                                The trend includes counts of all online PAS stations with production status - live, test and unspecified.
                                Whereas the rest of the article reports only on live production status.
                            </Typography>
                        </div>
                    </Grid>
                </Paper>

                <Paper className="storySection">
                    <Grid container justify="center" spacing={2} alignItems="stretch">
                        <div className="description">
                            <Typography variant="h6" fontWeight="bold">
                                Counts Trend of Supply Station with Windows 7 OS
                            </Typography>
                        </div>
                    </Grid>
                    <Grid container justify="center">
                        <ScatterPlot data={data.scatterSupplyCounts} axis={{x: "Data Extracted Date", y: "# of Supply Stations"}}
                                     width={900} height = {450} xType={"date"} yRange = {data.supplyyrange} tooltip = {"Number of Supply Devices: "}
                                     colors = {["#157DD8","#90ee90"]}></ScatterPlot>
                    </Grid>
                     <Grid container justify="center" spacing={2} alignItems="stretch">
                            <div className="subText">
                                <Typography>
                                    Supply Stations are identified if their RSS device type is one of the following: 'Supply 10 SupplyCenter Client',
                                    'Supply 10 SupplyStation','Supply 8 SupplyCenter Client','Supply 8 SupplyStation','Supply 9 SupplyCenter Client','Supply 9 SupplyStation'
                                    <p></p>
                                    In the current trend of reduction in Win 7 OS, about {data.supplydeviceperday} Supply Stations are upgraded per day.
                                    At this rate, the upgrade will be completed on {data.supplyendate}
                                </Typography>
                            </div>
                        </Grid>
                </Paper>
            </div>
        )
    }
}
export default WinEndOfLife
