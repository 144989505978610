import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { API } from 'aws-amplify'
import color from '../shared/color'
import StoryTitle from '../components/StoryTitle'
import StoryTable from '../components/StoryTable'
import {ChoroplethUS, DonutChart, ScatterPlot, ThresholdBarChart, BarChart} from '../d3'
import { Typography, Divider, Grid, CircularProgress, Paper} from '@material-ui/core'
import '../story.css'

function StationStability() {

    // const [title] = useState("Station Stability Comparison of Win 7 and 10")
    // const [summary] = useState("Stability of Med ES and PAS Stations based on reboots with respect to Win 7 and Win 10 ")
    // const [categories] = useState(["microsoft", "reboot"])
    const [isLoading, setIsLoading] = useState(true)
    const [table1Layout, setTable1Layout] = useState(null)
    const [table2Layout, setTable2Layout] = useState(null)
    const [data, setData] = useState({})
    useEffect(() => {
       setTable1Layout([
            {column:"Marketing Version", field:"MarketingVersion"},
            {column:"Reboots Count", field: "Numberofnonsafereboots"},
            {column:"Devices Count",field:"Numberofdevices"},
            {column:"Reboot per device in a month",field:"Avgrebootperdevice"},
        ])
        setTable2Layout([
            {column:"Facility Name", field:"Facility Name"},
            {column:"Device Name", field: "Device Name"},
            {column:"Device OS",field:"Device OS"},
            {column:"Is Patched?",field:"Is Patched?"},
            {column:"Device Version",field:"Device Version"},
            {column:"Number of Reboots",field:"Number of Reboots"}
        ])
            async function fetch() {
                //Read the data
                await API.get('qs-web-api', '/data/stationstability/stationstability1.json', {})
                    .then((storyData) => {
                        setData(storyData)
                    })
                    .catch(function (error) {
                        console.log(error)
                    })
                setIsLoading(false)
        }
        fetch()
    }, [])
    if (isLoading) {
        return (<div className="circular-progress"><CircularProgress disableShrink /></div>)
    } else {
        return (
            <div id="story">
                {/* <StoryTitle title={title} date={data.date} categories={categories}></StoryTitle> */}
                <Paper className="storySection">
                        <Grid container justify="center" spacing={2} alignItems="stretch">
                            <div className="subText">
                                <Typography>
                                    Station stability is determined by the number of times a device reboots. This story focuses
                                    on Med ES and PAS Stations with Windows 7 and Windows 10 Operating System, and the stability
                                    after patching.<p></p> Here a patched device is a device with "Port Speed Reduction - 100Mb Half-Duplex" package installed in it.
                                    <p></p> Reboots are categorized into three types: <li><b>Normal Shutdown</b> - Regular Shell Task,
                                    Proper Maintenance Shutdown, Relaunch, Dashboard Package and Software Update</li><li><b>
                                    Possibly an Issue</b> - Power Switch Flip, Power Loss</li><li><b>Unknown </b>
                                     - Reboots that do not have enough log information to determine if it is a safe or unsafe. This
                                    ensures no 'possible issue' scenario is overlooked</li><p></p>All reboots in this story are tracked for
                                    a period of past one month (or one year in few mentioned graphs), and refreshed every Monday with latest
                                    reboots from previous week. The reboot counts
                                    mentioned in the story by default does not include Type 1, Normal Shutdowns.
                                </Typography>
                            </div>
                        </Grid>
                    </Paper>
               <Paper className="storySection">
                    <Grid container justify="center" spacing={2} alignItems="stretch">
                        <div className="description">
                            <Typography variant="h6" fontWeight="bold">
                                Reboots Across all Device Versions irrespective of patching
                            </Typography>
                        </div>
                    </Grid>
                     <Grid container justify="center" spacing={2} alignItems="stretch">
                            <div className="subText">
                                <Typography>
                                    The table shows the top 3 Versions with maximum device counts, and the total number of reboots across the devices
                                    in the each version.
                                </Typography>
                            </div>
                        </Grid>
                        <StoryTable data={data.TABLEcountsbyversion} layout={table1Layout}></StoryTable>

                </Paper>


               <Paper className="storySection">
                    <Grid container justify="center" spacing={2} alignItems="stretch">
                        <div className="description">
                            <Typography variant="h6" fontWeight="bold">
                                Average Reboot Per day for 1000 devices of Win 10 - Patched and Unpatched
                            </Typography>
                        </div>
                    </Grid>
                    <Grid container justify="center">
                        <BarChart data = {[{"name":"Before Patch Win 10", "value":42}, {"name":"After Patch Win 10", "value":25},
                                            {"name":"Windows 7", "value":52}]}
                        tooltip = {"Average Value: "} axis={{x: "", y: "Average Reboots Per Day Per 1000 Devices"}}
                        >
                        </BarChart>
                    </Grid>
                     <Grid container justify="center" spacing={2} alignItems="stretch">
                            <div className="subText">
                                <Typography>
                                    All the Unknown and Possible Issue reboots since June 2020 are taken into consideration.
                                </Typography>
                            </div>
                        </Grid>
                </Paper>
               <Paper className="storySection">
                    <Grid container justify="center" spacing={2} alignItems="stretch">
                        <div className="description">
                            <Typography variant="h6" fontWeight="bold">
                                Med ES Stations (1.6.1) - Reboot counts trend of Win 7 and Win 10 Stations
                            </Typography>
                        </div>
                    </Grid>
                    <Grid container justify="center">
                        <ScatterPlot data={data.SCATTERperdaycountMedES} axis={{x: "Date of Reboot", y: "Number of Reboots"}}
                                     width={900} height = {450} xType={"date"} tooltip = {"Number of Reboots: "}
                                     colors = {["#157DD8","#90ee90", "#f28e2c"]}></ScatterPlot>
                    </Grid>
                    <Grid container justify="center" spacing={2} alignItems="stretch">
                        <div className="description">
                            <Typography variant="h6" fontWeight="bold">
                                Med ES Stations (1.6.1) - Average Reboot counts trend per 1000 devices (normalized)
                            </Typography>
                        </div>
                    </Grid>
                    <Grid container justify="center">
                        <ScatterPlot data={data.SCATTERperdayavgMedES} axis={{x: "Date of Reboot", y: "Number of Reboots"}}
                                     width={900} height = {450} xType={"date"} tooltip = {"Number of Reboots: "}
                                     colors = {["#157DD8","#90ee90", "#f28e2c"]}></ScatterPlot>
                    </Grid>
                     <Grid container justify="center" spacing={2} alignItems="stretch">
                            <div className="subText">
                                <Typography>
                                    Type 1 non-issue reboots are not included in the above chart. The reboots are shown
                                    for the past one month. Reboot frequency depends also
                                    on the day of the week, generally Sundays have lesser reboots than working days.
{/*                                    <p></p>Windows 10 devices tend to reboot more often than Windows 7 devices.
                                    Difference in total Windows 10 and 7 devices is not significant to influence the
                                    reboot trend. There are about 56K Win 10 and 51K Win 7, online live stations in RSS.*/}
                                </Typography>
                            </div>
                        </Grid>
                </Paper>
                <Paper>
                    <Grid container justify="center" spacing={2} alignItems="stretch">
                        <div className="description">
                            <Typography variant="h6" fontWeight="bold">
                                Med PAS Stations (1.6.1) - Reboot counts trend of Win 7 and Win 10
                            </Typography>
                        </div>
                    </Grid>
                    <Grid container justify="center">
                        <ScatterPlot data={data.SCATTERperdaycountMedPAS} axis={{x: "Date of Reboot", y: "Number of Reboots"}}
                                     width={900} height = {450} xType={"date"} tooltip = {"Number of Reboots: "}
                                     colors = {["#157DD8","#90ee90", "#f28e2c"]}></ScatterPlot>
                    </Grid>
                    <Grid container justify="center" spacing={2} alignItems="stretch">
                        <div className="description">
                            <Typography variant="h6" fontWeight="bold">
                                Med PAS Stations (1.6.1) - Average Reboot counts trend per 1000 devices (normalized)
                            </Typography>
                        </div>
                    </Grid>
                    <Grid container justify="center">
                        <ScatterPlot data={data.SCATTERperdayavgMedPAS} axis={{x: "Date of Reboot", y: "Number of Reboots"}}
                                     width={900} height = {450} xType={"date"} tooltip = {"Number of Reboots: "}
                                     colors = {["#157DD8","#90ee90", "#f28e2c"]}></ScatterPlot>
                    </Grid>
                </Paper>
{/* <Paper className="storySection">
                   <Grid container justify="center" spacing={2} alignItems="stretch">
                      <div className="description">
                            <Typography variant="h6" fontWeight="bold">
                                Average Reboot Per day Per device of Win 7 and Win 10 irrespective of patch status
                           </Typography>
                        </div>
                    </Grid>
                    <Grid container justify="center">
                        <ScatterPlot data={data.SCATTERperdayperdevicecount} axis={{x: "Date of Reboot", y: "Average Reboots"}}
                                     width={900} height = {450} xType={"date"} tooltip = {"Avg Reboots per day per device: "}
                                     colors = {["#157DD8","#90ee90"]}></ScatterPlot>
                    </Grid>
               </Paper>
*/}
                <Paper className="storySection">
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                    <div className="description">
                        <Typography variant="h6">
                            Top 5 Unpatched Win 10 Stations with maximum reboots in one month
                        </Typography>
                    </div>
                </Grid>
                <StoryTable data={data.TABLEnopkgwin10} layout={table2Layout}></StoryTable>
                </Paper>
                <Paper className="storySection">
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                    <div className="description">
                        <Typography variant="h6">
                            Top 5 Patched Win 10 Stations with maximum reboots in one month
                        </Typography>
                    </div>
                </Grid>
                <StoryTable data={data.TABLEpkgwin10} layout={table2Layout}></StoryTable>
                </Paper>
                <Paper className="storySection">
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                    <div className="description">
                        <Typography variant="h6">
                            Top 5 Win 7 Stations with maximum reboots in one month
                        </Typography>
                    </div>
                    </Grid>
                    <StoryTable data={data.TABLEwin7} layout={table2Layout}></StoryTable>
                </Paper>
 {/*               <Paper className="storySection">
                    <Grid container justify="center" spacing={2} alignItems="stretch" >
                    <div className="description">
                        <Typography variant="h6">
                            Top 5 Patched Win 7 Stations with maximum reboots in one month
                        </Typography>
                    </div>
                </Grid>
                <StoryTable data={data.TABLEpkgwin7} layout={table2Layout}></StoryTable>
                        <Grid container justify="center" spacing={2} alignItems="stretch">
                            <div className="subText">
                                <Typography>
                                    Windows 7 devices are not patched as much to Windows 10 devices. Reboots counts in all the
                                    above table do not include the Type 1 Normal Shutdowns.
                                </Typography>
                            </div>
                        </Grid>
                </Paper>*/}
{/*                  <Paper className="storySection">
                        <Grid container justify="left" spacing={2} alignItems="stretch">
                            <div className="subText">
                                <Typography>
                                    Key Findings from the analysis of Win 7 and Win 10 Patched devices - Since June 2020:<p></p>
                                    <li>Totally there are about 1482 devices that are patched out of which 1164 Win 10 devices rebooted (excluding Normal Shutdown) at least once and 5 Win 7 devices rebooted at least once</li>
                                    <li>Only 1169 out of 1482 (78.9%) had an issue or unknown restart in the past one year</li>
                                    <li>In the past one year, across these 1482 devices, 9365 Type II (Issue) and Type III (Unknown) reboots occurred</li>
                                    <li>Among 9365 reboots, 6747 reboots occurred after the device was patched and 2618 when device was unpatched</li>
                                    <li>17 reboots occurred in Win 7, patched device and 13 reboots in Win 7 unpatched device</li>
                                    <li>6730 reboots occurred in Win 10 patched device and 2605 reboots in Win 10 unpatched device</li><p></p>
                                    Therefore, reboots occur often in Win 10 device than other categories.
                                </Typography>
                            </div>
                        </Grid>
                    </Paper>*/}
            </div>
        )
    }
}
export default StationStability
